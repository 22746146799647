import Button from 'components/Button'
import Card from 'components/Card'
import { ReactComponent as BellSvg } from 'images/bell.svg'
import { getNow } from 'modules/date'
import { createMarkup } from 'modules/render'
import { isExternal } from 'modules/utils'
import React, { FC, useMemo } from 'react'
import { Sprint } from 'typings/sprint'

import styles from './Announcement.module.scss'

type Props = {
  sprint: Sprint
}

const Announcement: FC<Props> = ({ sprint }) => {
  const announcement = useMemo(() => {
    const now = getNow().utc().unix()

    const available = sprint.carousel.filter(
      (c) => c.available_datetime_UTC <= now && (!c.expiration_datetime_UTC || c.expiration_datetime_UTC > now),
    )

    if (available.length === 0) {
      return undefined
    }

    available.sort((a, b) => b.available_datetime_UTC - a.available_datetime_UTC)

    return available[0]
  }, [sprint])

  if (!announcement) {
    return null
  }

  // Previous approach didn't work because local links returned with https at start. This one compares the hosts to decide.
  const external = isExternal(announcement.link_url)

  return (
    <Card className={styles.announcement}>
      <BellSvg className={styles.bell} />
      <div className={styles.main}>
        <div className={styles.textBlock}>
          <div className={styles.title}>{announcement.title}</div>
          <div className={styles.message} dangerouslySetInnerHTML={createMarkup(announcement.carousel_body_html)} />
        </div>
        {announcement.link_text && announcement.link_url && (
          <Button
            className={styles.button}
            labelClassName={styles.buttonLabel}
            label={announcement.link_text}
            link={announcement.link_url}
            external={external}
          />
        )}
      </div>
    </Card>
  )
}

export default Announcement
