import { RouteComponentProps, useLocation, WindowLocation } from '@reach/router'
import { useAppData } from 'hooks/useAppData'
import { useSprintPageTracking } from 'hooks/usePageTracking'
import React, { FC, useMemo } from 'react'

import Breadcrumb from '../../components/Breadcrumb'
import Page from '../../components/Page'
import LessonsAndCaseStudies from './components/LessonsAndCaseStudies'
import ModuleHeader from './components/ModuleHeader'
import styles from './Module.module.scss'
import { findVideo } from './utils'

type Props = RouteComponentProps & {
  moduleSlug?: string
  videoSlug?: string
}

const Module: FC<Props> = ({ moduleSlug, videoSlug }) => {
  const { sprints, selectedSprint } = useAppData()
  const location = useLocation() as WindowLocation<{ autoPlay?: boolean }>
  const { autoPlay } = location?.state ?? {}

  const sprint = selectedSprint >= 0 ? sprints[selectedSprint] : null

  useSprintPageTracking(sprint, 'Modules')

  const modules = useMemo(() => (sprint || {}).course_modules || [], [sprint])

  const currentModule = useMemo(() => modules.find((m) => m.slug === moduleSlug), [modules, moduleSlug])

  const currentVideo = findVideo(currentModule, videoSlug)

  const courseModule = currentModule?.course_module

  if (!courseModule || !currentVideo) {
    return null
  }

  return (
    <Page className={styles.pageBackground}>
      <div className="row">
        <div className="col-12">
          <Breadcrumb link={`/${sprint.slug}/modules`} linkTitle="Modules" currentTitle="Module" />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <ModuleHeader module={currentModule} />
        </div>
      </div>

      <div className="mt-4">
        <LessonsAndCaseStudies
          selectedVideoSlug={videoSlug}
          video={currentVideo}
          autoPlay={!!autoPlay}
          module={currentModule}
          sprint={sprint}
        />
      </div>
    </Page>
  )
}

export default Module
