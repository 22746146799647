import Card from 'components/Card'
import Col from 'components/Col'
import Row from 'components/Row'
import { DESKTOP_WIDTH, useWindowSize } from 'hooks/useWindowSize'
import { decode } from 'html-entities'
import { WPCONTENT } from 'modules/constants'
import { playerSeek } from 'modules/player'
import Workbook from 'pages/module/components/Workbook'
import React, { FC, useMemo } from 'react'
import { CourseModule } from 'typings/courseModule'
import { Sprint } from 'typings/sprint'
import { VideoPost } from 'typings/video'

import Bookmarks from './Bookmarks'
import styles from './LessonsAndCaseStudies.module.scss'
import ModuleStatus from './ModuleStatus'
import Playlist from './Playlist'
import Video from './Video'

const DEEPER = /(<ul>.*<\/ul>)/

type Props = {
  sprint: Sprint
  selectedVideoSlug: string
  video: VideoPost
  module: CourseModule
  autoPlay: boolean
}

const LessonsAndCaseStudies: FC<Props> = ({ sprint, selectedVideoSlug, video, module, autoPlay }) => {
  const decodedContent = useMemo(() => decode(video.post_content), [video])

  const { header_1, header_1_q_1, header_1_q_2, header_2, header_2_q_1, header_2_q_2 } = video?.video ?? {}

  const videoRef = React.useRef<HTMLDivElement>(null)

  // Nasty hack to detect the 'Welcome' module. If anyone knows better, please let me know.
  const isWelcome = (module?.post_title ?? '')?.toLowerCase() === 'welcome'

  const goDeeper = useMemo(() => {
    if (video.video.go_deeper) return video.video.go_deeper
    const extracted = DEEPER.exec(decodedContent)
    return extracted && extracted[0] ? extracted[0] : ''
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decodedContent])

  const filtered = useMemo(
    () => decodedContent.split(WPCONTENT).filter((p) => p && !p.startsWith('\n') && !p.startsWith('<strong>')),
    [decodedContent],
  )

  const questions = useMemo(() => filtered.filter((p) => p.startsWith('Q:')).map((p) => p.substring(3)), [filtered])
  const paragraphs = useMemo(() => filtered.filter((p) => !p.startsWith('Q:')).map((p) => `<p>${p}</p>`), [filtered])
  const description = useMemo(() => paragraphs.join('\n'), [paragraphs])

  const isDesktop = useWindowSize().width >= DESKTOP_WIDTH

  const handleBookmarkClick = (startSeconds) => {
    if (videoRef.current) {
      videoRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })
    }

    playerSeek(startSeconds)
  }

  return (
    <>
      <Card>
        {!isDesktop && (
          <Row>
            <Col xs={12}>
              <div className={styles.title}>{video.post_title}</div>
              <ModuleStatus module={module} />
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={12} lg={9} ref={videoRef}>
            {isDesktop && <div className={styles.title}>{video.post_title}</div>}
            <Video sprintSlug={sprint.slug} module={module} video={video} autoPlay={autoPlay} />
          </Col>
          <Col xs={12} lg={3} className="mt-lg-0">
            {isDesktop && <ModuleStatus module={module} />}
            <Bookmarks video={video} onBookmarkClick={handleBookmarkClick} />
          </Col>
        </Row>

        <Playlist springSlug={sprint.slug} selectedVideoSlug={selectedVideoSlug} module={module} />
        <Row className="mt-4">
          <Col xs={12}>
            <div className={styles.videoTitle}>{video.post_title}</div>
          </Col>
          <Col xs={12} className={`${styles.description}`}>
            <p dangerouslySetInnerHTML={{ __html: description }} />
          </Col>
          {goDeeper && (
            <Col xs={12}>
              <div className={styles.h2}>Go Deeper</div>
            </Col>
          )}
          <Col xs={12}>
            <div className={styles.description} dangerouslySetInnerHTML={{ __html: goDeeper }} />
          </Col>
        </Row>
      </Card>

      {!isWelcome && (
        <Workbook
          sprint={sprint}
          questions={questions}
          header_1={header_1}
          header_1_q_1={header_1_q_1}
          header_1_q_2={header_1_q_2}
          header_2={header_2}
          header_2_q_1={header_2_q_1}
          header_2_q_2={header_2_q_2}
        />
      )}
    </>
  )
}

export default LessonsAndCaseStudies
