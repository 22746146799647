import { Link } from '@reach/router'
import cn from 'classnames'
import { ReactComponent as ChevronRightSvg } from 'images/chevron-right.svg'
import React from 'react'

import styles from './ViewAll.module.scss'

type Props = {
  url: string
  className?: string
}

const ViewAll: React.FC<Props> = ({ url, className }) => (
  <Link to={url} className={cn(styles.viewAll, className)}>
    View All
    <ChevronRightSvg className={styles.chevron} />
  </Link>
)

export default ViewAll
