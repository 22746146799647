import Button from 'components/Button'
import Card from 'components/Card'
import Col from 'components/Col'
import Row from 'components/Row'
import { ReactComponent as Download } from 'images/download.svg'
import { ReactComponent as Eye } from 'images/eye.svg'
import { emptySprintSection } from 'modules/sprint'
import React, { FC, useMemo } from 'react'
import { Sprint } from 'typings/sprint'
import { isMicrosoftSprint } from 'utils/isFilteredSprint'

import styles from './LessonsAndCaseStudies.module.scss'
import TitleQuestion from './TitleQuestion'

type Props = {
  sprint: Sprint
  questions: string[]
  header_1?: string
  header_1_q_1?: string
  header_1_q_2?: string
  header_2?: string
  header_2_q_1?: string
  header_2_q_2?: string
}

const Workbook: FC<Props> = ({
  sprint,
  questions,
  header_1,
  header_1_q_1,
  header_1_q_2,
  header_2,
  header_2_q_1,
  header_2_q_2,
}) => {
  const workbookLink = useMemo(() => sprint?.document_links?.editable_workbook, [sprint])

  const { slack_section_url } = sprint?.sprint_section || emptySprintSection
  const hasQuestions = (questions && questions.length > 0) || (header_1 && header_2)
  const isMicrosoft = isMicrosoftSprint(sprint?.slug)

  return (
    <Card className={styles.card}>
      <div className={styles.h2UC}>Project Materials</div>
      <div className={styles.paragraph}>
        Respond to the reflection questions in the course materials linked below. This will help you apply the learnings
        to your business, and start your project. Want to discuss what you learned with fellow sprinters? Head over to
        your #section channel on Slack to discuss any other learnings, takeaways, and insights about the module.
      </div>

      {hasQuestions && <div className={styles.hr} />}

      <TitleQuestion header={header_1} question1={header_1_q_1} question2={header_1_q_2} />
      <TitleQuestion header={header_2} question1={header_2_q_1} question2={header_2_q_2} />

      {questions && questions.length > 0 && (
        <>
          {questions.map((q: string, i) => (
            <div key={i} className={styles.questionContainer}>
              <div className={styles.Q}>Q</div>
              <div className={styles.questionText} dangerouslySetInnerHTML={{ __html: q }} />
            </div>
          ))}
        </>
      )}

      <div className={styles.hr} />
      <Row>
        {workbookLink && (
          <Col xs={12} md={4}>
            <Button
              className={styles.button}
              image={<Download />}
              label="Project Materials"
              external
              link={workbookLink}
            />
          </Col>
        )}
        {slack_section_url && !isMicrosoft && (
          <Col xs={12} md={4}>
            <Button
              className={styles.button}
              image={<Eye />}
              label="Discuss in your #Section"
              external
              link={slack_section_url}
            />
          </Col>
        )}
      </Row>
    </Card>
  )
}

export default Workbook
