import { AnimatePresence, motion } from 'framer-motion'
import React, { FC, ReactNode } from 'react'

const animationVariants = {
  open: { opacity: 1, height: 'auto' },
  collapsed: { opacity: 0, height: 0 },
}

type Props = {
  expanded: boolean
  className?: string
  children?: ReactNode
}

export const Expandable: FC<Props> = ({ expanded, children, className }) => (
  <AnimatePresence initial={false}>
    {expanded && (
      <motion.div
        key="content"
        style={{ overflow: 'hidden' }}
        initial="collapsed"
        animate="open"
        exit="collapsed"
        variants={animationVariants}
        transition={{ duration: 1, ease: [0.04, 0.62, 0.23, 0.98] }}
        className={className}
      >
        {children}
      </motion.div>
    )}
  </AnimatePresence>
)
