import { createSlice } from '@reduxjs/toolkit'
import { parseTokenCookie } from 'modules/cookieUtils'
import { User } from 'typings/user'

import { getUserInfo } from '../../modules/api'
import { handleLogout } from '../../modules/auth'
import * as errorTracking from '../../modules/errorTracking'

const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: {} as User,
    token: null,
  },
  reducers: {
    cleanUp(state) {
      state.user = {}
      state.token = null
    },
    setToken(state, action) {
      state.token = action.payload
    },
    setUser(state, action) {
      state.user = action.payload
    },
  },
})

export default userSlice.reducer

export const { cleanUp, setToken, setUser } = userSlice.actions

export const doCheckAuthentication = () => {
  return async (dispatch) => {
    await dispatch(fetchUserInfo())
    const token = parseTokenCookie()
    errorTracking.setSessionId(token)
    dispatch(setToken(token))
  }
}

export const fetchUserInfo = () => {
  return async (dispatch) => {
    try {
      const { data: userInfo } = await getUserInfo()
      dispatch(setUser(userInfo))
      errorTracking.setUserId(`${userInfo.user_id}`)
    } catch (error) {
      console.error(error)
    }
  }
}

export const newLogout = () => {
  return async (dispatch) => {
    dispatch(cleanUp())
    handleLogout()
  }
}
