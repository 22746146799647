import cn from 'classnames'
import { createMarkup } from 'modules/render'
import React from 'react'
import { CourseModule } from 'typings/courseModule'

import Video from '../../home/components/Video'
import styles from './ModuleContent.module.scss'

type Props = {
  sprintSlug: string
  module: CourseModule
}

const ModuleContent: React.FC<Props> = ({ sprintSlug, module }) => {
  const availableDatetimeUTC = module.course_module.available_datetime_UTC
  const items = module.course_module.items

  return (
    <div className="row">
      <div
        className={cn('col-12 wordpress-text', styles.description)}
        dangerouslySetInnerHTML={createMarkup(module.post_content)}
      />
      {items.map((item) => (
        <div className="col-12 col-md-6 mt-3 mt-md-4">
          <Video
            sprintSlug={sprintSlug}
            moduleSlug={module.slug}
            video={item}
            availableDatetimeUTC={availableDatetimeUTC}
          />
        </div>
      ))}
    </div>
  )
}

export default ModuleContent
