import cn from 'classnames'
import React, { FC, ReactNode } from 'react'

import styles from './Button.module.scss'
import InternalOrExternalLink from './InternalOrExternalLink'

type Props = {
  link: string
  image?: ReactNode
  label: string
  external?: boolean
  className?: string
  labelClassName?: string
}

const Button: FC<Props> = ({ link, image, label, external, className, labelClassName }) => (
  <InternalOrExternalLink link={link} external={external} className={cn(styles.button, className)}>
    {image && <div className={styles.imageWrapper}>{image}</div>}
    <div className={cn(styles.label, labelClassName)}>{label}</div>
  </InternalOrExternalLink>
)

export default Button
