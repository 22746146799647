import { RouteComponentProps } from '@reach/router'
import { useSprintPageTracking } from 'hooks/usePageTracking'
import { saveUploadedProjectInfo } from 'modules/api'
import { ProjectSubmissionForm } from 'pages/upload/components/projectSubmissionForm'
import Submitted from 'pages/upload/components/Submitted'
import Wrapper from 'pages/upload/components/Wrapper'
import React, { FC, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { offersPeerFeedback } from 'utils/isFilteredSprint'

import Step1 from './components/Step1'
import Step4 from './components/Step4'
import Step5 from './components/Step5'
import Step6 from './components/Step6'
import StepBegin from './components/StepBegin'

const ProjectUpload: FC<RouteComponentProps> = () => {
  const [direction, setDirection] = useState(0)
  const [step, setStep] = useState(0)
  const [stepValid, setStepValid] = useState(true)

  const [uploadData, setUploadData] = useState<ProjectSubmissionForm>({})

  const sprints = useSelector((state: RootState) => state.sprint.sprints)
  const selectedSprint = useSelector((state: RootState) => state.sprint.selectedSprint)

  const sprint = useMemo(() => sprints[selectedSprint], [sprints, selectedSprint])

  useSprintPageTracking(sprint)

  const updateData = (stepData) => setUploadData({ ...uploadData, ...stepData })

  const stepPrevious = () => {
    if (step > 0) {
      setStep(step - 1)
      setDirection(-1)
    }
  }

  const stepNext = () => {
    if (step < 8 && stepValid) {
      setStep(step + 1)
      setDirection(1)
    }
  }

  const restart = (event) => {
    // Don't copy this. Instead of relying on preventDefault() we should convert
    // anchors to buttons.
    event.preventDefault()
    setStep(0)
    setDirection(-1)
    setUploadData({})
  }

  const handleSubmit = async () => {
    if (!stepValid) return
    await saveUploadedProjectInfo(uploadData)
    setStep(step + 1)
    setDirection(1)
  }

  const renderStep = () => {
    const stepsArray = []

    // Some steps only apply to peer feedback, which we don't offer for some sprints.
    // This count excludes StepBegin and Submitted, which don't have navigation.
    const numberOfSteps = offersPeerFeedback(sprint.slug) ? 5 : 4

    stepsArray.push(<StepBegin sprint={sprint} setValid={setStepValid} handleBegin={stepNext} />)

    stepsArray.push(
      <Step1
        sprint={sprint}
        step={step}
        data={uploadData}
        onUpdate={updateData}
        setValid={setStepValid}
        onBack={stepPrevious}
        onNext={stepNext}
        stepValid={stepValid}
        numberOfSteps={numberOfSteps}
      />,
    )

    stepsArray.push(
      <Step4
        data={uploadData}
        updateHandler={updateData}
        setValid={setStepValid}
        step={step}
        onNext={stepNext}
        onPrevious={stepPrevious}
        stepValid={stepValid}
        numberOfSteps={numberOfSteps}
      />,
    )

    stepsArray.push(
      <Step5
        data={uploadData}
        updateHandler={updateData}
        setValid={setStepValid}
        step={step}
        onNext={stepNext}
        onPrevious={stepPrevious}
        stepValid={stepValid}
        numberOfSteps={numberOfSteps}
      />,
    )

    stepsArray.push(
      <Step6
        data={uploadData}
        updateHandler={updateData}
        valid={stepValid}
        setValid={setStepValid}
        handleSubmit={handleSubmit}
        step={step}
        onPrevious={stepPrevious}
        numberOfSteps={numberOfSteps}
      />,
    )

    stepsArray.push(<Submitted sprint={sprint} resubmit={restart} />)

    return stepsArray[step]
  }

  return (
    <Wrapper step={step} direction={direction}>
      {renderStep()}
    </Wrapper>
  )
}

export default ProjectUpload
