import { ReactComponent as SlackSvg } from 'images/slack.svg'
import { emptySprintSection } from 'modules/sprint'
import React, { FC } from 'react'
import { Sprint } from 'typings/sprint'

import HeaderButton from './HeaderButton'
import styles from './HeaderButton.module.scss'

type Props = {
  sprint: Sprint
}

const YourSlackSection: FC<Props> = ({ sprint }) => {
  const { slack_section_url: slackSectionURL } = sprint?.sprint_section || emptySprintSection

  const disabled = !slackSectionURL

  return (
    <HeaderButton
      image={<SlackSvg className={styles.svgImage} />}
      label="Your Slack Section"
      title={disabled ? 'Not Yet Assigned' : 'Join the Community Discussion'}
      url={disabled ? '' : slackSectionURL}
      disabled={disabled}
    />
  )
}

export default YourSlackSection
