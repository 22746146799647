import { calculateStatus } from './utils'

export const calculateVideoStatus = (availableDatetimeUTC, video): [boolean, string] => {
  const totalVideoSeconds = video.video.video_duration
  const watchedVideoSeconds = parseInt(video.user.history.farthest_watched_time) || 0

  const percentWatched = (watchedVideoSeconds / totalVideoSeconds) * 100 || 0

  return calculateStatus(availableDatetimeUTC, percentWatched)
}
