import { navigate } from '@reach/router'
import cn from 'classnames'
import Col from 'components/Col'
import Row from 'components/Row'
import { useWindowSize } from 'hooks/useWindowSize'
import { ReactComponent as ChevronRightSvg } from 'images/chevron-right.svg'
import { buildUrl } from 'modules/routing'
import React, { FC, useEffect, useRef, useState } from 'react'
import { CourseModule } from 'typings/courseModule'

import styles from './Playlist.module.scss'

type Props = {
  springSlug: string
  selectedVideoSlug: string
  module: CourseModule
}

const Playlist: FC<Props> = ({ springSlug, selectedVideoSlug, module }) => {
  const [showLeft, setShowLeft] = useState(false)
  const [showRight, setShowRight] = useState(false)

  const videos = (module?.course_module ?? {})?.items ?? []

  const videosRef = useRef<HTMLDivElement>()

  const { width } = useWindowSize()

  useEffect(() => {
    if (videosRef) {
      setShowRight(videosRef.current.scrollWidth !== videosRef.current.offsetWidth + videosRef.current.scrollLeft)
    }
  }, [videosRef, width])

  const handleOnClick = (videoSlug: string) => {
    navigate(buildUrl(springSlug, module.slug, videoSlug))
  }

  const handleScrollLeft = () => {
    videosRef.current.scroll({
      left: videosRef.current.scrollLeft - 141,
      behavior: 'smooth',
    })
  }

  const handleScrollRight = () => {
    videosRef.current.scroll({
      left: videosRef.current.scrollLeft + 141,
      behavior: 'smooth',
    })
  }

  const handleScroll = (ev: React.UIEvent<HTMLElement>) => {
    setShowRight(
      ev.nativeEvent.target['scrollWidth'] !==
        ev.nativeEvent.target['offsetWidth'] + ev.nativeEvent.target['scrollLeft'],
    )

    setShowLeft(ev.nativeEvent.target['scrollLeft'] > 0)
  }

  return (
    <Row>
      <Col xs={12}>
        <div className={styles.title}>Module Playlist</div>
      </Col>
      <Col xs={12}>
        <div className={styles.videosWrapper}>
          <div className={styles.scrollLeftBackdrop} style={{ visibility: showLeft ? 'visible' : 'hidden' }}>
            <div className={styles.scrollLeft} onClick={handleScrollLeft}>
              <ChevronRightSvg className={styles.chevronLeft} />
            </div>
          </div>
          <div className={styles.videos} ref={videosRef} onScroll={handleScroll}>
            {videos.map((v, i) => (
              <div
                key={i}
                className={cn(styles.video, {
                  [styles.video__playing]: selectedVideoSlug === v.slug,
                })}
                onClick={() => handleOnClick(v.slug)}
              >
                <div className={styles.thumbnailContainer}>
                  <img src={v.thumbnail} alt={v.post_title} className={styles.thumbnail} />
                  {selectedVideoSlug === v.slug && (
                    <div className={styles.overlay}>
                      <div className={styles.playing}>Playing</div>
                    </div>
                  )}
                </div>
                <div className={styles.videoTitle}>{v.post_title}</div>
              </div>
            ))}
          </div>
          <div className={styles.scrollRightBackdrop} style={{ visibility: showRight ? 'visible' : 'hidden' }}>
            <div className={styles.scrollRight} onClick={handleScrollRight}>
              <ChevronRightSvg className={styles.chevronRight} />
            </div>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default Playlist
