const reducerBuilder = (list, reducerFunc, initialVal = 0) =>
  list.reduce((accumulator, currentVal) => reducerFunc(accumulator, currentVal), initialVal)

export const totalSeconds = (moduleItems) =>
  reducerBuilder(moduleItems, (accumulator, currentVal) => accumulator + currentVal.video.video_duration)

export const watchedSeconds = (moduleItems) =>
  reducerBuilder(
    moduleItems,
    (accumulator, currentVal) => accumulator + (parseInt(currentVal.user.history.farthest_watched_time) || 0),
  )

// The original functions...
//
// Does doing this in a more functional way buy us much?... In this case, it's debatable
//
// const totalSeconds = moduleItems =>
//   moduleItems.reduce(
//     (accumulator, currentVal) =>
//       accumulator + currentVal.video.video_duration,
//     0
//   );

// // TODO: This function is basically the same as the function above... Improve it/make it more functional
// const watchedSeconds = moduleItems =>
//   moduleItems.reduce(
//     (accumulator, currentVal) =>
//       accumulator +
//       (parseInt(currentVal.user.history.farthest_watched_time) || 0),
//     0
//   );
