import { ReactComponent as Avatar } from 'images/avatar.svg'
import { emptySprintSection } from 'modules/sprint'
import React, { FC } from 'react'
import { Sprint } from 'typings/sprint'

import HeaderButton from './HeaderButton'
import styles from './HeaderButton.module.scss'

type Props = {
  sprint: Sprint
}

const YourTA: FC<Props> = ({ sprint }) => {
  const {
    ta_name: taName,
    ta_slack_url: taSlackURL,
    thumbnail: taImgURL,
  } = sprint?.sprint_section || emptySprintSection

  const disabled = !taName || !taSlackURL

  return (
    <HeaderButton
      image={
        disabled ? (
          <Avatar className={styles.svgImage} />
        ) : (
          <img className={styles.image} src={taImgURL} alt={`TA: ${taName}`} />
        )
      }
      label="Your TA"
      title={disabled ? 'Not Yet Assigned' : taName}
      url={disabled ? '' : taSlackURL}
      disabled={disabled}
    />
  )
}

export default YourTA
