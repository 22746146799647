import cn from 'classnames'
import Button from 'components/Button'
import Card from 'components/Card'
import { ReactComponent as Eye2Icon } from 'images/eye2.svg'
import React from 'react'
import { Sprint } from 'typings/sprint'

import commonStyles from './common.module.scss'
import styles from './SprintSchedule.module.scss'

type Props = {
  sprint: Sprint
}

const SprintSchedule: React.FC<Props> = ({ sprint }) => {
  const { google_calendar_link } = sprint.course

  return (
    <Card>
      <div className={commonStyles.title}>Sprint Schedule</div>
      <div className={commonStyles.description}>View the full sprint schedule and sync with your calendar.</div>
      <Button
        link={google_calendar_link}
        image={<Eye2Icon />}
        label="View Schedule"
        className={cn(commonStyles.buttons, styles.button)}
        labelClassName={styles.buttonLabel}
        external={true}
      />
    </Card>
  )
}

export default SprintSchedule
