import cn from 'classnames'
import React, { FC } from 'react'

import styles from './Loader.module.scss'

type Props = {
  className?: string
}

const Loader: FC<Props> = ({ className }) => (
  <div className={cn(styles.loaderContainer, className)}>
    <div className={styles.spinnerContainer}>
      <span className={styles.spinner1} />
      <span className={styles.spinner2} />
    </div>
  </div>
)

export default Loader
