import { MEMBERSHIP_LOGIN_ROUTE, MEMBERSHIP_URL } from 'modules/constants'
import { REFRESH_TOKEN_COOKIE_NAME, TOKEN_COOKIE_NAME, USER_ID_COOKIE_NAME } from 'modules/cookieConstants'
import { deleteCookie, parseTokenCookie } from 'modules/cookieUtils'
import * as errorTracking from 'modules/errorTracking'

const AUTHORIZED_ACCOUNT_TYPES = ['EMPLOYEE', 'MEMBER', 'TRIAL']

export function authHeader() {
  const token = parseTokenCookie()
  return token ? { Authorization: 'Bearer ' + token } : {}
}

export const handleLogout = (): void => {
  deleteCookie(TOKEN_COOKIE_NAME)
  deleteCookie(REFRESH_TOKEN_COOKIE_NAME)
  deleteCookie(USER_ID_COOKIE_NAME)
  errorTracking.setSessionId('')
  window.location.href = MEMBERSHIP_URL + MEMBERSHIP_LOGIN_ROUTE
}

/**
 * By default, ALUMNI users don't get access to past sprints, but we provide it
 * by request. This is stored in the Platform DB as users.has_ongoing_sprint_access.
 * We query the Services API for the current user to check their account type and
 * privilege, and redirect accordingly.
 */
export const redirectToMembershipIfUnauthorized = (user): void => {
  if (!userHasSprintAccess(user)) {
    redirectToMembership()
  }
}

const userHasSprintAccess = (user): boolean => {
  return (
    user.status === 'ACTIVE' && (AUTHORIZED_ACCOUNT_TYPES.includes(user.account_type) || user.has_ongoing_sprint_access)
  )
}

const redirectToMembership = (): void => {
  window.location.href = MEMBERSHIP_URL
}
