import React from 'react'
import { TitleLink } from 'typings/titleLink'

import ExternalLink from '../../components/ExternalLink'
import styles from './EventContent.module.scss'

// TODO: Split this into separate EventHtml and EventLinks components.
const EventContent = ({ title, links, innerHTML }: { title: string; links?: TitleLink[]; innerHTML?: string }) => {
  return (
    <div>
      <div className={styles.eventContentTitle}>{title}</div>
      {innerHTML && <div className={styles.eventContentPreReadings} dangerouslySetInnerHTML={{ __html: innerHTML }} />}

      {links &&
        links.map((l, i) => {
          return (
            <div key={i}>
              <ExternalLink link={l.url} className={styles.eventContentLink}>
                {l.title}
              </ExternalLink>
            </div>
          )
        })}
      <div style={{ marginBottom: '3rem' }} />
    </div>
  )
}

export default EventContent
