import { DeviceConfig } from 'typings/deviceConfig'

const durationNav = 0.5
// const durationNav = 1;

export const OPEN = 'open'
export const CLOSED = 'closed'

export enum DeviceType {
  PHONE,
  TABLET,
}

// : Map<string, DeviceConfig>
type DeviceConfigs = { [k: string]: DeviceConfig }

export const device: DeviceConfigs = {
  PHONE: {
    // Nav
    navWidthClosed: 0,
    navWidthOpen: 0, // The left nav isn't animated on phones
    navLeftClosed: 0,
    navLeftOpen: 24,
    marginClosed: 0,
    marginOpen: 0, // Items shouldn't move to the right when the nav opens
    // Content
    contentLeftClosed: 0,
    contentLeftOpen: 0,
    overlayDisplay: 'none',
  },
  TABLET: {
    // Nav
    navWidthClosed: 56,
    navWidthOpen: 288,
    navLeftClosed: 0,
    navLeftOpen: 20,
    marginClosed: 12,
    marginOpen: 30,
    // Content
    contentLeftClosed: 56,
    contentLeftOpen: 56,
    overlayDisplay: 'inline-block',
  },
  DESKTOP: {
    // Nav
    navWidthClosed: 72,
    navWidthOpen: 320,
    navLeftClosed: 0,
    navLeftOpen: 20,
    marginClosed: 20,
    marginOpen: 30,
    // Content
    contentLeftClosed: 72,
    contentLeftOpen: 72,
    overlayDisplay: 'inline-block',
  },
}

// ---- Nav ----
export const navVariants = (device) => {
  const { navWidthOpen, navWidthClosed } = device

  return {
    open: () => ({
      width: navWidthOpen,
      transition: {
        duration: durationNav,
      },
    }),
    closed: () => ({
      width: navWidthClosed,
      transition: {
        duration: durationNav,
      },
    }),
  }
}

export const hamburgerVariants = ({ navLeftClosed }) => ({
  open: {
    transition: {
      when: 'beforeChildren',
      duration: durationNav,
    },
  },
  closed: {
    paddingLeft: navLeftClosed,
    transition: {
      duration: durationNav,
    },
  },
})

export const menuItemVariants = (device) => {
  const { navLeftClosed, navLeftOpen } = device

  return {
    open: {
      paddingLeft: navLeftOpen,
      transition: {
        when: 'beforeChildren',
        duration: durationNav,
      },
    },
    closed: {
      paddingLeft: navLeftClosed,
      transition: {
        duration: durationNav,
      },
    },
  }
}

export const MENU_LABEL_VARIANTS = {
  open: {
    opacity: 1,
    display: 'inline-block',
    transition: {
      duration: durationNav,
    },
  },
  closed: {
    opacity: 0,
    transitionEnd: {
      display: 'none',
    },
  },
}

export const separatorVariants = (device) => {
  const { marginClosed, marginOpen } = device

  return {
    open: () => ({
      marginLeft: marginOpen,
      marginRight: marginOpen,
      transition: {
        duration: durationNav,
      },
    }),
    closed: () => ({
      marginLeft: marginClosed,
      marginRight: marginClosed,
      transition: {
        duration: durationNav,
      },
    }),
  }
}

export const INFO_VARIANTS = {
  open: {
    width: '100%',
    transition: {
      duration: durationNav,
      when: 'beforeChildren',
    },
  },
  closed: {
    width: 0,
    transition: {
      duration: durationNav,
    },
  },
}

export const INFO_CONTENT_VARIANTS = {
  open: {
    opacity: 1,
    display: 'inline-block',
    transition: {
      duration: durationNav,
    },
  },
  closed: {
    opacity: 0,
    transitionEnd: {
      display: 'none',
    },
  },
}

// Mobile (Phone)
export const PHONE_NAV_VARIANTS = {
  open: {
    backgroundColor: '#474549',
    width: '100vw',
    height: '100vh',
    transition: {
      duration: durationNav,
    },
  },
  closed: {
    backgroundColor: '#EEEDF4',
    width: 94,
    height: 94,
    transition: {
      duration: durationNav,
      bounce: 0,
    },
  },
}

// ---- Content ----
export const contentVariants = (device) => {
  const { contentLeftClosed, contentLeftOpen } = device

  return {
    open: () => ({
      left: contentLeftOpen,
      overflowY: 'hidden',
      transition: {
        duration: durationNav,
      },
    }),
    closed: () => ({
      left: contentLeftClosed,
      overflowY: 'scroll',
      transition: {
        duration: durationNav,
      },
    }),
  }
}

export const overlayVariants = (device) => {
  const { contentLeftClosed, contentLeftOpen, overlayDisplay } = device

  return {
    open: () => ({
      opacity: 0.4,
      display: overlayDisplay,
      left: contentLeftOpen,
      transition: {
        duration: durationNav,
      },
    }),
    closed: () => ({
      opacity: 0,
      left: contentLeftClosed,
      transitionEnd: {
        display: 'none',
      },
    }),
  }
}
