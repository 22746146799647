import { RouteComponentProps } from '@reach/router'
import Button from 'components/Button'
import Card from 'components/Card'
import { useAppData } from 'hooks/useAppData'
import { useSprintPageTracking } from 'hooks/usePageTracking'
import { ReactComponent as DownloadSvg } from 'images/download.svg'
import { ReactComponent as EyeSvg } from 'images/eye.svg'
import { ReactComponent as Eye3Svg } from 'images/eye3.svg'
import { ReactComponent as DocumentCheckLargeSvg } from 'images/large/document-check.svg'
import { ReactComponent as DocumentHeartLargeSvg } from 'images/large/document-heart.svg'
import { ReactComponent as DocumentPenLargeSvg } from 'images/large/document-pen.svg'
import { HELP_EMAIL } from 'modules/constants'
import SubmitProject from 'pages/documents/SubmitProject'
import React, { FC } from 'react'
import { isComcastSprint, isMicrosoftSprint } from 'utils/isFilteredSprint'

import styles from './DocumentCenter.module.scss'
import DocumentCenterItem from './DocumentCenterItem'

const DocumentCenter: FC<RouteComponentProps> = () => {
  const { sprint, isSC1 } = useAppData()

  const isMicrosoft = isMicrosoftSprint(sprint?.slug)
  const isComcast = isComcastSprint(sprint?.slug)

  useSprintPageTracking(sprint, 'Documents')

  const sprintName = sprint.short_name
  const { syllabus, editable_workbook } = sprint.document_links || {}

  const { project_rubric_link } = sprint.final_project_per_course_info ?? {}

  return (
    <div className={styles.component}>
      <div className="row">
        <Card className={`col-12 ${styles.head}`}>
          <div className={styles.pageName}>Documents</div>
          <h3>{sprintName}</h3>
          <div className={styles.paragraph}>
            All materials for the Sprint are included below. If you have trouble accessing these documents, please feel
            free to reach out to your Community Manager or our Support Team at{' '}
            <a href={`mailto:${HELP_EMAIL}`}>{HELP_EMAIL}</a>.
          </div>
          <div className={styles.buttons}>
            <Button className={styles.topButton} link={syllabus} external image={<EyeSvg />} label="Syllabus" />
          </div>
        </Card>
      </div>

      <DocumentCenterItem
        title="Project Materials"
        link={editable_workbook}
        hidden={!editable_workbook}
        content="Project Materials is a tool to practice and apply what you learn. As you progress through the modules, you will respond to reflection questions, complete exercises, and build your project based on your chosen business challenge or opportunity."
        icon={<DocumentPenLargeSvg />}
        buttonText="Project Materials"
        buttonIcon={<DownloadSvg />}
        linkExternal
      />

      {!isMicrosoft && !isComcast && (
        <>
          <DocumentCenterItem
            title="Past Project Examples"
            link={`/${sprint.slug}/documents/past-project-examples`}
            content="Explore student projects from previous sprints."
            icon={<DocumentHeartLargeSvg />}
            buttonIcon={<Eye3Svg />}
            hidden={false}
          />

          <DocumentCenterItem
            title="Project Rubric"
            content="Use this rubric to guide your project development and peer feedback."
            link={project_rubric_link}
            hidden={!project_rubric_link}
            icon={<DocumentCheckLargeSvg />}
            buttonIcon={<Eye3Svg />}
            linkExternal
          />

          {!isSC1 && <SubmitProject sprint={sprint} />}
        </>
      )}
    </div>
  )
}

export default DocumentCenter
