import { RouteComponentProps } from '@reach/router'
import Col from 'components/Col'
import Page from 'components/Page'
import Row from 'components/Row'
import { useAppData } from 'hooks/useAppData'
import { useSprintPageTracking } from 'hooks/usePageTracking'
import React, { FC } from 'react'
import { isComcastSprint, isMicrosoftSprint } from 'utils/isFilteredSprint'

import Announcement from './components/Announcement'
import HomeHeader from './components/HomeHeader'
import LiveClassesAndEvents from './components/LiveClassesAndEvents'
import Modules from './components/Modules'
import SlackChannels from './components/SlackChannels'
import SprintSchedule from './components/SprintSchedule'
import SubmitYourProject from './components/SubmitYourProject'
import Welcome from './components/Welcome'
import styles from './Home.module.scss'

const Home: FC<RouteComponentProps> = () => {
  const { user, sprint, fetching } = useAppData()
  const isMicrosoft = isMicrosoftSprint(sprint?.slug)
  const isComcast = isComcastSprint(sprint?.slug)

  useSprintPageTracking(sprint, 'Home')

  return (
    <Page className={styles.homePageContent} isLoading={!sprint || !sprint.course_modules || fetching}>
      <Row>
        <Col xs={12}>
          <HomeHeader user={user} sprint={sprint} />
        </Col>
      </Row>
      <Announcement sprint={sprint} />
      <Welcome sprint={sprint} />
      <Row>
        <Col xs={12} lg={isMicrosoft ? 12 : 7}>
          <Row className="mt-3 mt-md-4">
            <Col sm={12}>
              <Modules sprint={sprint} />
            </Col>
          </Row>
          {!isMicrosoft && (
            <Row className="mt-3 mt-md-4">
              <Col sm={12}>
                <LiveClassesAndEvents sprint={sprint} />
              </Col>
            </Row>
          )}
        </Col>
        {!isMicrosoft && (
          <Col sm={12} lg={5}>
            <Row className="mt-3 mt-md-4">
              {!isComcast && (
                <Col sm={12} md={6} lg={12}>
                  <SubmitYourProject sprint={sprint} />
                </Col>
              )}
              <Col sm={12} md={6} lg={12} className="mt-3 mt-md-0 mt-lg-4">
                <SprintSchedule sprint={sprint} />
              </Col>
            </Row>
            {!sprint.endedMoreThanTwoWeeksAgo && sprint?.slug !== 'section4-business-strategy-sprint-12' && (
              <Row className="mt-3 mt-md-4">
                <Col sm={12}>
                  <SlackChannels sprint={sprint} />
                </Col>
              </Row>
            )}
          </Col>
        )}
      </Row>
    </Page>
  )
}

export default Home
