import 'moment-timezone'

import moment, { Moment } from 'moment'
import { CourseModule } from 'typings/courseModule'

const NEW_YORK_TIMEZONE = 'America/New_York'
const FORMAT_AVAILABLE = 'dddd, MMMM DD'
export const FORMAT_COMPLETED_SPRINT = 'MMMM DD, YYYY'
const FORMAT_DATE_TIME = 'YYYY-MM-DD hh:mm:ss'
export const FORMAT_DATE_TIME_MINUTES = 'YYYY-MM-DD HH:mm'
const FORMAT_DATE = 'YYYY-MM-DD'

export const getMonthAndDate = (date) => date.format('MMMM DD')

export const getDay = (date, numberOfLetters = 2, locale = 'en-US') => date.format('ddd').substr(0, numberOfLetters)

export const isToday = (date) => getNow().isSame(date, 'day')

export const newYorkDate = (dateStr) => moment.tz(dateStr, FORMAT_DATE_TIME_MINUTES, NEW_YORK_TIMEZONE)

export const getFormatted = (date) => date.format(FORMAT_COMPLETED_SPRINT)

export const dateFormatted = (dateStr, formatStr = FORMAT_AVAILABLE) => {
  return moment(dateStr).format(formatStr)
}

// OK... I ended up using Moment after all...
//
// TODO: This definitely needs a couple of unit tests
export const isNowAfter = (dateStr) => {
  const currentTimezone = moment.tz.guess()

  const datetimeOffice = moment(dateStr).tz(NEW_YORK_TIMEZONE)
  const datetimeNow = getNow().tz(currentTimezone)

  return datetimeNow.isAfter(datetimeOffice, 'second')
}

const momentAsString = (momentDate = getNow()) => momentDate.format('YYYY-MM-DD')

const daysBetween = (startDate, endDate) => moment(endDate).diff(moment(startDate), 'days') + 1
const dayNum = (dayNum, daysBetween) => {
  switch (true) {
    case dayNum > daysBetween:
      return daysBetween
    case dayNum <= 0:
      return 0
    default:
      return dayNum
  }
}

export const DEFAULT_DAYS = {
  sprintLength: 15,
  dayNum: 0,
}

export const countDays = (startDate, endDate, currentDate = momentAsString()) => {
  const sprintLength = daysBetween(startDate, endDate)
  const daysLeft = daysBetween(currentDate, endDate)

  const currentDay = sprintLength - daysLeft + 1

  return {
    sprintLength,
    dayNum: dayNum(currentDay, sprintLength),
  }
}

export const dayName = (dateStr) => moment(dateStr).format('dddd')
export const monthName = (dateStr) => moment(dateStr).format('MMMM')

export const FORMAT_DUE_DATE = 'dddd, MMMM DD [at] hh:mm A'
export const FORMAT_EARLIEST_SUBMISSION_DATE = 'dddd, MMMM DD'

export const formatDueDate = (dueDate: Moment) => dueDate.format(FORMAT_DUE_DATE)

export const formatEarliestSubmissionDate = (dueDate: Moment) => dueDate.format(FORMAT_EARLIEST_SUBMISSION_DATE)

export const dualDateString = (start, end) => {
  const startDate = moment(start, FORMAT_DATE_TIME)
  const endDate = moment(end, FORMAT_DATE)
  const startMonth = startDate.month()
  const startYear = startDate.year()
  const endMonth = endDate.month()
  const endYear = endDate.year()
  if (startMonth === endMonth) {
    return `${monthName(end)} ${startDate.format('D')} - ${endDate.format('D')}, ${endYear}`
  } else if (startYear === endYear)
    return `${monthName(start)} ${startDate.format('D')} - ${monthName(end)} ${endDate.format('D')}, ${endYear}`
  return `${monthName(start)} ${startDate.format('D')} ${startYear} - ${monthName(end)} ${endDate.format(
    'D',
  )}, ${endYear}`
}

export const getLastModuleDate = (modules: CourseModule[] = []) =>
  moment(Math.max(...modules.map((m) => m.course_module.available_datetime_UTC)) * 1000)

export const getNow = () => moment() // moment("2021-09-22 03:00:00");

export const formatDateSubmitted = (dateSubmitted) =>
  moment(dateSubmitted).format('MM/DD/YY [at] hh:mmA [US Eastern Time]')
