import { VideoPost } from './video'

export type ZoomLinkType =
  | 'Live Kickoff Session'
  | 'Live Case Discussions'
  | 'Networking Events'
  | 'Live Classes'
  | 'Live Project Workshops'

export interface ZoomLink {
  zoom_link_type?: ZoomLinkType
  label?: string
  link_url?: string // meeting URL (excl. section-specific Live Case Discussions)
  zoom_link_date?: string //YYYY-MM-DD
  zoom_link_time?: string //HH:MM
  description_html?: string
  discussion_slides_html?: string
  pre_readings_html?: string
  video_item?: VideoPost // recording for Live Classes
}

interface ZoomLinkWithVideoItem extends ZoomLink {
  video_item: VideoPost
}

// Live Case Discussions are section-specific, except when international. The other
// event types are sprint-specific.
export const ZOOM_LINK_TYPES_META: { [key in ZoomLinkType]: { isRecorded: boolean; singularName: string } } = {
  'Live Kickoff Session': {
    isRecorded: false,
    singularName: 'Live Kickoff Session',
  },
  'Live Case Discussions': {
    isRecorded: true,
    singularName: 'Live Case Discussion',
  },
  'Networking Events': {
    isRecorded: false,
    singularName: 'Networking Event',
  },
  'Live Classes': {
    isRecorded: true,
    singularName: 'Live Class',
  },
  'Live Project Workshops': {
    isRecorded: false,
    singularName: 'Live Project Workshop',
  },
}

export const isZoomLinkWithVideoItem = (zoomLink: ZoomLink): zoomLink is ZoomLinkWithVideoItem => {
  return zoomLink.hasOwnProperty('video_item')
}

// Live Case Discussions are recorded and have section-specific URLs.
//   *except sprint-specific discussions (ex. Product 03 sprint's "International Live Case Discussion")
//   Some sprints have just one case study, with two sessions: AM and PM.
//   Longer sprints may have two case studies with two sessions each.
//
//   meeting URL = <SprintSection>.live_case_study_zoom_1
//   recording URLs = <SprintSection>.live_case_study_recording_zoom_1 (case study 1, AM session),
//                    <SprintSection>.live_case_study_recording_zoom_2 (case study 1, PM session),
//                    <SprintSection>.live_case_study_recording_zoom_3 (case study 2, AM session),
//                    <SprintSection>.live_case_study_recording_zoom_4 (case study 2, PM session)
interface LiveCaseDiscussion extends ZoomLink {
  zoom_link_type: 'Live Case Discussions'
}

export const isLiveCaseDiscussion = (zoomLink: ZoomLink): zoomLink is LiveCaseDiscussion => {
  return zoomLink.zoom_link_type === 'Live Case Discussions'
}

interface SectionLiveCaseDiscussion extends LiveCaseDiscussion {
  link_url: ''
}

export const isSectionLiveCaseDiscussion = (zoomLink: ZoomLink): zoomLink is SectionLiveCaseDiscussion => {
  return isLiveCaseDiscussion(zoomLink) && !zoomLink.link_url
}

export interface LiveCaseDiscussionWithSectionUrls extends SectionLiveCaseDiscussion {
  // The camelCase property names distinguish them from data from the API.
  sectionMeetingUrl: string
  sectionRecordingUrl: string
}

export const isLiveCaseDiscussionWithSectionUrls = (
  zoomLink: ZoomLink,
): zoomLink is LiveCaseDiscussionWithSectionUrls => {
  return (
    isSectionLiveCaseDiscussion(zoomLink) &&
    zoomLink.hasOwnProperty('sectionMeetingUrl') &&
    zoomLink.hasOwnProperty('sectionRecordingUrl')
  )
}
