import React, { FC } from 'react'

import styles from './TitleQuestion.module.scss'

type Props = {
  header: string
  question1: string
  question2: string
}

const TitleQuestion: FC<Props> = ({ header, question1, question2 }) => {
  if (!question1) return null

  return (
    <div>
      <div className={styles.title}>{header}</div>
      {question1 && (
        <div className={styles.container}>
          <div className={styles.Q}>Q</div>
          <div className={styles.text}>{question1}</div>
        </div>
      )}
      {question2 && (
        <div className={styles.container}>
          <div className={styles.Q}>Q</div>
          <div className={styles.text}>{question2}</div>
        </div>
      )}
    </div>
  )
}

export default TitleQuestion
