import { TrackJS } from 'trackjs'

import { APP_VERSION, IS_LOCALHOST, TRACK_JS_APP_NAME, TRACK_JS_TOKEN } from './constants'

export const install = () => {
  if (!IS_LOCALHOST) {
    TrackJS.install({
      token: TRACK_JS_TOKEN,
      application: TRACK_JS_APP_NAME,
      version: APP_VERSION,
    })
    TrackJS.addMetadata('feature', 'sprint-center')
  }
}

// We identify users by ID instead of email to protect their privacy.
//
// https://docs.trackjs.com/browser-agent/sdk-reference/agent-config/#userid
export const setUserId = (userId: string) => {
  if (!IS_LOCALHOST) {
    TrackJS.configure({ userId })
  }
}

// We don't clear the user ID since it helps us diagnose bugs. It will be updated
// in the unlikely situation that another student logs in using the same browser.

// We use the JWT from the login API response.
// TrackJS requires sessionId to be a string, so call this function with an empty
// string to clear the session context.
//
// https://docs.trackjs.com/browser-agent/sdk-reference/agent-config/#sessionid
export const setSessionId = (token: string) => {
  if (!IS_LOCALHOST) {
    TrackJS.configure({ sessionId: token })
  }
}
