import throttle from 'lodash.throttle'
import { useEffect, useState } from 'react'

export const DESKTOP_WIDTH = 992
export const TABLET_WIDTH = 768

// Use this hook in the cases where the DOM flow is different among devices to avoid re rendering components.

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    height: 0,
    width: 0,
  })

  const handleWindowSize = () => {
    const { innerHeight, innerWidth } = window

    setWindowSize({
      height: innerHeight,
      width: innerWidth,
    })
  }

  useEffect(() => {
    window.addEventListener(
      'resize',
      throttle(function () {
        handleWindowSize()
      }, 200),
    )

    handleWindowSize()

    return () => {
      window.removeEventListener('resize', handleWindowSize)
    }
  }, [])

  return windowSize
}
