import cn from 'classnames'
import Card from 'components/Card'
import Col from 'components/Col'
import { Expandable } from 'components/Expandable'
import Row from 'components/Row'
import React, { useState } from 'react'
import { CourseModule } from 'typings/courseModule'

import styles from './Module.module.scss'
import ModuleContent from './ModuleContent'
import ModuleHeader from './ModuleHeader'

type Props = {
  sprintSlug: string
  module: CourseModule
  expanded: boolean
  onExpand?: () => void
}

const Module: React.FC<Props> = ({ sprintSlug, module, expanded, onExpand }) => {
  const [hover, setHover] = useState(false)
  return (
    <Row>
      <Col xs={12}>
        <Card
          className={cn(styles.roundedRect, {
            [styles.roundedRectHover]: hover,
          })}
        >
          <ModuleHeader
            sprintSlug={sprintSlug}
            module={module}
            expanded={expanded}
            onExpand={onExpand}
            onHoverChange={(h) => setHover(h)}
          />
          <Expandable expanded={expanded}>
            <div className={styles.contentSeparator} />
            <ModuleContent sprintSlug={sprintSlug} module={module} />
          </Expandable>
        </Card>
      </Col>
    </Row>
  )
}

export default Module
