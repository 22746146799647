import { createSlice } from '@reduxjs/toolkit'

import { getFinalProjects } from '../../modules/api'

const resourcesSlice = createSlice({
  name: 'resources',
  initialState: {
    finalProjects: null,
  },
  reducers: {
    setFinalProjects(state, action) {
      state.finalProjects = action.payload
    },
  },
})

export default resourcesSlice.reducer

export const { setFinalProjects } = resourcesSlice.actions

export const fetchProjects = () => {
  return async (dispatch) => {
    try {
      const finalProjects = await getFinalProjects()
      dispatch(setFinalProjects(finalProjects.items))
    } catch (error) {
      console.error(error)
    }
  }
}
