import './App.sass'

import { Location, Redirect, Router } from '@reach/router'
import { fetchSprintsList } from 'features/sprint/sprintSlice'
import { doCheckAuthentication } from 'features/user/userSlice'
import { getPlatformUser } from 'modules/api'
import { redirectToMembershipIfUnauthorized } from 'modules/auth'
import { MEMBERSHIP_LOGIN_URL, MEMBERSHIP_RESET_PASSWORD_URL } from 'modules/constants'
import Home from 'pages/home/Home'
import RedirectIfLoggedOut from 'pages/RedirectIfLoggedOut'
import React, { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'

import Main from './components/Main'
import Sprint2 from './components/Sprint2'
import * as errorTracking from './modules/errorTracking'
import SprintDashboard from './pages/dashboard/SprintDashboard'
import DocumentCenter from './pages/documents/DocumentCenter'
import Documents from './pages/documents/Documents'
import LiveClasses from './pages/liveclass/LiveClasses'
import Module from './pages/module/Module'
import Modules from './pages/modules/Modules'
import PastProjectExamples from './pages/pastProjectExamples/PastProjectExamples'
import ProjectUpload from './pages/upload/ProjectUpload'
import V1Redirect from './pages/V1Redirect'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    GrowthPhysics?: any
  }
}

const gtmArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
}

TagManager.initialize(gtmArgs)

errorTracking.install()

// We used to keep the auth token in localStorage. Clean up this now-unused data.
localStorage.removeItem('token')

const App = () => {
  const isAuth = useSelector((state: RootState) => !!state.user.token)
  const userId = useSelector((state: RootState) => state.user.user.user_id)
  const UUID = useSelector((state: RootState) => state.user.user.UUID)
  const email = useSelector((state: RootState) => state.user.user.email)
  const sprints = useSelector((state: RootState) => state.sprint.sprints)

  const tagManagerArgs = {
    dataLayer: {
      userId,
    },
  }

  TagManager.dataLayer(tagManagerArgs)

  const dispatch = useDispatch()

  useEffect(() => {
    if (UUID) {
      window.GrowthPhysics?.identify(UUID, { email })
    }
  }, [UUID, email])

  useEffect(() => {
    dispatch(doCheckAuthentication())
  }, [dispatch])

  useEffect(() => {
    if (isAuth) {
      getPlatformUser().then((response) => {
        redirectToMembershipIfUnauthorized(response.data)
      })
    }
  }, [isAuth])

  useEffect(() => {
    if (isAuth && (!sprints || sprints.length === 0)) {
      dispatch(fetchSprintsList())
    }
  }, [isAuth, sprints, dispatch])

  return (
    <div className="app">
      <main>
        <Location>
          {({ location }) => (
            <Router primary={false}>
              {isAuth ? (
                <>
                  {/* ADDING A NEW PAGE? PUT TRACKING IN THE PAGE COMPONENT: usePageTracking.ts */}
                  {/* Authenticated */}

                  <Redirect from="/" to="/dashboard" noThrow />
                  <SprintDashboard path="/dashboard" />

                  <Main path="/">
                    {/* v1 routes */}
                    <Redirect from="/sprint/:sprintSlug" to="/:sprintSlug" noThrow />
                    <Redirect from="/sprint/:sprintSlug/resources" to="/:sprintSlug/documents" noThrow />
                    <Redirect
                      from="/sprint/:sprintSlug/module/:moduleSlug"
                      to="/:sprintSlug/modules/:moduleSlug"
                      noThrow
                    />
                    <Redirect
                      from="/sprint/:sprintSlug/module/:moduleSlug/:videoSlug"
                      to="/:sprintSlug/modules/:moduleSlug/:videoSlug"
                      noThrow
                    />

                    {/*
                      Root-level slug paths like this are inconvenient for routing.
                      Now any unmatched path string is routed to Sprint2, which
                      has to hit the API to validate the path and possibly redirect
                      to our actual fallback: SprintDashboard.

                      Page tracking is handled in the page components because:
                      (1) We need to check whether a sprint object exists to avoid
                          false-positive page views on the Home component.
                      (2) We send sprint data with sprint page visit tracking calls.

                      This is why we have all-caps reminder comments in this file.
                    */}
                    <Sprint2 path=":sprintSlug">
                      <Home path="/" />
                      <Modules path="modules" />
                      <Module path="modules/:moduleSlug" location={location} />
                      <Module path="modules/:moduleSlug/:videoSlug" location={location} />
                      <Documents path="documents">
                        <DocumentCenter path="/" />
                        <ProjectUpload path="upload-your-project" />
                        <PastProjectExamples path="past-project-examples" />
                      </Documents>
                      <LiveClasses path="live-sessions" />
                    </Sprint2>
                  </Main>
                </>
              ) : (
                <>
                  {/* ADDING A NEW PAGE? PUT TRACKING IN THE PAGE COMPONENT: usePageTracking.ts */}
                  {/* Not authenticated */}

                  {['/', '/login', '/dashboard', ':sprintSlug/*'].map((path) => (
                    <RedirectIfLoggedOut path={path} key={path} to={MEMBERSHIP_LOGIN_URL} />
                  ))}
                  {/* V1 routes with functionality that's missing in V2 */}
                  <V1Redirect path="/create-your-account" />

                  {['/reset-password', '/reset-your-password', '/set-password'].map((path) => (
                    <RedirectIfLoggedOut path={path} key={path} to={MEMBERSHIP_RESET_PASSWORD_URL} />
                  ))}
                </>
              )}
            </Router>
          )}
        </Location>
      </main>
    </div>
  )
}

export default App
