import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

import Page from '../../components/Page'
import styles from './Documents.module.scss'

const Documents = ({ ...rest }) => {
  const sprints = useSelector((state: RootState) => state.sprint.sprints)
  const selectedSprint = useSelector((state: RootState) => state.sprint.selectedSprint)
  const fetching = useSelector((state: RootState) => state.sprint.fetching)

  const sprint = useMemo(() => (selectedSprint >= 0 ? sprints[selectedSprint] : null), [sprints, selectedSprint])

  return (
    <Page className={styles.content} isLoading={!sprint || !sprint.course_modules || fetching}>
      {rest.children}
    </Page>
  )
}

export default Documents
