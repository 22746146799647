import cn from 'classnames'
import commonStyles from 'common.module.scss'
import { motion } from 'framer-motion'
import { ReactComponent as Ta } from 'images/nav/ta.svg'
import { emptySprintSection } from 'modules/sprint'
import React, { FC } from 'react'
import { Sprint } from 'typings/sprint'

import { INFO_CONTENT_VARIANTS, INFO_VARIANTS } from './Animations'
import ExternalLink from './ExternalLink'
import styles from './Info.module.scss'

type Props = {
  sprint: Sprint
}

const Info: FC<Props> = ({ sprint }) => {
  const {
    section_name: sectionName,
    slack_section_url: sectionURL,
    slack_support_url: supportURL,
    slack_announcement_url: announcementsURL,
    ta_name: taName,
    ta_slack_url: taURL,
    thumbnail: taImgURL,
  } = sprint?.sprint_section || emptySprintSection

  return (
    <motion.div className={`${styles.component}`} variants={INFO_VARIANTS}>
      <motion.div className={styles.content} variants={INFO_CONTENT_VARIANTS}>
        <div className={styles.channelSection}>
          <div className={styles.link}>
            {announcementsURL ? <ExternalLink link={announcementsURL}>#announcements</ExternalLink> : <>Not assigned</>}
          </div>
          <div className={styles.link}>
            {sectionURL && sectionName ? (
              <ExternalLink link={sectionURL}>#community-discussion</ExternalLink>
            ) : (
              <>Not assigned</>
            )}
          </div>
          <div className={styles.link}>
            {supportURL ? <ExternalLink link={supportURL}>#technical-support</ExternalLink> : <>Not assigned</>}
          </div>
        </div>
        <hr className={commonStyles.separator} />
        <div className={cn(styles.taSection, { [styles.taSectionDisabled]: !taImgURL || !taName })}>
          <div className={styles.icon}>
            {taImgURL && taName ? (
              <img className={styles.ta} src={taImgURL} alt={taName} />
            ) : (
              <Ta className={styles.ta} />
            )}
          </div>
          <div className={styles.info}>
            <div className={styles.title}>Your TA</div>
            {taURL && taName ? (
              <div className={styles.link}>
                <ExternalLink link={taURL}>{taName}</ExternalLink>
              </div>
            ) : (
              <div className={styles.noLink}>
                <span>Not assigned</span>
              </div>
            )}
          </div>
        </div>
      </motion.div>
    </motion.div>
  )
}

export default Info
