import cn from 'classnames'
import { ReactComponent as CloseSvg } from 'images/close.svg'
import { ReactComponent as FilterSvg } from 'images/filter.svg'
import React, { FC, useState } from 'react'

import { companySizes, industries } from './constants'
import DropdownFilter from './DropdownFilter'
import styles from './ResourcesFilters.module.scss'
import SelectedFilters from './SelectedFilters'

type Props = {
  companySizesSelected: Array<string>
  updateCompanySizesSelected: (selected: Array<string>) => void
  industriesSelected: Array<string>
  updateIndustriesSelected: (selected: Array<string>) => void
  sprintTermsSelected: Array<string>
  updateSprintTermsSelected: (selected: Array<string>) => void
  sprintTerms: Array<string>
  filteredProjectsCount: number
}

const ResourcesFilters: FC<Props> = ({
  companySizesSelected,
  updateCompanySizesSelected,
  industriesSelected,
  updateIndustriesSelected,
  sprintTermsSelected,
  updateSprintTermsSelected,
  sprintTerms,
  filteredProjectsCount,
}) => {
  const [showMobile, setShowMobile] = useState(false)

  const handleUpdateSelected = (newSelected) => {
    updateCompanySizesSelected(companySizes.filter((c) => newSelected.includes(c)))

    updateIndustriesSelected(industries.filter((c) => newSelected.includes(c)))

    updateSprintTermsSelected(sprintTerms.filter((c) => newSelected.includes(c)))
  }

  const handleClearAll = () => {
    handleUpdateSelected([])
  }

  const allSelected = [...companySizesSelected, ...industriesSelected, ...sprintTermsSelected]

  return (
    <>
      <div className={styles.toggler}>
        <div className={styles.filterContainer} onClick={() => setShowMobile(!showMobile)}>
          <FilterSvg />
          <div className={cn(styles.togglerTitle)}>filter</div>
        </div>
        {allSelected.length > 0 && (
          <div className={styles.clearAll} onClick={handleClearAll}>
            Clear all
          </div>
        )}
      </div>
      <div
        className={cn(styles.panel, 'flex-column flex-md-row', {
          'd-none d-md-flex': !showMobile,
          'd-flex': showMobile,
        })}
      >
        <div className={styles.mobileHeader}>
          <FilterSvg />
          <div className={styles.mobileTitle}>filter</div>
          <CloseSvg className={styles.closeMobile} onClick={() => setShowMobile(false)} />
        </div>
        <DropdownFilter
          items={companySizes}
          className={cn(styles.dropdownFilter, styles.dropdownFilter__first)}
          label="Company Size"
          selectedItems={companySizesSelected}
          onUpdate={(selected) => updateCompanySizesSelected(selected)}
        />
        <DropdownFilter
          items={industries}
          className={styles.dropdownFilter}
          label="Industry"
          selectedItems={industriesSelected}
          onUpdate={(selected) => updateIndustriesSelected(selected)}
        />
        <DropdownFilter
          items={sprintTerms}
          className={styles.dropdownFilter}
          label="Strategy Term"
          selectedItems={sprintTermsSelected}
          onUpdate={(selected) => updateSprintTermsSelected(selected)}
        />
        <div className={styles.filtersSelected}>Filters Selected: {allSelected.join(', ')}</div>
        <div>
          <div className={styles.viewResults} onClick={() => setShowMobile(!showMobile)}>
            View Results ({filteredProjectsCount})
          </div>
        </div>
      </div>
      <SelectedFilters selected={allSelected} updateSelected={handleUpdateSelected} />
    </>
  )
}

export default ResourcesFilters
