import React, { ReactNode } from 'react'

import Col from '../../components/Col'
import Row from '../../components/Row'
import ButtonExpander from './ButtonExpander'
import styles from './Section.module.scss'

const Section = ({
  title,
  isTwoTime,
  subtitle,
  children,
  someExpanded,
  onCollapseToggle,
}: {
  title?: string
  isTwoTime?: boolean
  children?: ReactNode
  someExpanded?: boolean
  subtitle?: string
  onCollapseToggle?: () => void
}) => (
  <>
    <Row>
      <Col xs={12} className={styles.sectionTitle}>
        {title}
      </Col>
    </Row>
    <Row noGutters className={styles.sectionSubtitleContainer}>
      <Col className={styles.sectionSubtitle} xs={12} md={9}>
        {subtitle}
      </Col>
      {!isTwoTime && <ButtonExpander expanded={someExpanded} onClick={onCollapseToggle} />}
    </Row>
    {children}
    <div style={{ marginBottom: '4rem' }} />
  </>
)

export default Section
