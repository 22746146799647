import { RouteComponentProps } from '@reach/router'
import cn from 'classnames'
import Breadcrumb from 'components/Breadcrumb'
import Col from 'components/Col'
import ExternalLink from 'components/ExternalLink'
import Row from 'components/Row'
import { fetchProjects } from 'features/resources/resourcesSlice'
import { useSprintPageTracking } from 'hooks/usePageTracking'
import { get, sortBy, uniq } from 'lodash'
import { ranges } from 'pages/pastProjectExamples/components/constants'
import Header from 'pages/pastProjectExamples/components/Header'
import ResourcesFilters from 'pages/pastProjectExamples/components/ResourcesFilters'
import { filterBySprintType, filterList } from 'pages/pastProjectExamples/utils'
import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { Sprint } from 'typings/sprint'

import styles from './PastProjectExamples.module.scss'

const PastProjectExamples: FC<RouteComponentProps> = () => {
  const dispatch = useDispatch()
  const [isContentsSticky, setContentsSticky] = useState(false)
  const [companySizesSelected, setCompanySizesSelected] = useState([])
  const [industriesSelected, setIndustriesSelected] = useState([])
  const [sprintTermsSelected, setSprintTermsSelected] = useState([])

  const sectionRefs = useRef({})
  const mobileContensWrapperRef = useRef(null)

  const finalProjects = useSelector((state: RootState) => state.resources.finalProjects)

  const sprints = useSelector((state: RootState) => state.sprint.sprints)
  const selectedSprint = useSelector((state: RootState) => state.sprint.selectedSprint)

  const sprint: Sprint = useMemo(() => sprints && sprints[selectedSprint], [sprints, selectedSprint])

  const sprintTypeSlug = get(sprints, `[${selectedSprint}].course.sprint_types[0].slug`, 'unknown')

  let filteredFinalProjects = filterBySprintType(finalProjects ?? [], sprintTypeSlug)

  const sprintTerms = sortBy<string>(
    uniq(filteredFinalProjects.map((fp) => get(fp, 'final_project.primary_sprint_term[0].name')).filter((st) => st)),
  )

  filteredFinalProjects = filterList(filteredFinalProjects, companySizesSelected, 'company_size')
  filteredFinalProjects = filterList(filteredFinalProjects, industriesSelected, 'industries')
  filteredFinalProjects = filterList(filteredFinalProjects, sprintTermsSelected, 'primary_sprint_term')

  useSprintPageTracking(sprint)

  useEffect(() => {
    dispatch(fetchProjects())
  }, [dispatch])

  const scrollToSection = (index) => {
    sectionRefs.current[index].scrollIntoView()
  }

  const workFinalProject = sortBy(filteredFinalProjects || [], (f) => f.post_title.toUpperCase())

  const handleScroll = () => {
    if (mobileContensWrapperRef.current) {
      setContentsSticky(mobileContensWrapperRef.current.getBoundingClientRect().top <= 0)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [])

  const loaded = sprint?.course_modules && finalProjects

  if (!sprint) {
    return null
  }

  return (
    <Row className={styles.mainContainer}>
      <Col xs={12} className={styles.breadcrumbContainer}>
        <Breadcrumb
          link={`/${sprint.slug}/documents`}
          linkTitle="Documents"
          currentTitle="Past Project Library"
          className={styles.breadcrumb}
        />
      </Col>
      <Header sprint={sprint} />
      {loaded && (
        <Col xs={12} className={cn(styles.roundedRect, styles.mainContainer)}>
          <ResourcesFilters
            companySizesSelected={companySizesSelected}
            updateCompanySizesSelected={(values) => setCompanySizesSelected(values)}
            industriesSelected={industriesSelected}
            updateIndustriesSelected={(values) => setIndustriesSelected(values)}
            sprintTermsSelected={sprintTermsSelected}
            updateSprintTermsSelected={(values) => setSprintTermsSelected(values)}
            sprintTerms={sprintTerms}
            filteredProjectsCount={workFinalProject?.length ?? 0}
          />

          <div className={styles.contentSection}>
            {ranges.map((r, index) => (
              <span key={index} className={styles.contentItem} onClick={() => scrollToSection(index)}>
                {r.start}
                {'-'}
                {r.end}
              </span>
            ))}
          </div>

          <div className={styles.container}>
            {workFinalProject.length > 0 && (
              <>
                <div>
                  {ranges.map((r, index) => (
                    <div key={index} className={styles.section} ref={(el) => (sectionRefs.current[index] = el)}>
                      <div className={styles.sectionTitle}>
                        {r.start}-{r.end}
                      </div>
                      <div className={styles.list}>
                        {workFinalProject
                          .filter(
                            (p) => p.post_title[0].toUpperCase() >= r.start && p.post_title[0].toUpperCase() <= r.end,
                          )
                          .map((p, index) => (
                            <ExternalLink key={index} className={styles.item} link={p.final_project.dropbox_link}>
                              {p.post_title}
                            </ExternalLink>
                          ))}
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  className={cn(styles.mobileContents, {
                    [styles.mobileContents__sticky]: isContentsSticky,
                  })}
                >
                  {ranges.map((r, index) => (
                    <span key={index} className={styles.contentItem} onClick={() => scrollToSection(index)}>
                      {r.start}
                      {'-'}
                      {r.end}
                    </span>
                  ))}
                </div>
              </>
            )}
            {workFinalProject.length === 0 && (
              <div className={cn(styles.section, styles.section__empty)}>
                Sorry, there are no results for the filters you entered
              </div>
            )}
          </div>
        </Col>
      )}
    </Row>
  )
}

export default PastProjectExamples
