import { TitleLink } from 'typings/titleLink'
import { VideoPost } from 'typings/video'
import { isLiveCaseDiscussionWithSectionUrls, ZoomLink } from 'typings/zoomLink'

export const zoomLinkMeetingUrl = (zoomLink: ZoomLink): string => {
  return isLiveCaseDiscussionWithSectionUrls(zoomLink) ? zoomLink.sectionMeetingUrl : zoomLink.link_url
}

export const zoomLinkRecording = (zoomLink: ZoomLink): string | VideoPost => {
  return isLiveCaseDiscussionWithSectionUrls(zoomLink) ? zoomLink.sectionRecordingUrl : zoomLink.video_item
}

export const extractLinks = /<a href="(.*?)".*?>(.*?)<\/a>/gi

export function getLinks(text: string): TitleLink[] {
  if (!text) return []

  const div = document.createElement('textarea')
  div.innerHTML = text
  text = div.firstChild.nodeValue.replaceAll(/\\+/g, '\\').replaceAll(/(\\")+/g, '"')
  div.remove()

  let match
  const list: TitleLink[] = []
  while ((match = extractLinks.exec(text)) !== null) {
    list.push({
      title: match[2],
      url: match[1],
    })
  }
  return list
}
