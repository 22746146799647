import { Expandable } from 'components/Expandable'
import { getNow } from 'modules/date'
import { newYorkDate } from 'modules/date'
import React, { FC, useState } from 'react'
import { useMemo } from 'react'
import { ZOOM_LINK_TYPES_META, ZoomLink } from 'typings/zoomLink'

import Col from '../../components/Col'
import Row from '../../components/Row'
import styles from './Event.module.scss'
import EventContent from './EventContent'
import EventItem from './EventItem'
import EventRecording from './EventRecording'
import { getLinks, zoomLinkMeetingUrl, zoomLinkRecording } from './utils'
import ZoomLinkButton from './ZoomLink'

type Props = {
  link: ZoomLink
  separator?: boolean
  hover?: (h: boolean) => void
  selected?: boolean
}

const Event: FC<Props> = ({ link, separator, hover, selected }) => {
  const [expanded, setExpanded] = useState(selected)

  const slides = useMemo(() => getLinks(link?.discussion_slides_html), [link?.discussion_slides_html])
  const preReadings = useMemo(() => link?.pre_readings_html, [link?.pre_readings_html])

  if (!link) return null

  const concluded = getNow().isAfter(newYorkDate(`${link.zoom_link_date} ${link.zoom_link_time}`).add(3, 'hour'))

  const eventTypeMeta = ZOOM_LINK_TYPES_META[link.zoom_link_type]

  return (
    <>
      <EventItem
        link={link}
        onExpand={() => setExpanded((e) => !e)}
        expanded={expanded}
        hover={hover}
        selected={selected}
      />
      <Expandable expanded={expanded}>
        <div className={styles.eventContentSeparator} />
        <Row>
          <Col xs={12} md={6}>
            {concluded ? (
              <div className={styles.eventConcluded}>The event has concluded.</div>
            ) : (
              <ZoomLinkButton url={zoomLinkMeetingUrl(link)} />
            )}
            {slides && slides.length > 0 && eventTypeMeta && (
              <EventContent title={`${eventTypeMeta.singularName} Slides`} links={slides} />
            )}
          </Col>
          <Col xs={12} md={6}>
            {preReadings && <EventContent title="Pre-Readings" innerHTML={preReadings} />}
            {eventTypeMeta?.isRecorded && (
              <EventRecording title={`${eventTypeMeta.singularName} Recording`} recording={zoomLinkRecording(link)} />
            )}
          </Col>
        </Row>
      </Expandable>
      {separator && <div className={styles.eventSeparator} />}
    </>
  )
}

export default Event
