import { UPLOAD_CHOICES_YES_NO } from 'modules/constants'
import Navigation from 'pages/upload/components/Navigation'
import { ProjectSubmissionForm } from 'pages/upload/components/projectSubmissionForm'
import React, { FC, useEffect } from 'react'

import Radio2 from '../../../components/Radio2'
import commonStyles from './Common.module.scss'

type Props = {
  step: number
  data: ProjectSubmissionForm
  updateHandler: (data: ProjectSubmissionForm) => void
  setValid: (valid: boolean) => void
  onPrevious: () => void
  onNext: () => void
  stepValid: boolean
  numberOfSteps: number
}

const Step4: FC<Props> = ({ data, updateHandler, setValid, step, onNext, onPrevious, stepValid, numberOfSteps }) => {
  useEffect(() => {
    setValid(data.ok_to_share_in_library !== undefined)
  }, [data, setValid])

  const setChoice = (val: boolean) => {
    updateHandler({ ok_to_share_in_library: val })
  }

  return (
    <div className={commonStyles.step}>
      <div className={commonStyles.title}>Share your project with future sprinters</div>
      <div className={commonStyles.stepDescription}>
        Would you be willing to share your project with future sprinters? Each sprint, the Section team picks standout
        final projects to use as examples for future classes. Sharing your project is a great way to give back to the
        community.
      </div>
      <Radio2 choices={UPLOAD_CHOICES_YES_NO} currentChoice={data.ok_to_share_in_library} handleChange={setChoice} />

      <Navigation
        numberOfSteps={numberOfSteps}
        step={step}
        onBack={onPrevious}
        onNext={onNext}
        nextDisabled={!stepValid}
      />
    </div>
  )
}

export default Step4
