import { ReactComponent as Warning } from 'images/error.svg'
import { ProjectSubmissionForm } from 'pages/upload/components/projectSubmissionForm'
import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

import stepStyles from '../Steps.module.scss'
import commonStyles from './Common.module.scss'
import styles from './Step6URL.module.scss'

type Props = {
  data: ProjectSubmissionForm
  updateHandler: (data: ProjectSubmissionForm) => void
  setValid: (valid: boolean) => void
}

const isValidUrl = (url) => {
  try {
    new URL(url)
  } catch {
    return false
  }

  return true
}

const Step6URL: FC<Props> = ({ data, updateHandler, setValid }) => {
  const [error, setError] = useState('')

  const sprints = useSelector((state: RootState) => state.sprint.sprints)
  const selectedSprint = useSelector((state: RootState) => state.sprint.selectedSprint)

  useEffect(() => {
    if (!data.project_link_url) {
      setValid(false)
      setError('')
    } else if (isValidUrl(data.project_link_url)) {
      setValid(true)
      setError('')
    } else {
      setValid(false)
      setError('Please specify a valid URL')
    }
  }, [data.project_link_url, setValid])

  const handleOnChange = ({ target }) => {
    updateHandler({
      course_post_id: sprints[selectedSprint].post_id,
      project_link_url: target.value,
    })
  }

  return (
    <>
      <div className={commonStyles.title}>Paste a link to your project</div>
      <div className={commonStyles.stepDescription}>
        You can share your project via Google Drive, Dropbox, or another platform of your choosing. Please double check
        your sharing permissions before pasting the link.
      </div>

      <textarea
        className={styles.input}
        placeholder="Paste Link Here"
        value={data.project_link_url}
        onChange={handleOnChange}
      />
      <div className={stepStyles.messages}>
        <div className={stepStyles.error}>
          {error && (
            <>
              <Warning className={stepStyles.warning} /> {error}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Step6URL
