import Dropdown from 'pages/upload/components/Dropdown'
import Input from 'pages/upload/components/Input'
import Navigation from 'pages/upload/components/Navigation'
import { ProjectSubmissionForm } from 'pages/upload/components/projectSubmissionForm'
import React, { FC, useEffect, useMemo } from 'react'
import { Sprint } from 'typings/sprint'

import commonStyles from './Common.module.scss'
import styles from './Step1.module.scss'

type Props = {
  sprint: Sprint
  step: number
  numberOfSteps: number
  data: ProjectSubmissionForm
  stepValid: boolean
  setValid: (valid: boolean) => void
  onUpdate: (data: ProjectSubmissionForm) => void
  onBack: () => void
  onNext: () => void
}

const Step1: FC<Props> = ({ sprint, step, data, stepValid, setValid, onUpdate, onBack, onNext, numberOfSteps }) => {
  useEffect(() => {
    setValid(!!data.company && !!data.industries && (!projectOptions?.length || !!data.project_option))
  })

  const handleCompanyUpdate = (value: string) => {
    onUpdate({ company: value })
  }

  const handleIndustrySelect = (value: string[]) => {
    onUpdate({ industries: value.join(',') })
  }

  const handleProjectOptionSelect = (value: string) => {
    onUpdate({ project_option: value })
  }

  const { projectOptions, industries } = useMemo(() => {
    const {
      final_project_per_course_info: { project_options: projectOptions, industries },
    } = sprint
    return { projectOptions, industries }
  }, [sprint])

  const industryOptions = useMemo(
    () => industries.map((industry) => ({ value: industry.slug, label: industry.name })),
    [industries],
  )

  const industryValues = useMemo(() => {
    return data.industries ? data.industries.split(',') : []
  }, [data])

  return (
    <div className={commonStyles.step}>
      <div className={commonStyles.title}>Tell us about your Project</div>
      <Input
        id="company"
        label="Company"
        className={styles.company}
        value={data.company || ''}
        setValue={handleCompanyUpdate}
      />
      <div className={styles.dropdowns}>
        <Dropdown
          value={industryValues}
          setValue={handleIndustrySelect}
          label="Industry"
          className={styles.industry}
          options={industryOptions}
          multiselect={true}
        />
        {projectOptions?.length > 0 && (
          <Dropdown
            value={data.project_option}
            setValue={handleProjectOptionSelect}
            label="Project Option"
            className={styles.projectOption}
            options={projectOptions.map((o) => ({ value: o, label: o }))}
          />
        )}
      </div>

      <Navigation numberOfSteps={numberOfSteps} step={step} onBack={onBack} onNext={onNext} nextDisabled={!stepValid} />
    </div>
  )
}

export default Step1
