import { Link } from '@reach/router'
import React, { FC, ReactNode } from 'react'

type Props = {
  link: string
  children: ReactNode
  className?: string
}

const InternalLink: FC<Props> = ({ link, children, className }) => (
  <Link to={link[0] === '/' ? link : `/${link}`} className={className}>
    <>{children}</>
  </Link>
)

export default InternalLink
