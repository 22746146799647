import { Link } from '@reach/router'
import cn from 'classnames'
import React, { FC } from 'react'

import styles from './Breadcrumb.module.scss'

type Props = {
  link: string
  linkTitle: string
  currentTitle: string
  className?: string
}

const Breadcrumb: FC<Props> = ({ link, linkTitle, currentTitle, className }) => (
  <div className={cn(styles.breadcrumb, className)}>
    <Link to={link} className={styles.link}>
      {linkTitle}
    </Link>
    <div className={styles.separator}>{'>'}</div>
    <div className={styles.last}>{currentTitle}</div>
  </div>
)

export default Breadcrumb
