import Button from 'components/Button'
import { PLAYER_ID, PLAYER_KEY, URL_PLAYER_LIBRARIES, URL_PLAYER_LIST } from 'modules/constants'
import { isString } from 'modules/typeof'
import React from 'react'
import ReactJWPlayer from 'react-jw-player'
import { VideoPost } from 'typings/video'

import styles from './EventRecording.module.scss'

const EventRecording = ({ title, recording }: { title: string; recording?: string | VideoPost }) => {
  let video: VideoPost
  let recordingUrl: string

  if (recording) {
    if (isString(recording)) {
      recordingUrl = recording
    } else {
      video = recording
    }
  }

  return (
    <div>
      <div className={styles.eventContentTitle}>{title}</div>

      {recordingUrl && (
        <Button label="Zoom Recording" external link={recordingUrl} className={styles.zoomRecordingButton} />
      )}

      {video && (
        <ReactJWPlayer
          playerId={video.video.video_key}
          className={PLAYER_ID}
          playerScript={`${URL_PLAYER_LIBRARIES}/${PLAYER_KEY}.js`}
          playlist={`${URL_PLAYER_LIST}/${video.video.video_key}`}
          onError={(e) => console.error(`Player error: ${e}`)}
        />
      )}

      {!recording && (
        <div className={styles.eventContentRecording}>Recordings will be posted here within 3 hours of the event.</div>
      )}
      <div style={{ marginBottom: '3rem' }} />
    </div>
  )
}

export default EventRecording
