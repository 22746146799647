export const buildSprintUrl = (slug) => `/${slug}`

export const buildModulesUrl = (sprintSlug) => `${buildSprintUrl(sprintSlug)}/modules`

export const buildLiveSessionsUrl = (sprintSlug) => `${buildSprintUrl(sprintSlug)}/live-sessions`

export const buildUrl = (sprintSlug: string, moduleSlug: string, videoSlug?: string, farthestTime?: string) => {
  const urlVideoSlug = videoSlug ? `/${videoSlug}` : ''
  const urlFarthestTime = farthestTime ? `/${farthestTime}` : ''

  return `${buildModulesUrl(sprintSlug)}/${moduleSlug}${urlVideoSlug}${urlFarthestTime}`
}

// S4-2633 - external links for SC project submission
const TYPEFORM_PROJECT_SUBMIT_MAP = {
  // 'section4-platform-strategy-sprint-03': 'https://sectionschool.typeform.com/to/rpbXzoYv',
  'section-strategic-planning-sprint-2024-03': 'https://sectionschool.typeform.com/to/rpbXzoYv',
}

export const getTypeformProjectSubmitLink = (sprintSlug: string) => TYPEFORM_PROJECT_SUBMIT_MAP[sprintSlug]

export const isTypeformProjectSubmit = (sprintSlug: string) =>
  Object.keys(TYPEFORM_PROJECT_SUBMIT_MAP).includes(sprintSlug)

export const buildUploadYourProjectLink = (sprintSlug: string) => `/${sprintSlug}/documents/upload-your-project`
