import cn from 'classnames'
import React, { ReactNode } from 'react'

import InternalOrExternalLink from '../../../components/InternalOrExternalLink'
import styles from './HeaderButton.module.scss'

type Props = {
  image: ReactNode
  label: string
  title: string
  url?: string
  disabled?: boolean
}

const HeaderButton: React.FC<Props> = ({ image, label, title, url, disabled }) => (
  <InternalOrExternalLink
    link={url}
    external={true}
    className={cn(styles.headerButton, {
      [styles.headerButton__disabled]: disabled,
    })}
  >
    <div className={styles.imageWrapper}>{image}</div>
    <div className={styles.body}>
      <div className={styles.label}>{label}</div>
      <div className={cn(styles.title, { [styles.titleDisabled]: disabled })}>{title}</div>
    </div>
  </InternalOrExternalLink>
)

export default HeaderButton
