import cn from 'classnames'
import { ReactComponent as LockSvg } from 'images/lock.svg'
import { MODULE_ITEM_STATUS } from 'modules/constants'
import { totalSeconds, watchedSeconds } from 'modules/functional'
import { calculateStatus } from 'modules/utils'
import moment from 'moment'
import React, { FC, useMemo } from 'react'
import { CourseModule } from 'typings/courseModule'

import styles from './ModuleStatus.module.scss'

type Props = {
  module: CourseModule
}

const ModuleStatus: FC<Props> = ({ module }) => {
  const percentWatched = useMemo(() => {
    const moduleItems = module.course_module.items

    const totalModuleSeconds = totalSeconds(moduleItems)
    const watchedModuleSeconds = watchedSeconds(moduleItems)

    return (watchedModuleSeconds / totalModuleSeconds) * 100
  }, [module])
  const [isAvailable, statusStr] = useMemo(() => {
    return calculateStatus(module.course_module.available_datetime_UTC, percentWatched)
  }, [module, percentWatched])

  const availableDatetime = useMemo(() => moment.unix(module.course_module.available_datetime_UTC).utc(), [module])

  const notYetStarted = statusStr === MODULE_ITEM_STATUS.NOT_YET_STARTED
  const inProgress = statusStr === MODULE_ITEM_STATUS.IN_PROGRESS
  const completed = statusStr === MODULE_ITEM_STATUS.COMPLETED

  return (
    <div className={styles.moduleStatus}>
      {isAvailable ? (
        <div className={styles.statusBar}>
          <div
            className={cn(styles.progressBar, {
              [styles.progressBarInProgress]: inProgress,
              [styles.progressBarNotYetStarted]: notYetStarted,
            })}
            style={{ width: `${percentWatched}%` }}
          />
        </div>
      ) : (
        <LockSvg className="d-none d-md-block" />
      )}
      <div className={styles.statusTextWrapper}>
        {isAvailable ? (
          <div
            className={cn(styles.statusText, {
              [styles.statusTextInProgress]: inProgress,
              [styles.statusTextNotYetStarted]: notYetStarted,
              [styles.statusTextCompleted]: completed,
            })}
          >
            {statusStr}
          </div>
        ) : (
          <>
            <div className={styles.availableTitle}>Available:</div>
            <div className={styles.available}>{availableDatetime.format('MMMM DD')}</div>
          </>
        )}
      </div>
    </div>
  )
}

export default ModuleStatus
