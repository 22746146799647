import Card from 'components/Card'
import React from 'react'

import Row from '../../components/Row'
import Sprint from './Sprint'
import styles from './Sprints.module.scss'
import { SprintType } from './typings'

const Sprints = ({ sprints, title, upcoming }: { sprints: SprintType[]; title: string; upcoming?: boolean }) => {
  return (
    <Card className={styles.sprints}>
      <h2>{title}</h2>
      <Row>
        {sprints.map((s, i) => (
          <Sprint key={i} sprint={s} upcoming={upcoming} />
        ))}
      </Row>
    </Card>
  )
}

export default Sprints
