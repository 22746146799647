import cn from 'classnames'
import { FADE_SPEED } from 'modules/constants'
import React, { useEffect, useRef, useState } from 'react'
import LoadingOverlay from 'react-loading-overlay'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

import commonStyles from '../common.module.scss'
import Footer from './Footer'
import Header from './Header'
import styles from './Page.module.scss'

const Page = ({
  hideNav,
  isLoading,
  ...props
}: {
  hideNav?: boolean
  isLoading?: boolean
} & React.HTMLProps<HTMLDivElement>) => {
  const [loading, setLoading] = useState(true)
  const user = useSelector((state: RootState) => state.user.user)
  const useLoading = loading || isLoading

  const pageRef = useRef<HTMLDivElement>()

  useEffect(() => {
    // Wait for user state to load. A user must have an email to log in.
    if (user.email) setLoading(false)
  }, [user])

  useEffect(() => {
    pageRef?.current.scrollTo(0, 0)
  }, [])

  return (
    <div
      ref={pageRef}
      className={cn(commonStyles.component, {
        [commonStyles.noNav]: hideNav,
      })}
    >
      <Header showNav={!hideNav} />
      {useLoading ? (
        <LoadingOverlay
          active={loading}
          spinner={
            <div className={styles.spinnerContainer}>
              <span className={styles.spinner1} />
              <span className={styles.spinner2} />
            </div>
          }
          fadeSpeed={FADE_SPEED}
          styles={{
            overlay: (base) => ({
              ...base,
              zIndex: 0,
              background: '#e3e3e3',
            }),
            wrapper: (base) => ({
              ...base,
              width: '100%',
              height: '100%',
            }),
          }}
        />
      ) : (
        <>
          <div
            {...props}
            style={{ ...props.style, background: '#e3e3e3' }}
            className={cn(`container-fluid ${commonStyles.content}`, props.className)}
          />
        </>
      )}
      <Footer />
    </div>
  )
}

export default Page
