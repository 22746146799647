import { totalSeconds, watchedSeconds } from 'modules/functional'
import { buildUrl } from 'modules/routing'
import { calculateStatus } from 'modules/utils'
import React, { useMemo } from 'react'
import { CourseModule } from 'typings/courseModule'

import VideoItem from './VideoItem'

type Props = {
  sprintSlug: string
  module: CourseModule
}

const Module: React.FC<Props> = ({ sprintSlug, module }) => {
  const [isAvailable, statusStr] = useMemo(() => {
    const moduleItems = module.course_module.items

    const totalModuleSeconds = totalSeconds(moduleItems)
    const watchedModuleSeconds = watchedSeconds(moduleItems)

    const percentWatched = (watchedModuleSeconds / totalModuleSeconds) * 100

    return calculateStatus(module.course_module.available_datetime_UTC, percentWatched)
  }, [module])

  const video = useMemo(() => {
    const items = module?.course_module?.items

    if (items && items.length > 0) {
      return items[0]
    }

    return undefined
  }, [module])

  const thumbnail = useMemo(() => {
    if (video) {
      return video.thumbnail
    }

    return ''
  }, [video])

  const videoLink = useMemo(() => {
    return buildUrl(sprintSlug, module?.slug, video?.slug)
  }, [sprintSlug, module, video])

  return (
    <VideoItem
      thumbnail={thumbnail}
      title={module.post_title}
      status={statusStr}
      link={videoLink}
      disabled={!isAvailable}
    />
  )
}

export default Module
