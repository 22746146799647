import { ReactComponent as CancelUpload } from 'images/upload/cancel-upload.svg'
import { ReactComponent as FileUpload } from 'images/upload/file-upload.svg'
import { ReactComponent as FileUploadDocument } from 'images/upload/file-upload-small.svg'
import { ReactComponent as UploadCompleted } from 'images/upload/upload-completed.svg'
import { ReactComponent as Warning } from 'images/upload/warning.svg'
import { getCanceller, getProjectUploadInfo, uploadProject } from 'modules/api'
import { ProjectSubmissionForm } from 'pages/upload/components/projectSubmissionForm'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

import ProgressBar from '../../../components/ProgressBar'
import stepsStyles from '../Steps.module.scss'
import commonStyles from './Common.module.scss'
import styles from './Step6Upload.module.scss'

const STAGES = {
  UPLOAD: 0, // Ready to upload
  UPLOADING: 1, // Self explanatory
  UPLOADED: 2, // Successfully uploaded
}

type Props = {
  updateHandler: (data: ProjectSubmissionForm) => void
  setValid: (valid: boolean) => void
}

const Step6Upload: FC<Props> = ({ updateHandler, setValid }) => {
  const [stage, setStage] = useState(STAGES.UPLOAD)
  const [file, setFile] = useState(null)
  const [uploaded, setUploaded] = useState(0)
  const [total, setTotal] = useState(0)
  const [canceller, setCanceller] = useState(null)
  const [error, setError] = useState('')

  const user = useSelector((state: RootState) => state.user.user)
  const sprints = useSelector((state: RootState) => state.sprint.sprints)
  const selectedSprint = useSelector((state: RootState) => state.sprint.selectedSprint)

  useEffect(() => {
    setValid(false)

    return () => {
      if (canceller) {
        canceller.cancel()
      }
    }
  }, [canceller, setValid])

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setError('')

      const file = acceptedFiles[0]

      if (file) {
        setFile(file)
        setTotal(file.size)
        setStage(STAGES.UPLOADING)

        const courseId = sprints[selectedSprint].post_id
        const studentId = user.user_id

        const response = await getProjectUploadInfo(file.name, courseId, studentId)

        try {
          const { url, fields } = response
          const canceller = getCanceller()
          setCanceller(canceller)
          await uploadProject(url, file, fields, progressHandler, canceller)
          setStage(STAGES.UPLOADED)
          setValid(true)
          updateHandler({
            course_post_id: courseId,
            s3_key: fields.key,
            originalFilename: file.name,
          })
        } catch (e) {
          // TODO
          console.error('[ERROR] onDrop', e)
        }
      }
    },
    [selectedSprint, setValid, sprints, updateHandler, user.user_id],
  )

  const onDropRejected = (rejections) => {
    // Relevant errors:
    //
    // {code: "file-invalid-type", message: "File type must be pdf, docx, pptx, video/mov, video/mp4"}
    // {code: "file-too-large", message: "File is larger than 20000000 bytes"}
    const rejection = rejections[0]

    if (rejection.errors && rejection.errors.find((error) => error.code === 'file-too-large')) {
      setError('File is too large. Maximum file size is 20MB')
    }
  }

  const handleCancel = () => {
    if (canceller) {
      canceller.cancel()
    }
    setStage(STAGES.UPLOAD)
  }

  const progressHandler = (uploaded) => {
    setUploaded(uploaded)
  }

  const ACCEPTED_TYPES =
    'application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, video/quicktime, video/mp4'
  const MAX_SIZE = 20000000 // 20MB

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: ACCEPTED_TYPES,
    maxSize: MAX_SIZE,
    onDrop,
    onDropRejected,
  })

  return (
    <>
      <div className={commonStyles.title}>Upload your file</div>
      <div {...getRootProps({ className: stepsStyles.dropZone })}>
        {stage === STAGES.UPLOAD && (
          <>
            <input {...getInputProps()} />
            <div className={stepsStyles.upload}>
              <div className={stepsStyles.icon}>
                <FileUpload />
              </div>
              <div>
                <div className={styles.chooseFile}>Choose file or drop file here</div>
                <div className={styles.fileFormats}>
                  Files must be in one of the following formats: PDF, Docx, Pptx, Mov or MP4 Under 20MB
                </div>
              </div>
            </div>
          </>
        )}
        {stage === STAGES.UPLOADING && (
          <>
            <div className={stepsStyles.upload}>
              <div className={stepsStyles.icon}>
                <FileUploadDocument />
              </div>
              <div className={stepsStyles.uploading}>
                <div className={stepsStyles.filename}>
                  <h3>{file?.path || 'File.pdf'}</h3>
                </div>
                <div className={stepsStyles.cancelUpload}>
                  <CancelUpload onClick={handleCancel} height="100%" />
                </div>
              </div>
            </div>
            <div className={stepsStyles.progressContainer}>
              <ProgressBar current={uploaded} max={total} noMessage />
            </div>
            <div className={stepsStyles.progressMessage}>
              {(uploaded / 1000000).toFixed(2)} MB of {(total / 1000000).toFixed(2)} MB{' '}
              {uploaded === 0 ? 0 : Math.round((uploaded * 100) / total)}% uploaded
            </div>
          </>
        )}

        {stage === STAGES.UPLOADED && (
          <>
            <div className={stepsStyles.upload}>
              <div className={stepsStyles.icon}>
                <UploadCompleted />
              </div>
              <div className={stepsStyles.filename}>
                <h3>{file?.path || 'File.pdf'}</h3>
                <p>File Upload Complete</p>
              </div>
              <div className={styles.reuploadButton} onClick={handleCancel}>
                <div>
                  <FileUploadDocument className={styles.reuploadButtonIcon} />
                </div>
                <div>Reupload</div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className={stepsStyles.messages}>
        <div className={stepsStyles.error}>
          {error && (
            <>
              <Warning className={stepsStyles.warning} /> {error}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Step6Upload
