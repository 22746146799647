import { navigate, useLocation } from '@reach/router'
import classNames from 'classnames'
import MenuIcon from 'components/MenuIcon'
import { motion } from 'framer-motion'
import React, { FC, useMemo } from 'react'
import { DeviceConfig } from 'typings/deviceConfig'
import { NavContextType } from 'typings/navContextType'

import { device, MENU_LABEL_VARIANTS, menuItemVariants } from './Animations'
import styles from './MenuItem.module.scss'
import { SPRINT_CENTER_2_ROOT } from './navItems'
import { NavContext } from './Sprint2'

type Props = {
  config: DeviceConfig
  src: string
  srcActive: string
  label: string
  itemPath?: string
  sprintSlug: string
  path?: string
  active?: boolean
  externalUrl?: string
  clickHandler?: (path: string) => void
}

const MenuItem: FC<Props> = ({
  config = device.PHONE,
  src,
  srcActive,
  label,
  clickHandler,
  sprintSlug,
  path,
  externalUrl,
}) => {
  const location = useLocation()

  const { current, itemPath } = useMemo(() => {
    const itemPathRoot = `${SPRINT_CENTER_2_ROOT}${sprintSlug}`
    const itemPath = path !== undefined ? `${itemPathRoot}/${path}` : undefined

    const locationPath = location.pathname

    const exactMatch = itemPath === locationPath || itemPath === `${locationPath}/`

    const current = exactMatch || (locationPath.startsWith(itemPath) && itemPath !== `${itemPathRoot}/`)

    return { current, itemPath }
  }, [sprintSlug, path, location])

  const { hideNav } = React.useContext<NavContextType>(NavContext)

  const defaultClickHandler = (path) => {
    if (externalUrl) {
      window.open(externalUrl, '_blank', 'noreferrer')
    } else {
      navigate(path)
      hideNav()
    }
  }

  return (
    <motion.div
      className={classNames(styles.item, {
        [styles.current]: current,
      })}
      variants={menuItemVariants(config)}
    >
      <MenuIcon
        src={src}
        srcActive={srcActive}
        label={label}
        clickHandler={clickHandler || defaultClickHandler}
        itemPath={itemPath}
        active={current}
      />
      <motion.div
        className={styles.label}
        variants={MENU_LABEL_VARIANTS}
        onClick={() => {
          clickHandler ? clickHandler(itemPath) : defaultClickHandler(itemPath)
        }}
      >
        {label}
      </motion.div>
    </motion.div>
  )
}

export default MenuItem
