export const ranges = [
  {
    start: 'A',
    end: 'E',
  },
  {
    start: 'F',
    end: 'J',
  },
  {
    start: 'K',
    end: 'O',
  },
  {
    start: 'P',
    end: 'T',
  },
  {
    start: 'U',
    end: 'Z',
  },
]

export const companySizes = [
  'Self-employed',
  '2-10',
  '11-50',
  '51-100',
  '101-500',
  '501-1,000',
  '1,001-5,000',
  '5,000+',
]

export const industries = [
  'Advertising, Media, or Branding',
  'B2B Software',
  'Consulting',
  'Consumer Tech',
  'Education or Edtech',
  'Finance or Fintech',
  'Food, Beverage, or CPG',
  'Healthcare',
  'Luxury',
  'Manufacturing',
  'Retail',
]

export const strategyTerms = [
  'Accelerant',
  'Appealing to Human Instinct',
  'Benjamin Button',
  'Growth x Margin',
  'Likeability',
  'Other',
  'Rundles',
  'Vertical Integration',
  'Visionary Storytelling',
]
