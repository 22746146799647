import axios from 'axios'
import { AUDIENCE_COOKIE_NAME, REFRESH_TOKEN_COOKIE_NAME, TOKEN_COOKIE_NAME } from 'modules/cookieConstants'
import { parseCookie, setCookie } from 'modules/cookieUtils'

import { authHeader, handleLogout } from './auth'
import { API_URL, API_URL_2, API_URL_SERVICES, COGNITO_CLIENT_ID, COGNITO_SSO_URL, COGNITO_URL } from './constants'

const AUTH_TOKEN_URL = `${COGNITO_URL}/oauth2/token`
const SSO_AUTH_TOKEN_URL = `${COGNITO_SSO_URL}/oauth2/token`

const PLATFORM_USER_URL = `${API_URL_SERVICES}/user`

const CURRENT_USER_INFO_URL = `${API_URL}/s4/v1/user/info/me`
const SPRINT_LIST_URL = `${API_URL}/s4/v1/feed/course/list/`
const SPRINT_URL = `${API_URL}/s4/v1/feed/course/latest`
const SPRINT_BY_ID_URL = (id) => `${API_URL}/s4/v1/course/post_id/${id}`
const UPDATE_POSITION_URL = `${API_URL}/s4/v1/playlist/user_history/update_item`
const FINAL_PROJECT_URL = `${API_URL}/s4/v1/feed/final_project/latest?offset=0&posts_per_page=-1`
const UPLOADED_PROJECT_INFO_URL = `${API_URL}/s4/v1/course/upload_final_project_v2`

const SIGNED_PROJECT_UPLOAD_URL = `${API_URL_2}/finalProject/signedUploadUrl`

// ----------- Axios config -----------

axios.interceptors.response.use(null, async function (error) {
  const originalRequest = error.config

  // If the first attempt at a request fails with a 401 Unauthorized response,
  // try to refresh the auth token then retry the request.
  if (originalRequest && !originalRequest._isRetry && authenticationFailed(error)) {
    const tokenData = await refreshAuthToken()
    const newToken = tokenData?.id_token

    if (!newToken) {
      handleLogout()
    }

    setCookie(TOKEN_COOKIE_NAME, newToken)
    // Update and retry the request.
    originalRequest.headers.Authorization = `Bearer ${newToken}`
    originalRequest._isRetry = true
    return axios(originalRequest)
  }

  return Promise.reject(error)
})

// ----------- Support functions ------------------

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const authenticationFailed = (error: any): boolean => {
  return error.response?.status === 401 || projectUploadAuthenticationFailed(error)
}

// An AWS Lambda function is used to authorize project uploads:
//  - See authorizer-wordpress-jwt/ in the serverless-api repo.
//  - staging Lambda: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/authorizer-wordpress-jwt-stage?tab=code
//  - production Lambda: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/authorizer-wordpress-jwt-prod?tab=code
// It returns 403 Forbidden when validation of the provided Cognito JWT fails.
//
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const projectUploadAuthenticationFailed = (error: any): boolean => {
  return error.response?.status === 403 && error.response?.config.url.indexOf('/finalProject/signedUploadUrl') >= 0
}

export const getCanceller = () => {
  const CancelToken = axios.CancelToken
  return CancelToken.source()
}

export const uploadProject = (url, file, options, progressHandler, canceller) => {
  const onUploadProgress = (progressEvent) => {
    const { loaded, total } = progressEvent
    progressHandler(loaded, total)
  }

  const formData = new FormData()

  Object.keys(options).forEach((key) => {
    formData.append(key, options[key])
  })
  formData.append('file', file)

  return axios.post(url, formData, {
    onUploadProgress,
    cancelToken: canceller.token,
  })
}

// ----------- API Calls ------------------

type ParamsForRefreshToken = {
  clientId: string

  authTokenUrl: string
}

// the audience property of the JWT is the Cognito App client id
// the auth token url is different for SSO and non-SSO
// Note: significant assumption here is that there are only two Cognito App clients
const getParamsForRefreshToken = (): ParamsForRefreshToken => {
  const clientId = parseCookie(AUDIENCE_COOKIE_NAME) || COGNITO_CLIENT_ID

  const authTokenUrl = clientId === COGNITO_CLIENT_ID ? AUTH_TOKEN_URL : SSO_AUTH_TOKEN_URL

  return { clientId, authTokenUrl }
}

const refreshAuthToken = () => {
  const { clientId, authTokenUrl } = getParamsForRefreshToken()

  const searchParams = new URLSearchParams({
    grant_type: 'refresh_token',
    client_id: clientId,
    refresh_token: parseCookie(REFRESH_TOKEN_COOKIE_NAME),
  } as Record<string, string>)

  return axios
    .post(authTokenUrl, searchParams)
    .then((response) => {
      return response.data
    })
    .catch(() => {
      handleLogout()
    })
}

export const getPlatformUser = () => {
  return axios.get(PLATFORM_USER_URL, {
    headers: authHeader(),
  })
}

export const getUserInfo = () => {
  // The only possible errors in this request are any regular http request errors

  return axios.get(CURRENT_USER_INFO_URL, {
    headers: authHeader(),
  })
}

export const getSprintsList = () => {
  // The only possible errors in this request are any regular http request errors

  if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
    return import('../pages/mockData').then((v) => v.list)
  }

  return axios
    .get(SPRINT_LIST_URL, {
      headers: authHeader(),
    })
    .then((response) => response.data)
}

export const getSprint = () => {
  // The only possible errors in this request are any regular http request errors

  return axios
    .get(`${SPRINT_URL}?_limit=1`, {
      headers: authHeader(),
    })
    .then((response) => response.data)
}

export const getSprintById = (id) => {
  if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
    return import('../pages/mockData').then((v) => v.sprints[id])
  }

  /*

    Possible Error Responses
    ========================

  --- Invalid id ---
  {
    "start_time": 1634918520.431043,
    "code": 404,
    "message": "bad post specified",
    "user_id": 11534,
    "error": "bad post specified"
  }

  --- Null parameter ---

  {
    "code": "rest_no_route",
    "message": "No route was found matching the URL and request method.",
    "data": {
        "status": 404
    }
  }

*/

  return axios
    .get(SPRINT_BY_ID_URL(id), {
      headers: authHeader(),
    })
    .then((response) => response.data)
}

export const updatePosition = (post_id, current_position) => {
  /*

    Possible Error Responses
    ========================

  * The current_position parameter is always a number, no errors should arise from that

  --- Null id ---
  {
    "code": "rest_missing_callback_param",
    "message": "Missing parameter(s): post_id",
    "data": {
        "status": 400,
        "params": [
          "post_id"
        ]
      }
    }

  --- Uknown id ---
  {
    "code": 404,
    "message": "invalid post_id",
    "user_id": 11534,
    "error": "invalid post_id",
    "jwt": {
        "user_id": "11534",
        "status": "valid"
    },
    "elapsed_ms": 1.8
  }

  */

  return axios
    .post(
      UPDATE_POSITION_URL,
      {
        post_id,
        current_position,
      },
      {
        headers: authHeader(),
      },
    )
    .then((response) => response.data.code)
}

export const getFinalProjects = () => {
  // The only possible errors in this request are any regular http request errors

  return axios
    .get(`${FINAL_PROJECT_URL}`, {
      headers: authHeader(),
    })
    .then((response) => response.data)
}

export const getProjectUploadInfo = (originalFilename, courseId, studentId) => {
  /*

    Possible Error Responses
    ========================

  --- null or invalid paramters ---
  {
    "message": "Internal Server Error"
  }

  */

  return axios
    .post(
      SIGNED_PROJECT_UPLOAD_URL,
      {
        originalFilename,
        courseId,
        studentId,
      },
      {
        headers: authHeader(),
      },
    )
    .then((response) => response.data)
}

export const saveUploadedProjectInfo = (data) => {
  /*

    Possible Error Responses
    ========================

  --- null paramter ---
  {
    "code": "rest_missing_callback_param",
    "message": "Missing parameter(s): course_post_id",
    "data": {
      "status": 400,
      "params": [
        "course_post_id"
        ]
      }
  }

  --- invalid paramter ---
  {
    "code": "rest_missing_callback_param",
    "message": "Missing parameter(s): course_post_id",
    "data": {
        "status": 400,
        "params": [
            "course_post_id"
        ]
    }
  }

  */

  return axios
    .post(UPLOADED_PROJECT_INFO_URL, data, {
      headers: authHeader(),
    })
    .then((response) => response.data)
}
