import Card from 'components/Card'
import { countDays, DEFAULT_DAYS } from 'modules/date'
import React, { FC } from 'react'
import { Sprint } from 'typings/sprint'
import { User } from 'typings/user'
import { isMicrosoftSprint } from 'utils/isFilteredSprint'

import styles from './HomeHeader.module.scss'
import YourSlackSection from './YourSlackSection'
import YourTA from './YourTA'

type Props = {
  user: User
  sprint: Sprint
}

const HomeHeader: FC<Props> = ({ user, sprint }) => {
  let days = DEFAULT_DAYS
  const isMicrosoft = isMicrosoftSprint(sprint?.slug)

  const course = sprint?.course

  if (sprint && course) {
    days = countDays(course.start_date, course.end_date)
  }

  return (
    <Card>
      <div className={styles.greeting}>
        Hi {user.first_name}, welcome to{' '}
        {!isMicrosoft && (
          <>
            <span className={styles.greetingDay}>
              Day {days.dayNum} of {days.sprintLength}
            </span>{' '}
            of
          </>
        )}
      </div>
      <div className={styles.sprintTitle}>{course?.short_name ?? ''}</div>
      {!sprint.endedMoreThanTwoWeeksAgo && (
        <>
          <div className={`row ${styles.buttons}`}>
            <div className="col-12 col-md-6">
              <YourTA sprint={sprint} />
            </div>
            <div className="col-12 col-md-6 mt-2 mt-md-0">
              <YourSlackSection sprint={sprint} />
            </div>
          </div>
        </>
      )}
    </Card>
  )
}

export default HomeHeader
