import { motion } from 'framer-motion'
import PropTypes from 'prop-types'

import { CLOSED, contentVariants, device, OPEN, overlayVariants } from './Animations'
import styles from './Content.module.scss'

const Content = ({ config, open, toggle, ...rest }) => (
  <div>
    <motion.div
      className={styles.content}
      initial={open ? OPEN : CLOSED}
      animate={open ? OPEN : CLOSED}
      variants={contentVariants(config)}
    >
      {rest.children}
    </motion.div>
    <motion.div
      className={styles.overlay}
      initial={open ? OPEN : CLOSED}
      animate={open ? OPEN : CLOSED}
      variants={overlayVariants(config)}
      onClick={toggle}
    />
  </div>
)

Content.propTypes = {
  config: PropTypes.object,
  open: PropTypes.bool,
  toggle: PropTypes.func,
}

Content.defaultProps = {
  config: device.PHONE,
}

export default Content
