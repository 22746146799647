import { Link } from '@reach/router'
import { dualDateString } from 'modules/date'
import React from 'react'

import Col from '../../components/Col'
import styles from './Sprint.module.scss'
import { SprintType } from './typings'

const Sprint = ({ sprint, upcoming }: { sprint: SprintType; upcoming?: boolean }) => {
  return (
    <Col xs={12} md={6} className={styles.sprint}>
      {upcoming ? (
        <>
          <div className={styles.thumbnailBackgroundUpcomming}>
            <img src={sprint.thumbnail_large} alt={sprint.short_name} />
          </div>
          <div className={styles.upcoming}>{sprint.short_name}</div>
        </>
      ) : (
        <>
          <Link to={`/${sprint.slug}`}>
            <div className={styles.thumbnailBackground}>
              <img src={sprint.thumbnail_large} alt={sprint.short_name} />
            </div>
            <div className={styles.sprintLink}>{sprint.short_name}</div>
          </Link>
        </>
      )}
      <p>{dualDateString(sprint.start_date, sprint.end_date)}</p>
    </Col>
  )
}

export default Sprint
