import { motion } from 'framer-motion'
import React, { forwardRef } from 'react'

type Props = {
  onExpandChanged: () => void
}

type Ref = HTMLDivElement

export const ExpandableTrigger = forwardRef<Ref, Props & React.HTMLProps<HTMLDivElement>>(
  ({ onExpandChanged, children, ...rest }, ref) => (
    <motion.div ref={ref} initial={false} onClick={onExpandChanged} style={rest.style} className={rest.className}>
      {children}
    </motion.div>
  ),
)

export default ExpandableTrigger
