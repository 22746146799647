import { ReactComponent as Close16x16Svg } from 'images/close-16x16.svg'
import React from 'react'

import styles from './SelectedFilters.module.scss'

const SelectedFilters = ({ selected, updateSelected }) => {
  if (!selected.length) {
    return null
  }

  const handleClearAll = () => {
    updateSelected([])
  }

  const handleDelete = (item) => {
    updateSelected(selected.filter((s) => s !== item))
  }

  return (
    <div className={styles.selectedFilters}>
      <div>
        {selected.map((item) => (
          <div key={item} className={styles.selectedItem}>
            {item}

            <Close16x16Svg className={styles.selectedItem__delete} onClick={() => handleDelete(item)} />
          </div>
        ))}
      </div>
      <div className={styles.clearAll} onClick={handleClearAll}>
        Clear all
      </div>
    </div>
  )
}

export default SelectedFilters
