import TagManager from 'react-gtm-module'
import { Sprint } from 'typings/sprint'

export const emailEvent = (email, event, title = 'N/A') => {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
    events: {
      event,
      title,
      email,
    },
  }

  TagManager.initialize(tagManagerArgs)
}

/**
 * Segment: The global variable `analytics` is defined in index.html.
 */

type pageVisitParams = {
  name?: string
  sprint?: Sprint
}

type pageVisitMetadata = {
  course_slug?: string
  cohort?: number
}

// We should generally favor required parameters over optional, but we make an exception
// here since:
// 1. analytics.page() ignores arguments if they are `undefined` (`name` and custom
//    properties), so we don't need a mess of branching logic to handle absent and
//    present arguments differently.
// 2. It's unclear what metadata might be added to tracking calls in the future.
//    Permutations and function name lengths could grow unwieldy.
export const trackPageVisit = ({ name, sprint }: pageVisitParams = {}): void => {
  const properties: pageVisitMetadata = {
    course_slug: sprint?.course?.course_slug,
    cohort: parseInt(sprint?.course?.cohort_number, 10) || undefined,
  }

  window.GrowthPhysics?.page(name, properties)
}
