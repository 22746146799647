import { buildUrl } from 'modules/routing'
import { calculateVideoStatus } from 'modules/video'
import React, { useMemo } from 'react'
import { VideoPost } from 'typings/video'

import VideoItem from './VideoItem'

type Props = {
  sprintSlug: string
  moduleSlug: string
  video: VideoPost
  availableDatetimeUTC: number
}

const Video: React.FC<Props> = ({ sprintSlug, moduleSlug, video, availableDatetimeUTC }) => {
  const [isAvailable, statusString] = useMemo(
    () => calculateVideoStatus(availableDatetimeUTC, video),
    [availableDatetimeUTC, video],
  )

  const { thumbnail, post_title } = video

  const videoLink = useMemo(() => buildUrl(sprintSlug, moduleSlug, video?.slug), [sprintSlug, moduleSlug, video])

  return (
    <VideoItem
      thumbnail={thumbnail}
      title={post_title}
      status={statusString}
      link={videoLink}
      disabled={!isAvailable}
    />
  )
}

export default Video
