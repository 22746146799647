/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Sprint } from 'typings/sprint'
import { UserState } from 'typings/user'

export function useAppData() {
  // This hook simplify the access to some parts of the redux store and is meant
  // to remove the repetition of selectors, imports and memoized values that are used
  // very frequently in the code. This in the future could be modified to return only
  // the values we use in the code and can be extended with other values that are
  // generated in several parts of the code.

  // The available Sprints
  const sprints: Sprint[] = useSelector((state: any) => state.sprint.sprints)

  // The selected Sprint
  const selectedSprint: number = useSelector((state: any) => state.sprint.selectedSprint)

  // The User returned by the server
  const userState: UserState = useSelector((state: any) => state.user)

  // The current Sprint and an variable indicating that there is a valid Sprint.
  const [sprint, hasSprint]: [Sprint, boolean] = useMemo(() => {
    if (sprints && sprints[selectedSprint]) {
      return [sprints[selectedSprint], true]
    }
    return [{} as Sprint, false]
  }, [sprints, selectedSprint])

  // This is the initial detection of the old Sprints. It can be extended or changed if required.
  const isSC1 = !sprint.course?.google_calendar_link && !sprint.zoom_links?.length

  // Fetching status of the sprint
  const fetching: boolean = useSelector((state: any) => state.sprint.fetching)

  // For convenience and to avoid reimporting, the dispatch method.
  const dispatch = useDispatch()

  return {
    isSC1,
    sprints,
    sprint,
    hasSprint,
    selectedSprint,
    user: userState.user,
    userState,
    fetching,
    dispatch,
  }
}
