import cn from 'classnames'
import Button from 'components/Button'
import Card from 'components/Card'
import { ReactComponent as UploadSvg } from 'images/upload.svg'
import { ReactComponent as UploadCheckboxSvg } from 'images/upload-checkbox.svg'
import { ReactComponent as UploadDisabledSvg } from 'images/upload-disabled.svg'
import { isEmpty } from 'lodash'
import { formatDateSubmitted, formatDueDate, formatEarliestSubmissionDate, getNow } from 'modules/date'
import { buildUploadYourProjectLink, getTypeformProjectSubmitLink, isTypeformProjectSubmit } from 'modules/routing'
import moment from 'moment'
import React, { useMemo } from 'react'
import { Sprint } from 'typings/sprint'

import commonStyles from './common.module.scss'
import styles from './SubmitYourProject.module.scss'

type Props = {
  sprint: Sprint
}

const SubmitYourProject: React.FC<Props> = ({ sprint }) => {
  const dueDatetime = useMemo(() => {
    const { project_due_date } = sprint?.final_project_per_course_info ?? {}

    return moment(`${project_due_date} 23:59`)
  }, [sprint])

  const projectUploadEnabledDate = useMemo(() => {
    const { project_upload_enabled_date } = sprint?.final_project_per_course_info ?? {}

    return moment(`${project_upload_enabled_date} 00:00`)
  }, [sprint?.final_project_per_course_info])

  const projectUploadEnabledDateStr = useMemo(
    () => formatEarliestSubmissionDate(projectUploadEnabledDate),
    [projectUploadEnabledDate],
  )

  const dueDatetimeStr = useMemo(() => formatDueDate(dueDatetime), [dueDatetime])

  const disabled = useMemo(() => {
    return getNow().isBefore(projectUploadEnabledDate)
  }, [projectUploadEnabledDate])

  const projectSubmitted = useMemo(() => !isEmpty(sprint?.final_project_v2), [sprint])

  const dateSubmittedStr = useMemo(() => {
    const { date_submitted } = sprint?.final_project_v2 ?? {}

    if (!date_submitted) {
      return ''
    }

    return formatDateSubmitted(date_submitted)
  }, [sprint])

  let link = buildUploadYourProjectLink(sprint.slug)
  let linkExternal

  if (isTypeformProjectSubmit(sprint.slug)) {
    link = getTypeformProjectSubmitLink(sprint.slug)
    linkExternal = true
  }

  return (
    <Card>
      <div className={styles.titleContainer}>
        {projectSubmitted ? (
          <>
            <UploadCheckboxSvg className={styles.uploadCheckbox} />
            <div>
              <div className={commonStyles.title}>Project Submitted</div>
              <div className={styles.submittedDate}>{dateSubmittedStr}</div>
            </div>
          </>
        ) : (
          <div className={commonStyles.title}>Submit Your Project</div>
        )}
      </div>
      <div className={commonStyles.description}>
        Due on {dueDatetimeStr} US Eastern Time. You can submit as early as {projectUploadEnabledDateStr}.
      </div>
      <Button
        className={cn(styles.button, { [styles.button__disabled]: disabled })}
        labelClassName={styles.buttonLabel}
        image={disabled ? <UploadDisabledSvg /> : <UploadSvg />}
        label={`${projectSubmitted ? 'Resubmit ' : 'Submit'} Your Project`}
        link={disabled ? undefined : link}
        external={linkExternal}
      />
    </Card>
  )
}

export default SubmitYourProject
