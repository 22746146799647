import { createSlice } from '@reduxjs/toolkit'
import { get } from 'lodash'
import { getSprint, getSprintById, getSprintsList, updatePosition } from 'modules/api'
import { handleLogout } from 'modules/auth'
import { STATUS_CODE_FORBIDDEN, STATUS_CODE_UNAUTHORIZED, STATUS_INVALID } from 'modules/constants'
import { newYorkDate } from 'modules/date'
import moment from 'moment'
import { Sprint } from 'typings/sprint'

const sprintSlice = createSlice({
  name: 'sprint',
  initialState: {
    sprints: [] as Sprint[],
    selectedSprint: -1,
    error: null,
    fetching: false,
  },
  reducers: {
    fetchSprintsSuccess(state, action) {
      state.sprints = action.payload
      state.selectedSprint = -1
      state.fetching = false
    },
    fetchSprintSuccess(state, action) {
      let sprint: Sprint = action.payload
      const index = state.sprints.findIndex((s) => s.post_id === sprint.post_id)

      if (index >= 0) {
        // NOTE CDS-749: The returned initial sprint list is not the same as the single sprint fetched here. In order to keep it working I just merge this result with
        //       the initial one in the state. Maybe this should be fixed in the API, and I'm pretty sure that is the source of the problem of CDS-734.
        sprint = { ...state.sprints[index], ...sprint }
        state.sprints[index] = sprint
      } else {
        state.sprints.push(sprint)
      }

      // The next part of the code will add several flags to the sprint in order to avoid repetitions in the code and simplify the front end design.
      const sprintEndDate = newYorkDate(sprint.end_date)

      sprint.ended = moment().isAfter(sprintEndDate)
      sprint.endedMoreThanTwoWeeksAgo = moment().isAfter(sprintEndDate.add(2, 'week'))

      state.fetching = false
    },
    selectSprintSuccess(state, action) {
      state.selectedSprint = action.payload
    },
    postUpdatePositionSuccess(state, action) {
      const { moduleSlug, videoSlug, currentTime } = action.payload

      const sprint = state.sprints[state.selectedSprint]
      const module = sprint?.course_modules.find((m) => m.slug === moduleSlug)
      if (module) {
        const video = module?.course_module?.items.find((v) => v.slug === videoSlug)

        if (video) {
          const currentTimeStr = Math.round(currentTime).toString()
          video.user.history.continue_at_time = currentTimeStr

          const farthest = parseInt(video?.user?.history?.farthest_watched_time) || 0
          if (currentTime > farthest) {
            video.user.history.farthest_watched_time = currentTimeStr
          }
        }
      }
    },
    sprintError(state, action) {
      state.error = action.payload
    },
    setFetching(state, action) {
      state.fetching = action.payload
    },
  },
})

export const {
  fetchSprintsSuccess,
  fetchSprintSuccess,
  selectSprintSuccess,
  postUpdatePositionSuccess,
  sprintError,
  setFetching,
} = sprintSlice.actions

export default sprintSlice.reducer

export const fetchSprints = () => {
  return async (dispatch, getState) => {
    const { sprint } = getState()

    // Note: Since different parts of the application may call fetchSprints (For example, the dashboard followed by the
    //       StudentCenter component), and a current sprint may(/is likely to) have been selected on, for example, the
    //       dashboard, the code below ensures that if there are already one or more sprints in client side state, they
    //       are not re-fetched.
    //
    //       In order to get new/the latest sprint data, the user must reload the page/app in their browser
    if (sprint.sprints && sprint.sprints.length === 0) {
      try {
        const response = await getSprint()

        if (response.jwt && response.jwt.status === STATUS_INVALID) {
          handleLogout()
        }

        return dispatch(fetchSprintsSuccess(response.items))
      } catch (error) {
        dispatch(sprintError(error))
      }
    }
  }
}

export const fetchSprintsList = () => {
  return async (dispatch) => {
    try {
      dispatch(setFetching(true))
      const response = await getSprintsList()

      if (response.jwt && response.jwt.status === STATUS_INVALID) {
        handleLogout()
      }

      return dispatch(fetchSprintsSuccess(response.items))
    } catch (error) {
      dispatch(sprintError(error))
    }
  }
}

export const fetchSprintById = (id) => {
  return async (dispatch) => {
    try {
      await dispatch(setFetching(true))

      const response = await getSprintById(id)

      if (response.jwt && response.jwt.status === STATUS_INVALID) {
        handleLogout()
      }

      await dispatch(fetchSprintSuccess(response.items[0]))
    } catch (error) {
      dispatch(sprintError(error))
    }
  }
}

export const selectSprintBySlug = (slug) => {
  return async (dispatch, getState) => {
    try {
      const sprints = getState().sprint.sprints
      const index = sprints.findIndex((s) => s.slug === slug)

      if (index >= 0) {
        await dispatch(selectSprintSuccess(index))
        await dispatch(fetchSprintById(sprints[index].post_id))
      }
    } catch (error) {
      dispatch(sprintError(error))
    }
  }
}

export const postUpdatePosition = (moduleSlug, videoSlug, postId, currentTime) => {
  return async (dispatch) => {
    try {
      await updatePosition(postId, currentTime)

      return dispatch(
        postUpdatePositionSuccess({
          moduleSlug,
          videoSlug,
          currentTime,
        }),
      )
    } catch (error) {
      await dispatch(sprintError(error && error.message))

      const status = get(error, 'response.status')

      if (status === STATUS_CODE_UNAUTHORIZED || status === STATUS_CODE_FORBIDDEN) {
        handleLogout()
      }
    }
  }
}
