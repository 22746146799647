import Navigation from 'pages/upload/components/Navigation'
import { ProjectSubmissionForm } from 'pages/upload/components/projectSubmissionForm'
import React, { FC, useEffect } from 'react'

import Radio2 from '../../../components/Radio2'
import commonStyles from './Common.module.scss'

const choices = [
  { val: true, label: 'Upload project file' },
  { val: false, label: 'Paste a link to your project' },
]

type Props = {
  step: number
  data: ProjectSubmissionForm
  updateHandler: (data: ProjectSubmissionForm) => void
  setValid: (valid: boolean) => void
  onPrevious: () => void
  onNext: () => void
  stepValid: boolean
  numberOfSteps: number
}

const Step5: FC<Props> = ({ data, updateHandler, setValid, step, onNext, onPrevious, stepValid, numberOfSteps }) => {
  useEffect(() => {
    setValid(data.uploadFile !== undefined)
  }, [data, setValid])

  const setChoice = (val: boolean) => {
    updateHandler({ uploadFile: val })
  }

  return (
    <div className={commonStyles.step}>
      <div className={commonStyles.title}>How would you like to submit your project?</div>
      <div className={commonStyles.stepDescription}>
        You can choose to upload the project file from your computer or paste a link to the project.
      </div>
      <Radio2 choices={choices} currentChoice={data.uploadFile} handleChange={setChoice} />

      <Navigation
        numberOfSteps={numberOfSteps}
        step={step}
        onBack={onPrevious}
        onNext={onNext}
        nextDisabled={!stepValid}
      />
    </div>
  )
}

export default Step5
