import cn from 'classnames'
import Card from 'components/Card'
import React, { FC, ReactNode } from 'react'

import Button from '../../components/Button'
import styles from './DocumentCenterItem.module.scss'

interface Props {
  primary?: boolean
  buttonText?: string
  icon: ReactNode
  title: string
  subtitle?: string
  content?: string
  buttonIcon: ReactNode
  link?: string
  linkExternal?: boolean
  hidden?: boolean
  disabled?: boolean
}

const DocumentCenterItem: FC<Props> = ({
  primary,
  icon,
  title,
  subtitle,
  content,
  buttonIcon,
  link,
  linkExternal,
  buttonText,
  hidden,
  disabled,
}) => {
  if (hidden) {
    return null
  }

  return (
    <div className={styles.items}>
      <div className="row">
        <Card className={`col-12 ${styles.item}`}>
          <div className={styles.iconWrapper}>{icon}</div>
          <div className={styles.itemMain}>
            <div className={styles.content}>
              <h3>{title}</h3>
              {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
              <p>{content || ''}</p>
            </div>

            <Button
              className={cn(styles.button, {
                [styles.primary]: primary && !disabled,
                [styles.button__disabled]: disabled,
              })}
              labelClassName={cn({
                [styles.primaryLabel]: primary && !disabled,
                [styles.disabledLabel]: disabled,
              })}
              image={buttonIcon}
              link={disabled ? undefined : link}
              label={buttonText || title}
              external={linkExternal}
            />
          </div>
        </Card>
      </div>
    </div>
  )
}

export default DocumentCenterItem
