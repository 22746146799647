import styles from './ProgressBar.module.scss'

const ProgressBar = ({ current, max, noMessage }) => (
  <div className={styles.component}>
    {!noMessage && (
      <div className={styles.message}>
        Step {current} of {max}
      </div>
    )}
    <div className={styles.progress}>
      <div
        className={styles.complete}
        // style={{ width: `${Math.trunc((current / max) * 100)}%` }}
        style={{
          width: `${Math.trunc((Number(current.toString().replaceAll(/[a-z]/g, '')) / max) * 100)}%`,
        }}
      />
    </div>
  </div>
)

export default ProgressBar
