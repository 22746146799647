import Card from 'components/Card'
import { emptySprintSection } from 'modules/sprint'
import React from 'react'
import { Sprint } from 'typings/sprint'

import commonStyles from './common.module.scss'
import SlackChannel from './SlackChannel'
import styles from './SlackChannels.module.scss'
import { slackDescriptions } from './slackDescriptions'

type Props = {
  sprint: Sprint
}

const SlackChannels: React.FC<Props> = ({ sprint }) => {
  const { slack_announcement_url, slack_section_url, slack_support_url, ta_name, ta_slack_url } =
    sprint?.sprint_section || emptySprintSection

  const filterChannels = sprint.slug === 'section4-product-positioning-sprint-03'

  return (
    <Card>
      <div className={commonStyles.title}>Slack Channel</div>
      <div className={commonStyles.description}>Below is a list of your slack channels and what they are.</div>
      <div className={styles.list}>
        {!filterChannels && (
          <SlackChannel
            title="announcements"
            description={slackDescriptions.announcement}
            url={slack_announcement_url}
          />
        )}
        <SlackChannel title="community-discussion" description={slackDescriptions.section} url={slack_section_url} />
        <SlackChannel title="technical-support" description={slackDescriptions.support} url={slack_support_url} />
        {!filterChannels && (
          <SlackChannel
            title={ta_name ? `ta-${ta_name.toLowerCase().replace(/\s/g, '-')}` : ''}
            description={slackDescriptions.ta}
            url={ta_slack_url}
          />
        )}
      </div>
    </Card>
  )
}

export default SlackChannels
