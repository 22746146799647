import { RouteComponentProps } from '@reach/router'
import Card from 'components/Card'
import { useGenericPageTracking } from 'hooks/usePageTracking'
import { getNow } from 'modules/date'
import moment from 'moment'
import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { Sprint } from 'typings/sprint'

import Page from '../../components/Page'
import styles from './SprintDashboard.module.scss'
import Sprints from './Sprints'

const SprintDashboard: FC<RouteComponentProps> = () => {
  const sprints = useSelector((state: RootState) => state.sprint.sprints)
  const user = useSelector((state: RootState) => state.user)

  useGenericPageTracking('Dashboard')

  const { active, upcoming, completed } = useMemo(() => {
    const active = []
    const upcoming = []
    const completed = []
    const now = getNow()

    ;(sprints ?? []).forEach((sprint: Sprint) => {
      const sprintStart = moment(sprint.start_date)
      const sprintEnd = moment(sprint.end_date)

      if (now.isBetween(sprintStart, sprintEnd)) {
        active.push(sprint)
      } else if (now.isBefore(sprintStart)) {
        upcoming.push(sprint)
      } else if (now.isAfter(sprintEnd)) {
        completed.push(sprint)
      }
    })

    return {
      active,
      upcoming,
      completed,
    }
  }, [sprints])

  return (
    <Page hideNav={true} className={styles.page}>
      <Card className={styles.header}>
        <h2>Sprint Dashboard</h2>
        <h1>Welcome, {user.user.first_name}</h1>
        <p>Below you can view your upcoming, current and completed sprints.</p>
      </Card>
      {active.length > 0 && <Sprints title="Active Sprints" sprints={active} />}
      {upcoming.length > 0 && <Sprints title="Upcoming Sprints" sprints={upcoming} upcoming />}
      {completed.length > 0 && <Sprints title="Completed Sprints" sprints={completed} />}
    </Page>
  )
}

export default SprintDashboard
