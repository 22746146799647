import { trackPageVisit } from 'modules/eventTracking'
import { useEffect } from 'react'
import { Sprint } from 'typings/sprint'

export const useGenericPageTracking = (name?: string) => {
  useEffect(() => {
    trackPageVisit({ name })
  }, [name])
}

export const useSprintPageTracking = (sprint: Sprint, name?: string) => {
  useEffect(() => {
    // Ensure a sprint is present because
    // 1. We want to capture sprint-specific metadata.
    // 2. Unmatched path requests are routed to the sprint home page due to our
    //    root-level slug paths. For an invalid slug, there will be no sprint data.
    if (sprint?.course?.short_name) {
      trackPageVisit({ sprint, name })
    }
    // We only want this to run when the sprint is first loaded, not every time
    // it changes, so we use a stable sprint-identifying property as a dependency.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sprint?.course?.short_name])
}
