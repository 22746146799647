import PropTypes from 'prop-types'
import { Radio, RadioGroup } from 'react-radio-group'

import styles from './Radio2.module.scss'

const Radio2 = ({ name, choices, currentChoice, handleChange }) => (
  <RadioGroup className={styles.choices} name={name} selectedValue={currentChoice} onChange={handleChange}>
    {choices.map((choice) => (
      <label key={`choice-${choice.val}`}>
        <Radio value={choice.val} />
        <span className={styles.radioButton} />
        <div className={styles.radioLabel}>{choice.label}</div>
      </label>
    ))}
  </RadioGroup>
)

Radio2.propTypes = {
  name: PropTypes.string.isRequired,
  choices: PropTypes.array.isRequired,
  currentChoice: PropTypes.any,
  handleChange: PropTypes.func.isRequired,
}

Radio2.defaultProps = {
  name: 'choice',
  choices: [],
}

export default Radio2
