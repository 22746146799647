import cn from 'classnames'
import Col from 'components/Col'
import React, { FC } from 'react'
import { Sprint } from 'typings/sprint'

import styles from './Header.module.scss'

type Props = {
  sprint: Sprint
}

const Header: FC<Props> = ({ sprint }) => (
  <Col xs={12} className={cn(styles.roundedRect, styles.header)}>
    <div className={styles.title}>Past Project Library</div>
    <div className={styles.sprintTitle}>{sprint?.short_name}</div>
    <div className={styles.description}>Explore student projects from previous sprints in the section below.</div>
  </Col>
)

export default Header
