import classNames from 'classnames'
import { ReactComponent as LogoSvg } from 'images/logo.svg'
import { ReactComponent as LogoFullSvg } from 'images/logo-full.svg'
import { ReactComponent as ArrowDropDownSvg } from 'images/nav/arrow-drop-down.svg'
import { ReactComponent as UserSvg } from 'images/user.svg'
import React, { FC, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

import styles from './Header.module.scss'
import { navItems } from './navItems'
import PhoneNav from './PhoneNav'
import UserMenu from './UserMenu'

type Props = {
  showNav: boolean
}

const Header: FC<Props> = ({ showNav }) => {
  const user = useSelector((state: RootState) => state.user.user)
  const userMenuControlRefPhone = useRef()
  const userMenuControlRef = useRef()

  const [showUserMenuPhone, setShowUserMenuPhone] = useState(false)
  const [showUserMenu, setShowUserMenu] = useState(false)

  const toggleUserMenuPhone = () => setShowUserMenuPhone(!showUserMenuPhone)
  const toggleUserMenu = () => setShowUserMenu(!showUserMenu)

  return (
    <header className={styles.component}>
      {/* Phone */}
      <div className={`d-flex d-md-none ${styles.columns}`}>
        {showNav && <PhoneNav items={navItems} />}
        <div className={classNames(styles.brand, { [styles.noNav]: !showNav })}>
          <LogoSvg className={styles.logo} />
        </div>
        <div ref={userMenuControlRefPhone} className={styles.user} onClick={toggleUserMenuPhone}>
          <UserSvg />
          {showUserMenuPhone && (
            <UserMenu showNav={showNav} menuControlRef={userMenuControlRefPhone} toggle={toggleUserMenuPhone} />
          )}
        </div>
      </div>
      {/* Desktop and tablet */}
      <div className={`d-none d-md-flex ${styles.columns}`}>
        <div className={styles.brand}>
          <LogoFullSvg className={styles.fullLogo} />
        </div>
        <div className={styles.user}>
          <div ref={userMenuControlRef} className={styles.clickArea} onClick={toggleUserMenu}>
            Hi, {user.first_name}
            <ArrowDropDownSvg className={styles.arrow} />
          </div>
          {showUserMenu && (
            <UserMenu
              className={styles.userMenu}
              menuControlRef={userMenuControlRef}
              showNav={showNav}
              toggle={toggleUserMenu}
            />
          )}
        </div>
      </div>
    </header>
  )
}

export default Header
