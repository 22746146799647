import Card from 'components/Card'
import { FORMAT_DATE_TIME_MINUTES, getNow } from 'modules/date'
import { buildLiveSessionsUrl } from 'modules/routing'
import moment from 'moment'
import React, { useMemo } from 'react'
import { Sprint } from 'typings/sprint'

import styles from './LiveClassesAndEvents.module.scss'
import LiveClassOrEvent from './LiveClassOrEvent'
import ViewAll from './ViewAll'

type Props = {
  sprint: Sprint
}

const LiveClassesAndEvents: React.FC<Props> = ({ sprint }) => {
  const events = useMemo(() => {
    if (!sprint || !sprint.zoom_links || !sprint.zoom_links.length) return []
    const now = getNow()
    return sprint?.zoom_links
      .filter(
        (link) =>
          !now.isSameOrAfter(
            moment(`${link.zoom_link_date} ${link.zoom_link_time}`, FORMAT_DATE_TIME_MINUTES).add(1, 'hour'),
          ),
      )
      .slice(0, 3)
  }, [sprint])

  return (
    <Card className={styles.liveClassesAndEvents}>
      <div className={styles.header}>
        <div className={styles.title}>Live Classes & Events</div>
        <ViewAll url={buildLiveSessionsUrl(sprint.slug)} className={styles.viewAll} />
      </div>

      {events.length > 0 ? (
        events.map((event, index) => <LiveClassOrEvent key={index} zoomLink={event} sprintSlug={sprint.slug} />)
      ) : (
        <div className={styles.noLiveClasses}>
          No upcoming events. Go to the Live Classes page to view recordings of previous events.
        </div>
      )}
    </Card>
  )
}

export default LiveClassesAndEvents
