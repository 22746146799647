import { combineReducers } from '@reduxjs/toolkit'

import resourcesSlice from './features/resources/resourcesSlice'
import sprintReducer from './features/sprint/sprintSlice'
import userReducer from './features/user/userSlice'

const rootReducer = combineReducers({
  sprint: sprintReducer,
  user: userReducer,
  resources: resourcesSlice,
})

export default rootReducer
