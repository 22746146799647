import cn from 'classnames'
import { ReactComponent as ChevronSvg } from 'images/chevron-right.svg'
import React, { FC } from 'react'

import styles from './NextButton.module.scss'

type Props = {
  onClick: () => void
  disabled?: boolean
  className?: string
}

const NextButton: FC<Props> = ({ onClick, disabled, className }) => (
  <div
    className={cn(styles.nextButton, className, {
      [styles.nextButton__disabled]: disabled,
    })}
    onClick={onClick}
  >
    Next <ChevronSvg className={styles.chevron} />
  </div>
)

export default NextButton
