import cn from 'classnames'
import { ReactComponent as ChevronDownSvg } from 'images/chevron-down.svg'
import React, { FC, useEffect, useRef, useState } from 'react'

import styles from './DropdownFilter.module.scss'

type Props = {
  label: string
  items: Array<string>
  selectedItems: Array<string>
  className: string
  onUpdate: (items: Array<string>) => void
}

const DropdownFilter: FC<Props> = ({ label, items, selectedItems, className, onUpdate }) => {
  const [opened, setOpened] = useState(false)
  const dropdownFilterRef = useRef(null)

  const handleClickOutside = (event) => {
    if (dropdownFilterRef.current && !dropdownFilterRef.current.contains(event.target)) {
      setOpened(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mouseup', handleClickOutside)
    return () => {
      document.removeEventListener('mouseup', handleClickOutside)
    }
  })

  const handleSelectAll = (allSelected) => {
    if (allSelected) {
      onUpdate(items)
    } else {
      onUpdate([])
    }
  }

  const handleSelect = (item, selected) => {
    const selectedSet = new Set(selectedItems)
    if (selected) {
      selectedSet.add(item)
    } else {
      selectedSet.delete(item)
    }

    onUpdate(Array.from(selectedSet))
  }

  const handleClear = () => {
    onUpdate([])
  }

  const allSelected = !(items || []).find((item) => !selectedItems.includes(item))

  return (
    <div
      className={cn(styles.dropdownFilter, { [styles.dropdownFilter__opened]: opened }, className)}
      ref={dropdownFilterRef}
    >
      <div className={styles.header} onClick={() => setOpened(!opened)}>
        <div className={styles.label}>
          {label}
          <span className={styles.counter}>{!!selectedItems.length && ` (${selectedItems.length})`}</span>
        </div>
        <ChevronDownSvg
          className={cn(styles.arrow, {
            [styles.arrow__opened]: opened,
          })}
        />
      </div>
      {opened && (
        <div className={styles.listContainer}>
          <div className={styles.list}>
            <label className={cn(styles.listItem, styles.listItem__selectAll)}>
              <input
                type="checkbox"
                name="consentTextMessages"
                className={styles.checkbox}
                checked={allSelected}
                onChange={(event) => handleSelectAll(event.target.checked)}
              />
              Select All
            </label>
            {items.map((item) => (
              <label className={styles.listItem}>
                <input
                  type="checkbox"
                  name="consentTextMessages"
                  className={styles.checkbox}
                  checked={!!selectedItems.find((s) => s === item)}
                  onChange={(event) => handleSelect(item, event.target.checked)}
                />
                {item}
              </label>
            ))}
          </div>
          <div
            className={cn(styles.clear, {
              [styles.clear__active]: selectedItems.length,
            })}
            onClick={handleClear}
          >
            Clear
          </div>
        </div>
      )}
    </div>
  )
}

export default DropdownFilter
