import React, { FC, ReactNode } from 'react'

type Props = {
  link: string
  children: ReactNode
  className?: string
}

// Use rel="noreferrer" to avoid a cross-origin security vulnerability:
// https://web.dev/external-anchors-use-rel-noopener/
const ExternalLink: FC<Props> = ({ link, children, className }) => (
  <a href={link} target="_blank" rel="noreferrer" className={className}>
    {children}
  </a>
)

export default ExternalLink
