import cn from 'classnames'
import { Expandable } from 'components/Expandable'
import { ReactComponent as ChevronDownSvg } from 'images/chevron-down.svg'
import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { Option } from 'typings/option'

import styles from './Dropdown.module.scss'

export type DropdownValue = string | Array<string>

type Props = {
  value?: DropdownValue
  setValue: (DropdownValue) => void
  label: string
  options: Array<Option>
  multiselect?: boolean
  className?: string
}

const Dropdown: FC<Props> = ({ value, setValue, label, options, multiselect, className }) => {
  const [expanded, setExpanded] = useState(false)
  const dropdownFilterRef = useRef(null)

  useEffect(() => {
    document.addEventListener('mouseup', handleClickOutside)
    return () => {
      document.removeEventListener('mouseup', handleClickOutside)
    }
  })

  const handleClickOutside = (event) => {
    if (dropdownFilterRef.current && !dropdownFilterRef.current.contains(event.target)) {
      setExpanded(false)
    }
  }

  const handleClick = () => {
    setExpanded(!expanded)
  }

  const handleSelect = (index: number) => {
    setValue(options[index].value)

    setExpanded(false)
  }

  const handleMultiSelect = (index: number, checked: boolean) => {
    const optionValue = options[index].value

    const valuesSet = new Set(value)
    if (checked) {
      valuesSet.add(optionValue)
    } else {
      valuesSet.delete(optionValue)
    }

    setValue(Array.from(valuesSet))
  }

  const valueTitle = useMemo(() => {
    if (!value || value.length === 0) {
      return '- Select -'
    }

    if (!multiselect) {
      const valueOption = options.find((o) => o.value === value)

      return valueOption.label
    }

    if (value.length > 1) {
      return `${value.length} Items selected`
    }

    const valueOption = options.find((o) => o.value === value[0])

    return valueOption.label
  }, [value, multiselect, options])

  return (
    <div
      className={cn(styles.dropdown, className, {
        [styles.dropdown__expanded]: expanded,
      })}
      ref={dropdownFilterRef}
    >
      <div className={styles.header} onClick={handleClick}>
        <div>
          <div className={styles.label}>{label}</div>
          <div
            className={cn(styles.value, {
              [styles.value__selected]: value && value.length > 0,
            })}
          >
            {valueTitle}
          </div>
        </div>
        <ChevronDownSvg
          className={cn(styles.arrow, {
            [styles.arrow__expanded]: expanded,
          })}
        />
      </div>
      <div className={styles.optionsWrapper}>
        <Expandable expanded={expanded}>
          <div className={styles.options}>
            {options.map((option, index) => (
              <>
                {multiselect && (
                  <label key={index} className={styles.multiselectOption}>
                    <input
                      type="checkbox"
                      className={styles.checkbox}
                      checked={!!(value as Array<string>).find((s) => s === option.value)}
                      onChange={(event) => handleMultiSelect(index, event.target.checked)}
                    />
                    {option.label}
                  </label>
                )}
                {!multiselect && (
                  <div key={index} className={styles.option} onClick={() => handleSelect(index)}>
                    {option.label}
                  </div>
                )}
              </>
            ))}
          </div>
        </Expandable>
      </div>
    </div>
  )
}

export default Dropdown
