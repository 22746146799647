export const NODE_ENV = process.env.NODE_ENV

export const MODULE_PROGRESS = {
  AVAILABLE: 'Available',
  NOT_YET_STARTED: 'Not Yet Started',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
}

export const VIDEO_ACTIONS = {
  ON_COMPLETE: 'onComplete',
  ON_NEXT_SELECTED: 'onNextSelected',
  ON_PAUSE: 'onPause',
}
export const GO_BACK_SECONDS = 1

// TODO: The statuses below are the same as the last three values above... Do I really need MODULE_ITEM_STATUS?
export const MODULE_ITEM_STATUS = {
  NOT_YET_STARTED: 'Not Yet Started',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  UNKNOW: 'Unknow',
}

export const STATUS_INVALID = 'invalid'

export const PLAYER_ID = 'player-module'
export const PLAYER_KEY = 'iGDrEzU0'

export const URL_PLAYER_LIBRARIES = 'https://cdn.jwplayer.com/libraries'
export const URL_PLAYER_LIST = '//cdn.jwplayer.com/v2/media'

export const STATUS_CODE_UNAUTHORIZED = 401
export const STATUS_CODE_FORBIDDEN = 403

export const EVENT_VIDEO_STARTED = 'videoStarted'
export const EVENT_VIDEO_COMPLETED = 'videoCompleted'

export const API_URL = process.env.REACT_APP_API_URL
export const API_URL_2 = process.env.REACT_APP_API_URL_2
export const API_URL_SERVICES = process.env.REACT_APP_API_URL_SERVICES

export const APP_VERSION = process.env.REACT_APP_APP_VERSION

export const COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID
export const COGNITO_URL = process.env.REACT_APP_COGNITO_URL

export const COGNITO_SSO_URL = process.env.REACT_APP_COGNITO_SSO_URL

export const COOKIE_NAMESPACE = process.env.REACT_APP_COOKIE_NAMESPACE || 's4'

export const IS_DEV = NODE_ENV === 'development'

export const MEMBERSHIP_URL = process.env.REACT_APP_MEMBERSHIP_URL || 'https://my.sectionschool.com'
export const MEMBERSHIP_LOGIN_ROUTE = `/login?redirectUrl=${encodeURIComponent(window.location.href)}`
export const MEMBERSHIP_LOGIN_URL = MEMBERSHIP_URL + MEMBERSHIP_LOGIN_ROUTE
export const MEMBERSHIP_RESET_PASSWORD_ROUTE = '/reset-password'
export const MEMBERSHIP_RESET_PASSWORD_URL = MEMBERSHIP_URL + MEMBERSHIP_RESET_PASSWORD_ROUTE

export const TERMS_OF_USE_URL = 'https://www.sectionschool.com/terms'

export const HELP_CENTER_URL = 'https://help.sectionschool.com/support/home'
export const HELP_EMAIL = 'help@sectionschool.com'

export const ECOMMERCE_URL = 'https://www.sectionschool.com'

export const TRACK_JS_APP_NAME = process.env.REACT_APP_TRACK_JS_APP_NAME
export const TRACK_JS_TOKEN = process.env.REACT_APP_TRACK_JS_TOKEN

export const STRATEGY_SPRINT_TYPE_NAME = 'Strategy Sprint'

// Sprint Center 2.0
export const UPLOAD_CHOICES_YES_NO = [
  { val: true, label: 'Yes' },
  { val: false, label: 'No' },
]

export const FADE_SPEED = 500

export const WPCONTENT = /<!-- wp:paragraph -->\n<p>(.*)<\/p>/gm

export const IS_LOCALHOST = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
)
