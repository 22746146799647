import { RouteComponentProps } from '@reach/router'
import { useSprintPageTracking } from 'hooks/usePageTracking'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

import Page from '../../components/Page'
import ButtonExpander from '../liveclass/ButtonExpander'
import Module from './components/Module'
import ModulesHeader from './components/ModulesHeader'
import styles from './Modules.module.scss'

const Modules: FC<RouteComponentProps> = () => {
  const user = useSelector((state: RootState) => state.user.user)
  const sprints = useSelector((state: RootState) => state.sprint.sprints)
  const selectedSprint = useSelector((state: RootState) => state.sprint.selectedSprint)
  const fetching = useSelector((state: RootState) => state.sprint.fetching)

  const sprint = useMemo(() => (selectedSprint >= 0 ? sprints[selectedSprint] : null), [sprints, selectedSprint])

  const modules = useMemo(() => (sprint && sprint.course_modules) || [], [sprint])

  const [expanded, setExpanded] = useState(modules.map((_) => ({ expanded: false })))

  useSprintPageTracking(sprint, 'Modules')

  useEffect(() => {
    if (expanded.length !== modules.length) {
      setExpanded(modules.map((_) => ({ expanded: false })))
    }
  }, [modules, expanded.length])

  const someExpanded = useMemo(() => expanded.some((expandedModule) => expandedModule.expanded), [expanded])

  const onToggle = () => {
    setExpanded((expandedValues) => {
      return expandedValues.map((_) => ({ expanded: !someExpanded }))
    })
  }

  const handleExpand = (index) => {
    setExpanded((e) =>
      e.map((g, i) => {
        if (i === index) {
          return {
            ...g,
            expanded: !g.expanded,
          }
        }
        return g
      }),
    )
  }

  if (!sprint || !sprint.course_modules || fetching || modules.length !== expanded.length) {
    return null
  }

  return (
    <Page className={styles.pageBackground}>
      <div className="row">
        <div className="col-12">
          <ModulesHeader user={user} sprint={sprint} />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-9" />
        <ButtonExpander expanded={someExpanded} onClick={onToggle} />
      </div>

      <div className="mt-4">
        {modules.map((m, i) => (
          <Module
            key={i}
            sprintSlug={sprint.slug}
            module={m}
            expanded={expanded[i].expanded}
            onExpand={() => handleExpand(i)}
          />
        ))}
      </div>
    </Page>
  )
}

export default Modules
