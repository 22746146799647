import { get } from 'lodash'
import { SprintSection } from 'typings/sprintSection'

import { MODULE_ITEM_STATUS, MODULE_PROGRESS, STRATEGY_SPRINT_TYPE_NAME } from './constants'
import { isNowAfter } from './date'

export const findBonusContent = (sprint) =>
  sprint &&
  sprint.course_modules &&
  sprint.course_modules.find((module) => module && module.course_module && module.course_module.bonus_content === 'yes')

export const calculateModuleStatus = (dateStr, percentWatched) => {
  const afterDate = isNowAfter(dateStr)

  if (!afterDate) {
    return MODULE_PROGRESS.AVAILABLE
  }

  switch (true) {
    case percentWatched <= 0:
      return MODULE_ITEM_STATUS.NOT_YET_STARTED
    case percentWatched > 0 && percentWatched < 95:
      return MODULE_ITEM_STATUS.IN_PROGRESS
    case percentWatched >= 95:
      return MODULE_ITEM_STATUS.COMPLETED
    default: // TODO: What should the default case be (if any)?... NOOP for now
  }
}

export const calculateModuleProgress = (duration, secondsLeft) => {
  switch (true) {
    case secondsLeft <= 10:
      return MODULE_ITEM_STATUS.COMPLETED
    case duration - secondsLeft >= 5 && secondsLeft > 10:
      return MODULE_ITEM_STATUS.IN_PROGRESS
    case duration - secondsLeft < 5:
      return MODULE_ITEM_STATUS.NOT_YET_STARTED
    default: // TODO: What should the default case be (if any)?... NOOP for now
  }
}

export const getShowName = (item) => {
  if (!item) {
    return ''
  }

  // Why is this called `tax`?!
  const tax = item.tax
  // Check if the tax object is empty... If it isn't get the show name
  //
  // Note: Why are we using the first show in tax? To remain flexible, we're using back end code
  //       from section4.com where an item may be associated with more than one show. In this
  //       case, we only care about the first show (and the code shouldn't blow up if a show hasn't
  //       been specified, hence the check below)
  return !!Object.entries(tax).length && tax.shows && tax.shows.length > 0 ? tax.shows[0].name : ''
}

export const isSprintStrategy = (sprints, index) =>
  get(sprints, `[${index}].course.sprint_types`, []).some((st) => st.name === STRATEGY_SPRINT_TYPE_NAME)

export const emptySprintSection: SprintSection = {
  course_post_id: undefined,
  course_title: undefined,
  section_name: undefined,
  slack_section_url: undefined,
  slack_support_url: undefined,
  slack_announcement_url: undefined,
  ta_name: '',
  ta_slack_url: undefined,
  live_case_study_zoom_1: undefined,
  live_case_study_recording_zoom_1: undefined,
  live_case_study_recording_zoom_2: undefined,
  live_case_study_recording_zoom_3: undefined,
  live_case_study_recording_zoom_4: undefined,
  thumbnail_medium_large: '',
  thumbnail_large: '',
  thumbnail: '',
}
