import { ReactComponent as DownloadIcon } from 'images/download.svg'
import React from 'react'
import { Sprint } from 'typings/sprint'

import Button from '../../../components/Button'
import styles from './ModulesButtons.module.scss'

type Props = {
  sprint: Sprint
}

const ModulesButtons: React.FC<Props> = ({ sprint }) => {
  const { editable_workbook } = sprint?.document_links ?? {}

  return (
    <div className={styles.moduleButtons}>
      {editable_workbook && (
        <Button
          link={editable_workbook}
          external={true}
          image={<DownloadIcon />}
          label="Project Materials"
          className={styles.button}
          labelClassName={styles.buttonLabel}
        />
      )}
    </div>
  )
}

export default ModulesButtons
