import cn from 'classnames'
import React, { FC } from 'react'

import styles from './Stepper.module.scss'

type Props = {
  steps: number
  current: number
  className?: string
}

const Stepper: FC<Props> = ({ steps, current, className }) => {
  return (
    <div className={cn(styles.stepper, className)}>
      <div className={cn(styles.step, styles.step__pass)} />
      {Array(steps - 1)
        .fill('')
        .map((_, i) => (
          <div key={i} className={cn(styles.step, { [styles.step__pass]: i < current })} />
        ))}
    </div>
  )
}

export default Stepper
