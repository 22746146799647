import cn from 'classnames'
import React, { FC } from 'react'

import styles from './Input.module.scss'

type Props = {
  id: string
  label: string
  value: string
  setValue: (value: string) => void
  className?: string
}

const Input: FC<Props> = ({ id, label, value, setValue, className }) => (
  <div className={cn(styles.input, className)}>
    <label htmlFor={id} className={styles.label}>
      {label}
    </label>
    <input
      id={id}
      className={styles.inputControl}
      placeholder="Type Here"
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  </div>
)

export default Input
