const SHORT_DESCRIPTION_LENGTH = 140

export const everythingBefore = (char: string, s: string) => s.substring(0, s.indexOf(char))

export const getShortText = (str: string) => {
  if (str.length <= SHORT_DESCRIPTION_LENGTH) {
    return str
  }
  return str.substring(0, SHORT_DESCRIPTION_LENGTH) + '...'
}
