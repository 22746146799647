import cn from 'classnames'
import Loader from 'components/Loader'
import React, { FC, useEffect } from 'react'
import { Sprint } from 'typings/sprint'
import { offersPeerFeedback } from 'utils/isFilteredSprint'

import commonStyles from './Common.module.scss'
import styles from './StepBegin.module.scss'

type Props = {
  sprint: Sprint
  setValid: (valid: boolean) => void
  handleBegin: () => void
}

const StepBegin: FC<Props> = ({ sprint, setValid, handleBegin }) => {
  useEffect(() => {
    setValid(true)
  })

  if (!sprint?.course) {
    return <Loader className={styles.loaderPosition} />
  }

  const {
    course: { short_name: name },
    slug,
  } = sprint

  return (
    <div className={cn(commonStyles.step, commonStyles.stepBegin)}>
      <div className={commonStyles.subTitle}>Upload Your Project</div>
      <div className={commonStyles.name}>{name}</div>
      <div className={commonStyles.description}>
        {offersPeerFeedback(slug)
          ? "Submit your project via this form, and let us know if you'd like feedback on your work! At Section, we operate on a give and get feedback model. This means if you'd like feedback on your project, we ask that you give feedback on your classmates' projects."
          : 'Congratulations on completing your project. Complete this form to submit your project.'}
      </div>
      <div className={commonStyles.buttons}>
        <div className={commonStyles.button} onClick={handleBegin}>
          Begin
        </div>
      </div>
    </div>
  )
}

export default StepBegin
