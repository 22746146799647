import cn from 'classnames'
import Card from 'components/Card'
import { Expandable } from 'components/Expandable'
import { newYorkDate } from 'modules/date'
import { getNow } from 'modules/date'
import React, { useState } from 'react'
import { ZoomLink } from 'typings/zoomLink'

import Col from '../../components/Col'
import Row from '../../components/Row'
import EventContent from './EventContent'
import EventItem from './EventItem'
import EventRecording from './EventRecording'
import styles from './LiveClass.module.scss'
import { getLinks, zoomLinkMeetingUrl, zoomLinkRecording } from './utils'
import ZoomLinkButton from './ZoomLink'

const LiveClass = ({
  zoomLink,
  onExpandChange,
  expanded = false,
}: {
  zoomLink?: ZoomLink
  expanded?: boolean
  onExpandChange?: () => void
}) => {
  const slides = getLinks(zoomLink.discussion_slides_html)
  const [hover, setHover] = useState(false)

  const concluded = getNow().isAfter(
    newYorkDate(`${zoomLink.zoom_link_date} ${zoomLink.zoom_link_time}`).add(3, 'hour'),
  )

  return (
    <>
      <Row className={styles.rowMargin}>
        <Card
          className={cn('col-12', styles.roundedRect, {
            [styles.roundedRectShadow]: hover,
          })}
        >
          <EventItem link={zoomLink} onExpand={onExpandChange} expanded={expanded} hover={setHover} />
          <Expandable expanded={expanded}>
            <div className={styles.eventContentSeparator} />
            <Row>
              <Col xs={12} md={6}>
                {concluded ? (
                  <div className={styles.eventConcluded}>The event has concluded.</div>
                ) : (
                  <ZoomLinkButton url={zoomLinkMeetingUrl(zoomLink)} />
                )}
                {zoomLink.description_html && (
                  <div
                    className={styles.eventContentDescription}
                    dangerouslySetInnerHTML={{
                      __html: zoomLink.description_html,
                    }}
                  />
                )}
                {slides && slides.length > 0 && <EventContent title="Live Class Slides" links={slides} />}
              </Col>
              <Col xs={12} md={6}>
                <EventRecording title="Live Class Recording" recording={zoomLinkRecording(zoomLink)} />
              </Col>
            </Row>
          </Expandable>
        </Card>
      </Row>
      <div style={{ marginBottom: '1rem' }} />
    </>
  )
}

export default LiveClass
