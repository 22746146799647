import React from 'react'

import ExternalLink from '../../components/ExternalLink'
import styles from './ZoomLink.module.scss'

const ZoomLink = ({ url, ...rest }: { url: string } & React.HTMLProps<HTMLDivElement>) => (
  <ExternalLink link={url}>
    <div className={styles.buttonContainer} {...rest}>
      <div>
        <img height="36px" width="36px" src="/images/2.0/camera.svg" alt="camera" />
      </div>
      <div className={styles.buttonText}>Event Link</div>
    </div>
  </ExternalLink>
)

export default ZoomLink
