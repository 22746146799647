import cn from 'classnames'
import React from 'react'

import styles from '../common.module.scss'

const Card = (props: React.HTMLProps<HTMLDivElement>) => (
  <div {...props} className={cn(styles.roundedRect, props.className)} />
)

export default Card
