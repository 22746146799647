import cn from 'classnames'
import { ReactComponent as ChevronSvg } from 'images/chevron-right.svg'
import React, { FC } from 'react'

import styles from './BackButton.module.scss'

type Props = {
  onClick: () => void
  className?: string
}

const BackButton: FC<Props> = ({ onClick, className }) => (
  <div className={cn(styles.backButton, className)} onClick={onClick}>
    <ChevronSvg className={styles.chevronLeft} /> Back
  </div>
)

export default BackButton
