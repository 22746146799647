import Card from 'components/Card'
import React, { FC } from 'react'
import { Sprint } from 'typings/sprint'
import { User } from 'typings/user'

import ModulesButtons from './ModulesButtons'
import styles from './ModulesHeader.module.scss'

type Props = {
  user: User
  sprint: Sprint
}

const ModulesHeader: FC<Props> = ({ user, sprint }) => {
  const course = sprint?.course
  return (
    <Card>
      <div className={styles.moduleTitle}>Modules</div>
      <div className={styles.sprintTitle}>{course?.short_name ?? ''}</div>
      <ModulesButtons sprint={sprint} />
    </Card>
  )
}

export default ModulesHeader
