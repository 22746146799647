import { NavConfig } from 'typings/navConfig'

export const SPRINT_CENTER_2_ROOT = '/'

export const navItems: Array<NavConfig> = [
  {
    imgSrc: '/images/nav/home.svg',
    imgSrcActive: '/images/nav/home-active.svg',
    label: 'Home',
    path: '',
  },
  {
    imgSrc: '/images/nav/modules.svg',
    imgSrcActive: '/images/nav/modules-active.svg',
    label: 'Modules',
    path: 'modules',
  },
  {
    imgSrc: '/images/nav/documents.svg',
    imgSrcActive: '/images/nav/documents-active.svg',
    label: 'Documents',
    path: 'documents',
  },
  {
    imgSrc: '/images/nav/live-classes.svg',
    imgSrcActive: '/images/nav/live-classes-active.svg',
    label: 'Live Classes',
    path: 'live-sessions',
  },
]
