import cn from 'classnames'
import React, { FC } from 'react'

import ExternalLink from '../../../components/ExternalLink'
import styles from './SlackChannel.module.scss'

type Props = {
  title: string
  description: string
  url: string
}

const SlackChannel: FC<Props> = ({ title, description, url }) => (
  <ExternalLink link={url} className={styles.slackChannel}>
    <div className={cn(styles.title, { [styles.title__unassigned]: !url })}>{title ? `#${title}` : 'Not assigned'}</div>
    <div className={styles.description}>{description}</div>
  </ExternalLink>
)

export default SlackChannel
