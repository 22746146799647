import cn from 'classnames'
import Card from 'components/Card'
import { isEqual } from 'lodash'
import { FC, useState } from 'react'
import { ZoomLink } from 'typings/zoomLink'

import Row from '../../components/Row'
import Event from './Event'
import styles from './TwoTimeEvent.module.scss'

type Props = {
  links: ZoomLink[]
  selected?: ZoomLink
}

const TwoTimeEvent: FC<Props> = ({ links, selected }) => {
  const [hover, setHover] = useState(false)
  return (
    <Row className={styles.rowMargin}>
      <Card className={cn(styles.roundedRect, hover && styles.roundedRectShadow, 'col-12')}>
        {links.map((zl, i) => (
          <Event key={i} link={zl} separator={i < links.length - 1} hover={setHover} selected={isEqual(zl, selected)} />
        ))}
      </Card>
    </Row>
  )
}

export default TwoTimeEvent
