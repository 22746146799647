import moment from 'moment'

import { MODULE_ITEM_STATUS } from './constants'

export const calculateStatus = (availableDatetimeUTC, percentWatched): [boolean, string] => {
  const currentDateTime = moment.utc()

  const availableDatetime = moment.unix(availableDatetimeUTC).utc()

  const notAvailable = currentDateTime.isBefore(availableDatetime, 'second')

  if (notAvailable) {
    const currentTimezone = moment.tz.guess()
    return [false, availableDatetime.tz(currentTimezone).format('[Available On] MMMM DD [at] hh:mma')]
  }

  switch (true) {
    case percentWatched <= 0:
      return [true, MODULE_ITEM_STATUS.NOT_YET_STARTED]
    case percentWatched > 0 && percentWatched < 95:
      return [true, MODULE_ITEM_STATUS.IN_PROGRESS]
    case percentWatched >= 95:
      return [true, MODULE_ITEM_STATUS.COMPLETED]
    default:
      return [false, MODULE_ITEM_STATUS.UNKNOW]
  }
}

export const isExternal = (url: string) => {
  try {
    return new URL(url).hostname !== window.location.hostname
  } catch (err) {
    return false
  }
}
