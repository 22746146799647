import commonStyles from 'common.module.scss'
import { motion } from 'framer-motion'
import React, { FC } from 'react'
import { DeviceConfig } from 'typings/deviceConfig'
import { Sprint } from 'typings/sprint'

import { CLOSED, device, navVariants, OPEN, separatorVariants } from './Animations'
import Hamburger from './Hamburger'
import Info from './Info'
import MenuItem from './MenuItem'
import styles from './Nav.module.scss'
import { navItems } from './navItems'

type Props = {
  config: DeviceConfig
  sprint: Sprint
  open: boolean
}

const Nav: FC<Props> = ({ config = device.PHONE, sprint, open }) => (
  <motion.nav
    className={`${styles.left}`}
    initial={open ? OPEN : CLOSED}
    animate={open ? OPEN : CLOSED}
    variants={navVariants(config)}
  >
    <Hamburger config={config} />
    {navItems.map((item) => (
      <MenuItem
        key={item.label}
        config={config}
        src={item.imgSrc}
        srcActive={item.imgSrcActive}
        label={item.label}
        sprintSlug={sprint?.slug}
        path={item.path}
      />
    ))}
    <motion.hr className={commonStyles.separator} variants={separatorVariants(config)} />
    {!sprint?.endedMoreThanTwoWeeksAgo && (
      <>
        <MenuItem
          config={config}
          src="/images/nav/channels.svg"
          srcActive="/images/nav/channels-active.svg"
          label="Channels"
          sprintSlug={sprint?.slug}
          externalUrl={sprint?.sprint_section?.slack_section_url}
        />
        <Info sprint={sprint} />
      </>
    )}
  </motion.nav>
)

export default Nav
