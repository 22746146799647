import TagManager from 'react-gtm-module'

export const hasCourseModules = (sprint) => !!sprint?.course_modules?.length

export const findVideo = (module, slug = null) => {
  if (module?.course_module?.items?.length > 0) {
    if (slug) {
      return module.course_module.items.find((item) => slug === item.slug)
    }

    return module.course_module.items[0]
  }

  return null
}

export const handleError = (e) => console.error(`Player error: ${e}`)

export const sendVideoEvent = (video, action) => {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
    events: {
      event: 'video_view',
      video,
      action,
    },
  }

  TagManager.initialize(tagManagerArgs)
}
