import cn from 'classnames'
import React from 'react'

import { ReactComponent as Chev } from '../../images/chevron.svg'
import styles from './Chevron.module.scss'

type Props = {
  expanded?: boolean
  hovered?: boolean
  size?: number
  wrapperClassName?: string
  className?: string
}

const Chevron: React.FC<Props> = ({ expanded, hovered, wrapperClassName, className, size = 32 }) => (
  <div
    className={cn(styles.chevron, { [styles.chevronExpanded]: expanded }, wrapperClassName)}
    style={{ height: size, width: size }}
  >
    <Chev className={cn(className, { [styles.hovered]: hovered })} />
  </div>
)

export default Chevron
