import { navigate, RouteComponentProps } from '@reach/router'
import { fetchSprintById, selectSprintBySlug } from 'features/sprint/sprintSlice'
import { useAppData } from 'hooks/useAppData'
import { DESKTOP_WIDTH, TABLET_WIDTH, useWindowSize } from 'hooks/useWindowSize'
import React, { createContext, FC, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { NavContextType } from 'typings/navContextType'

import { device } from './Animations'
import Content from './Content'
import Nav from './Nav'
import styles from './Sprint2.module.scss'

export const NavContext = createContext<NavContextType>(undefined)

type Props = RouteComponentProps & {
  sprintSlug?: string
}

const Sprint2: FC<Props> = ({ sprintSlug, ...rest }) => {
  const { fetching, sprint, sprints } = useAppData()
  const { width } = useWindowSize()
  const dispatch = useDispatch()

  const [navOpen, setNavOpen] = useState(false)

  useEffect(() => {
    if (!sprints.length || !sprintSlug) {
      return
    }

    const index = sprints.findIndex((s) => s.slug === sprintSlug)
    if (index >= 0) {
      if (sprint.slug !== sprintSlug) {
        dispatch(selectSprintBySlug(sprintSlug))
      } else if (!sprint.course_modules && !fetching) {
        dispatch(fetchSprintById(sprint.post_id))
      }
    } else {
      navigate('/dashboard')
    }
  }, [sprints, sprintSlug, sprint, dispatch, fetching])

  const toggleNav = () => {
    setNavOpen(!navOpen)
  }
  const hideNav = () => {
    setNavOpen(false)
  }

  const navConfig = useMemo(() => {
    if (width >= DESKTOP_WIDTH) {
      return device.DESKTOP
    }

    if (width >= TABLET_WIDTH) {
      return device.TABLET
    }

    return device.PHONE
  }, [width])

  const contentConfig = useMemo(() => {
    if (width >= DESKTOP_WIDTH) {
      return device.DESKTOP
    }

    if (width >= TABLET_WIDTH) {
      return device.TABLET
    }

    return device.PHONE
  }, [width])

  if (width === 0) {
    return null
  }

  return (
    <NavContext.Provider value={{ navOpen, toggleNav, hideNav }}>
      <div className={styles.page}>
        {navConfig && <Nav config={navConfig} sprint={sprint} open={navOpen} />}
        <Content config={contentConfig} open={navOpen} toggle={toggleNav} {...rest} />
      </div>
    </NavContext.Provider>
  )
}

export default Sprint2
