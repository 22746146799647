import React, { FC } from 'react'

import styles from './MenuItem.module.scss'

type Props = {
  src: string
  srcActive: string
  label: string
  itemPath: string
  active: boolean
  clickHandler: (path: string) => void
}

const MenuIcon: FC<Props> = ({ src, srcActive, label, clickHandler, itemPath, active }) => (
  <div className={styles.icon}>
    <img
      src={active ? srcActive : src}
      alt={label}
      onClick={() => {
        clickHandler(itemPath)
      }}
    />
  </div>
)

export default MenuIcon
