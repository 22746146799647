import { ExpandableTrigger } from 'components/ExpandableTrigger'
import moment from 'moment'
import React, { useMemo } from 'react'
import { CourseModule } from 'typings/courseModule'

import Col from '../../../components/Col'
import Row from '../../../components/Row'
import { totalSeconds } from '../../../modules/functional'
import Chevron from '../../liveclass/Chevron'
import styles from './ModuleHeader.module.scss'
import ModuleStatus from './ModuleStatus'

type Props = {
  sprintSlug: string
  module: CourseModule
  expanded: boolean
  onExpand?: () => void
  onHoverChange?: (hover: boolean) => void
}

const ModuleHeader: React.FC<Props> = ({ module, expanded, onExpand, onHoverChange }) => {
  const availableDatetime = moment.unix(module.course_module.available_datetime_UTC).utc()

  const videoLength = useMemo(() => {
    const moduleItems = module.course_module.items

    return Math.ceil(totalSeconds(moduleItems) / 60.0)
  }, [module])

  const hasVideos = module.course_module.items?.length > 0

  return (
    <ExpandableTrigger className={styles.moduleHeader} onExpandChanged={onExpand}>
      <div
        onMouseEnter={() => onHoverChange && onHoverChange(true)}
        onMouseLeave={() => onHoverChange && onHoverChange(false)}
        className={styles.hoverArea}
      >
        <div className={styles.date}>
          <div className={styles.day}>{availableDatetime.format('DD')}</div>
          <div className={styles.month}>{availableDatetime.format('MMM')}</div>
        </div>

        <div className={styles.verticalLine} />

        <Row style={{ flexGrow: 1 }}>
          <Col xs={12} md={8} className={styles.titleWrapper}>
            <div className={styles.title}>{module.post_title}</div>
            {hasVideos ? (
              <div className={styles.length}>
                <span className={styles.lengthTitle}>Length:</span> {videoLength} minutes
              </div>
            ) : (
              <span className={styles.noVideo} />
            )}
          </Col>
          <Col xs={12} md={4} className={styles.statusWrapper}>
            {hasVideos && <ModuleStatus module={module} />}
          </Col>
        </Row>
        {onExpand && (
          <Chevron expanded={expanded} className={styles.chevron} wrapperClassName={styles.chevronWrapper} size={34} />
        )}
      </div>
    </ExpandableTrigger>
  )
}

export default ModuleHeader
