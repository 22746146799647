import { ReactComponent as UpLargeSvg } from 'images/large/up.svg'
import { ReactComponent as UpCompleteLargeSvg } from 'images/large/up-complete.svg'
import { ReactComponent as UpPrimarySvg } from 'images/up-primary.svg'
import { ReactComponent as UpPrimaryDisabledSvg } from 'images/up-primary-disabled.svg'
import { isEmpty } from 'lodash'
import { formatDateSubmitted, formatDueDate, formatEarliestSubmissionDate, getNow } from 'modules/date'
import { buildUploadYourProjectLink, getTypeformProjectSubmitLink, isTypeformProjectSubmit } from 'modules/routing'
import moment from 'moment'
import DocumentCenterItem from 'pages/documents/DocumentCenterItem'
import React, { FC, useMemo } from 'react'
import { Sprint } from 'typings/sprint'
import { offersPeerFeedback } from 'utils/isFilteredSprint'

interface Props {
  sprint: Sprint
}

const SubmitProject: FC<Props> = ({ sprint }) => {
  const projectSubmitted = useMemo(() => !isEmpty(sprint?.final_project_v2), [sprint])

  const dueDatetime = useMemo(() => {
    const { project_due_date } = sprint?.final_project_per_course_info ?? {}

    return moment(`${project_due_date} 23:59`)
  }, [sprint])

  const dueDatetimeStr = useMemo(() => formatDueDate(dueDatetime), [dueDatetime])

  const dateSubmittedStr = useMemo(() => {
    const { date_submitted } = sprint?.final_project_v2 ?? {}

    if (!date_submitted) {
      return ''
    }

    return formatDateSubmitted(date_submitted)
  }, [sprint])

  const title = useMemo(() => {
    if (projectSubmitted) {
      return 'Project Submitted'
    }

    return 'Submit Your Project'
  }, [projectSubmitted])

  const projectUploadEnabledDate = useMemo(() => {
    const { project_upload_enabled_date } = sprint?.final_project_per_course_info ?? {}

    return moment(`${project_upload_enabled_date} 00:00`)
  }, [sprint?.final_project_per_course_info])

  const projectUploadEnabledDateStr = useMemo(
    () => formatEarliestSubmissionDate(projectUploadEnabledDate),
    [projectUploadEnabledDate],
  )

  const content = useMemo(() => {
    if (projectSubmitted) {
      return 'Congratulations on submitting your project! Resubmit here if any changes need to be made.'
    }

    return `The project is due ${dueDatetimeStr} US Eastern Time. You can submit as early as ${projectUploadEnabledDateStr}. ${
      offersPeerFeedback(sprint.slug)
        ? 'You’ll be able to select confidentiality options and opt into giving and receiving peer feedback when you submit.'
        : 'Your project is confidential and will not be shared with your peers. You will have the option to allow Section to share your project with future sprinters as an example.'
    }`
  }, [projectSubmitted, projectUploadEnabledDateStr, dueDatetimeStr, sprint])

  const buttonText = useMemo(() => {
    if (projectSubmitted) {
      return 'Resubmit Your Project'
    }

    return 'Submit Your Project'
  }, [projectSubmitted])

  const disabled = useMemo(() => {
    return getNow().isBefore(projectUploadEnabledDate)
  }, [projectUploadEnabledDate])

  let link = buildUploadYourProjectLink(sprint.slug)
  let linkExternal

  if (isTypeformProjectSubmit(sprint.slug)) {
    link = getTypeformProjectSubmitLink(sprint.slug)
    linkExternal = true
  }

  return (
    <DocumentCenterItem
      title={title}
      subtitle={projectSubmitted && dateSubmittedStr}
      link={link}
      content={content}
      icon={projectSubmitted ? <UpCompleteLargeSvg /> : <UpLargeSvg />}
      buttonText={buttonText}
      buttonIcon={disabled ? <UpPrimaryDisabledSvg /> : <UpPrimarySvg />}
      primary
      linkExternal={linkExternal}
      disabled={disabled}
    />
  )
}

export default SubmitProject
