import { PLAYER_ID } from 'modules/constants'

const getPlayer = () => {
  const player = window['jwplayer']
  if (player) {
    return player(PLAYER_ID)
  }
}
export const readyPlayerOne = (timestamp) => {
  if (!timestamp) {
    return
  }

  const player = getPlayer()
  if (!player) {
    return
  }

  let timestampSeconds = parseInt(timestamp) || 0

  // The position reset is made here in order to preserve the current position in the server and to reset previously viewed videos at more than 90%
  const total = playerGetCurrentVideoDuration()
  const percentage = (timestamp / total) * 100 || 0
  if (percentage > 90) {
    timestampSeconds = 0
  }

  player.seek(timestampSeconds)
  player.pause()
}

export const playerSeek = (timestampSeconds) => {
  const player = getPlayer()
  if (!player) {
    return
  }

  player.seek(timestampSeconds)
}

export const playerGetState = () => {
  const player = getPlayer()
  if (!player) {
    return
  }

  if (player.getState) {
    return player.getState()
  }
}

export const playerGetCurrentVideoPosition = () => {
  const player = getPlayer()
  if (!player) {
    return
  }

  if (player.getPosition) {
    return player.getPosition()
  }
}

export const playerGetCurrentVideoDuration = () => {
  const player = getPlayer()
  if (!player) {
    return
  }

  if (player.getDuration) {
    return player.getDuration()
  }

  return 0
}
