import { navigate } from '@reach/router'
import classNames from 'classnames'
import { newLogout } from 'features/user/userSlice'
import React, { FC, MutableRefObject, useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { useAppData } from '../hooks/useAppData'
import { ECOMMERCE_URL, MEMBERSHIP_URL } from '../modules/constants'
import styles from './UserMenu.module.scss'

type Props = {
  className?: string
  menuControlRef: MutableRefObject<HTMLDivElement>
  showNav: boolean
  toggle: () => void
}

const UserMenu: FC<Props> = ({ className, menuControlRef, showNav, toggle }) => {
  const userMenuRef = useRef<HTMLDivElement>()
  const dispatch = useDispatch()
  const {
    user: { user_membership: userMembership },
  } = useAppData()

  const handleClick = useCallback(
    (e) => {
      // Execute the required code only for the correct device
      if (menuControlRef.current.contains(e.target) || userMenuRef.current.contains(e.target)) return
      toggle()
    },
    [menuControlRef, userMenuRef, toggle],
  )

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    // Return the function that's called when the menu is unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [handleClick])

  const redirectToEcommerce = userMembership.membershipStatus !== 'active'

  return (
    <div ref={userMenuRef} className={classNames(styles.menu, { [styles.noNav]: !showNav }, className)}>
      <div onClick={() => navigate('/dashboard')}>My Sprints</div>
      <div
        onClick={() => {
          window.location.href = redirectToEcommerce ? ECOMMERCE_URL : MEMBERSHIP_URL
        }}
      >
        Member Center
      </div>
      <div onClick={() => dispatch(newLogout())}>Log Out</div>
    </div>
  )
}

export default UserMenu
