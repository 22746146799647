import cn from 'classnames'
import React, { CSSProperties, forwardRef, ReactNode } from 'react'
type Props = {
  sm?: number
  md?: number
  lg?: number
  xl?: number
  xs?: number
  offsetSm?: number
  offsetMd?: number
  offsetLg?: number
  offsetXl?: number
  offset?: number
  className?: string
  children?: ReactNode
  style?: CSSProperties
  onClick?: () => void
}
type Ref = HTMLDivElement

const Col = forwardRef<Ref, Props>(
  (
    { sm, md, lg, xl, xs, offsetSm, offsetMd, offsetLg, offsetXl, offset, className, children, style, onClick },
    ref,
  ) => (
    <div
      ref={ref}
      style={style}
      onClick={onClick}
      className={cn(
        sm != null && `col-sm-${sm}`,
        md != null && `col-md-${md}`,
        lg != null && `col-lg-${lg}`,
        xl != null && `col-xl-${xl}`,
        xs != null && `col-${xs}`,
        offsetSm != null && `offset-sm-${offsetSm}`,
        offsetMd != null && `offset-md-${offsetMd}`,
        offsetLg != null && `offset-lg-${offsetLg}`,
        offsetXl != null && `offset-xl-${offsetXl}`,
        offset != null && `offset-${offset}`,
        className,
      )}
    >
      {children}
    </div>
  ),
)

export default Col
