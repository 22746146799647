import { MODULE_ITEM_STATUS } from 'modules/constants'
import { totalSeconds, watchedSeconds } from 'modules/functional'
import { calculateStatus } from 'modules/utils'
import React, { FC, useMemo } from 'react'
import { CourseModule } from 'typings/courseModule'

import styles from './ModuleStatus.module.scss'

type Props = {
  module: CourseModule
}

const ModuleStatus: FC<Props> = ({ module }) => {
  const { percentWatched, totalModuleSeconds } = useMemo(() => {
    const moduleItems = module.course_module.items

    const totalModuleSeconds = totalSeconds(moduleItems)
    const watchedModuleSeconds = watchedSeconds(moduleItems)

    const percentWatched = (watchedModuleSeconds / totalModuleSeconds) * 100

    return { percentWatched, totalModuleSeconds }
  }, [module])

  const [, statusStr] = useMemo(() => {
    return calculateStatus(module.course_module.available_datetime_UTC, percentWatched)
  }, [module, percentWatched])

  const color = useMemo(() => {
    if (statusStr === MODULE_ITEM_STATUS.NOT_YET_STARTED) {
      return '#4E4D4D'
    }

    if (statusStr === MODULE_ITEM_STATUS.IN_PROGRESS) {
      return '#F2B443'
    }

    return '#4FAFB8'
  }, [statusStr])

  const totalModuleMinutes = Math.ceil(totalModuleSeconds / 60)
  return (
    <div className={styles.videoStatus}>
      <div className={styles.textContainer}>
        <div className={`${styles.length} mt-0 mt-lg-3 d-flex align-items-center`}>Module Length:</div>
        <div className={`${styles.duration} mt-0 mt-lg-3 ml-3 ml-lg-0`}>{totalModuleMinutes} Minutes</div>
      </div>
      <div className={styles.statusBar}>
        <div className={styles.progressBar} style={{ width: `${percentWatched}%`, backgroundColor: `${color}` }} />
      </div>
    </div>
  )
}

export default ModuleStatus
