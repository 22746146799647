import cn from 'classnames'
import BackButton from 'pages/upload/components/BackButton'
import NextButton from 'pages/upload/components/NextButton'
import Stepper from 'pages/upload/components/Stepper'
import React, { FC } from 'react'

import styles from './Navigation.module.scss'

type Props = {
  step: number
  onBack: () => void
  onNext?: () => void
  nextDisabled?: boolean
  className?: string
  numberOfSteps: number
}

const Navigation: FC<Props> = ({ step, onBack, onNext, nextDisabled, className, numberOfSteps }) => (
  <div className={cn(styles.navigation, className)}>
    <BackButton className={styles.button} onClick={onBack} />
    <Stepper steps={numberOfSteps} current={step - 1} className={styles.stepper} />
    {onNext && <NextButton className={cn(styles.button, styles.nextButton)} onClick={onNext} disabled={nextDisabled} />}
    {!onNext && <div className={styles.buttonPlaceholder} />}
  </div>
)

export default Navigation
