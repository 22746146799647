import { ExpandableTrigger } from 'components/ExpandableTrigger'
import { ReactComponent as Calendar } from 'images/calendar.svg'
import { getNow, newYorkDate } from 'modules/date'
import moment from 'moment'
import React, { FC, useEffect, useRef } from 'react'
import { ZoomLink } from 'typings/zoomLink'

import Col from '../../components/Col'
import Row from '../../components/Row'
import Chevron from './Chevron'
import styles from './EventItem.module.scss'

const dateFormat = 'dddd M/D'

type Props = {
  link: ZoomLink
  onExpand?: () => void
  expanded?: boolean
  selected?: boolean
  hover?: (h: boolean) => void
}

const EventItem: FC<Props> = ({ link, onExpand, expanded, hover, selected }) => {
  let eventStatus = 'UPCOMING EVENT'
  let ended = false
  const now = getNow()

  const mdate = newYorkDate(`${link.zoom_link_date} ${link.zoom_link_time}`)
  const endDate = mdate.clone().add(1, 'hour')

  if (now.isSameOrAfter(endDate)) {
    ended = true
    eventStatus = 'EVENT CONCLUDED'
  } else if (now.isSameOrAfter(mdate)) {
    eventStatus = 'JOIN US'
  }

  const hovered = useRef(false)
  const containerRef = useRef<HTMLDivElement>()

  useEffect(() => {
    if (selected) {
      containerRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [selected])

  const onHoverChange = (h: boolean) => {
    hovered.current = h
    hover && hover(h)
  }

  return (
    <ExpandableTrigger ref={containerRef} onExpandChanged={onExpand} className={styles.cursorPointer}>
      <div
        className={styles.eventContainer}
        onMouseEnter={() => onHoverChange(true)}
        onMouseLeave={() => onHoverChange(false)}
      >
        <div className={styles.calendarContainer}>
          <div className={styles.calendarIconContainer}>
            <div>
              <Calendar height="100%" width="100%" />
            </div>
            <div className={styles.calendarNumberContainer}>
              <div className={styles.calendarNumber}>{mdate.format('D')}</div>
            </div>
          </div>
          <div className={styles.calendarMonthContainer}>
            <div className={styles.calendarMonth}>{mdate.format('MMM')}</div>
          </div>
          <div className={styles.verticalSeparator} />
        </div>
        <Row className={styles.flexGrow}>
          <Col xs={12} md={8}>
            <div className={styles.option}>{link.label}</div>
            <div className={styles.optionDate}>{`${mdate.format(dateFormat)} - ${moment(
              link.zoom_link_time,
              'HH:mm',
            ).format('h:mmA')} US EASTERN TIME`}</div>
          </Col>
          <Col xs={12} md={4} className={ended ? styles.eventConcluded : styles.eventUpcoming}>
            {eventStatus}
          </Col>
        </Row>
        <Chevron expanded={expanded} hovered={hovered.current} />
      </div>
    </ExpandableTrigger>
  )
}

export default EventItem
