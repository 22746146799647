import classNames from 'classnames'
import Navigation from 'pages/upload/components/Navigation'
import { ProjectSubmissionForm } from 'pages/upload/components/projectSubmissionForm'
import Step6Upload from 'pages/upload/components/Step6Upload'
import Step6URL from 'pages/upload/components/Step6URL'
import React, { FC } from 'react'

import commonStyles from './Common.module.scss'
import styles from './Step6.module.scss'

type Props = {
  step: number
  data: ProjectSubmissionForm
  valid: boolean
  handleSubmit: () => void
  updateHandler: (data: ProjectSubmissionForm) => void
  setValid: (valid: boolean) => void
  onPrevious: () => void
  numberOfSteps: number
}

const Step6: FC<Props> = ({ data, updateHandler, valid, setValid, handleSubmit, step, onPrevious, numberOfSteps }) => (
  <div className={commonStyles.step}>
    {data.uploadFile ? (
      <Step6Upload updateHandler={updateHandler} setValid={setValid} />
    ) : (
      <Step6URL data={data} updateHandler={updateHandler} setValid={setValid} />
    )}
    <div className={styles.buttons}>
      <div
        className={classNames(commonStyles.button, {
          [commonStyles.button__disabled]: !valid,
        })}
        onClick={handleSubmit}
      >
        Submit
      </div>
    </div>

    <Navigation numberOfSteps={numberOfSteps} step={step} onBack={onPrevious} />
  </div>
)

export default Step6
