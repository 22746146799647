import { RouteComponentProps, useLocation, WindowLocation } from '@reach/router'
import Card from 'components/Card'
import Page from 'components/Page'
import Row from 'components/Row'
import { useSprintPageTracking } from 'hooks/usePageTracking'
import { isEqual } from 'lodash'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { Sprint } from 'typings/sprint'
import { isSectionLiveCaseDiscussion, LiveCaseDiscussionWithSectionUrls, ZoomLink } from 'typings/zoomLink'
import { isMicrosoftSprint } from 'utils/isFilteredSprint'

import LiveClass from './LiveClass'
import styles from './LiveClasses.module.scss'
import Section from './Section'
import TwoTimeEvent from './TwoTimeEvent'

const LiveClasses: FC<RouteComponentProps> = () => {
  const sprints = useSelector((state: RootState) => state.sprint.sprints)
  const selectedSprint = useSelector((state: RootState) => state.sprint.selectedSprint)

  const location = useLocation() as WindowLocation<{ selectedZoomLink: ZoomLink }>
  const { selectedZoomLink } = location?.state ?? {}

  const fetching = useSelector((state: RootState) => state.sprint.fetching)

  const [exEvents, setExEvents] = useState([])

  const sprint: Sprint = useMemo(() => sprints && sprints[selectedSprint], [sprints, selectedSprint])

  const isMicrosoft = isMicrosoftSprint(sprint?.slug)

  const zoomLinks: ZoomLink[] = useMemo(() => sprint?.zoom_links ?? [], [sprint])

  const kickoffs = useMemo(() => zoomLinks.filter((z) => z.zoom_link_type === 'Live Kickoff Session'), [zoomLinks])

  // Some live case discussions are section-specific, so the meeting and recording
  // video URLs are in the sprint.sprint_section object instead of sprint.zoom_links.
  const sectionDiscussions: LiveCaseDiscussionWithSectionUrls[] = useMemo(() => {
    const discussions = zoomLinks.filter(isSectionLiveCaseDiscussion)

    return discussions.map((discussion, i) => {
      return {
        ...discussion,
        sectionMeetingUrl: sprint?.sprint_section.live_case_study_zoom_1,
        sectionRecordingUrl: sprint?.sprint_section[`live_case_study_recording_zoom_${i + 1}`],
      }
    })
  }, [sprint?.sprint_section, zoomLinks])

  const networking = useMemo(() => zoomLinks.filter((z) => z.zoom_link_type === 'Networking Events'), [zoomLinks])

  const workshop = useMemo(() => zoomLinks.filter((z) => z.zoom_link_type === 'Live Project Workshops'), [zoomLinks])

  useSprintPageTracking(sprint, 'Live Events')

  useEffect(() => {
    const lc = zoomLinks.filter((z) => z.zoom_link_type === 'Live Classes')
    setExEvents(
      lc.map((e) => ({
        ...e,
        expanded: selectedZoomLink && isEqual(e, selectedZoomLink),
      })),
    )
  }, [selectedZoomLink, zoomLinks])

  const someExpanded = useMemo(() => exEvents.some((e) => e.expanded), [exEvents])

  const onExpansionChange = (index: number) => {
    setExEvents((exEvents) =>
      exEvents.map((e, i) => {
        if (index === i) {
          return {
            ...e,
            expanded: !e.expanded,
          }
        }
        return e
      }),
    )
  }

  const onCollapseToggle = () => {
    setExEvents((exEvents) =>
      exEvents.map((e) => ({
        ...e,
        expanded: !someExpanded,
      })),
    )
  }

  return (
    <Page isLoading={!sprint || !sprint.course_modules || fetching}>
      <Row className={styles.mainContainer}>
        <Card className="col-12">
          <div className={styles.liveClasses}>Live Classes & Events</div>
          <div className={styles.mainTitle}>{sprint?.short_name}</div>
          <div className={styles.mainParagraph}>
            All links, slides, and recordings for live events are included in the accordions below (if available).
            Please note, some events have two time options.
          </div>
        </Card>
      </Row>
      {kickoffs.length > 0 && !isMicrosoft && (
        <Section
          title="Live Kickoff Session"
          isTwoTime
          subtitle="Meet your sprint cohort and learn insider tips to get the most out of the sprint. Please note, this event has two time options. The event is not recorded."
        >
          <TwoTimeEvent links={kickoffs} selected={selectedZoomLink} />
        </Section>
      )}
      {sectionDiscussions.length > 0 && !isMicrosoft && (
        <Section
          title="Live Case Discussions"
          isTwoTime
          subtitle="Work with your TA and fellow sprinters to apply the course concepts to a real business problem. Prepare by reviewing the readings below. Please note, this event has two time options. Recordings will be posted here within 3 hours of the event."
        >
          <TwoTimeEvent links={sectionDiscussions} selected={selectedZoomLink} />
        </Section>
      )}
      {networking.length > 0 && !isMicrosoft && (
        <Section
          title="Cohort Meetups"
          isTwoTime
          subtitle="Get to know the group you're sprinting with at our cohort meetup event."
        >
          <TwoTimeEvent links={networking} selected={selectedZoomLink} />
        </Section>
      )}
      {exEvents.length > 0 && (
        <Section
          title="LIVE CLASSES"
          someExpanded={someExpanded}
          subtitle={
            isMicrosoft
              ? 'Watch recordings of the livestream classes.'
              : 'Join a live class with our instructors. If you cannot attend, a recording and supporting slide deck will be posted here within 3 hours of the event.'
          }
          onCollapseToggle={onCollapseToggle}
        >
          {exEvents.map((c, i) => (
            <LiveClass key={i} zoomLink={c} expanded={c.expanded} onExpandChange={() => onExpansionChange(i)} />
          ))}
        </Section>
      )}
      {workshop.length > 0 && !isMicrosoft && (
        <Section
          title="Live Project Workshops"
          isTwoTime
          subtitle="Review your project with your peers and receive real time feedback. Please note, this event has two time options. The event is not recorded."
        >
          <TwoTimeEvent links={workshop} selected={selectedZoomLink} />
        </Section>
      )}
    </Page>
  )
}

export default LiveClasses
