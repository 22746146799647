import { navigate } from '@reach/router'
import { buildLiveSessionsUrl } from 'modules/routing'
import moment from 'moment'
import React, { FC } from 'react'
import { ZoomLink } from 'typings/zoomLink'

import styles from './LiveClassOrEvent.module.scss'

type Props = {
  sprintSlug: string
  zoomLink: ZoomLink
}

const LiveClassOrEvent: FC<Props> = ({ sprintSlug, zoomLink }) => {
  const { zoom_link_date, zoom_link_time, label } = zoomLink
  const eventTime = moment(`${zoom_link_date} ${zoom_link_time}`)

  const handleClick = () => {
    navigate(buildLiveSessionsUrl(sprintSlug), {
      state: { selectedZoomLink: zoomLink },
    })
  }

  return (
    <div className={styles.liveClassOrEvent}>
      <div className={styles.date}>
        <div className={styles.day}>{eventTime.format('DD')}</div>
        <div className={styles.month}>{eventTime.format('MMM')}</div>
      </div>
      <div className={styles.verticalLine} />
      <div>
        <div className={styles.title} onClick={handleClick}>
          {label}
        </div>
        <div className={styles.dateTime}>{eventTime.format('dddd M/D [at] h:mmA [US Eastern Time]')}</div>
      </div>
    </div>
  )
}

export default LiveClassOrEvent
