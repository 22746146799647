import ExternalLink from 'components/ExternalLink'
import { ReactComponent as Uploaded } from 'images/upload/uploaded.svg'
import React, { FC, MouseEventHandler } from 'react'
import { Sprint } from 'typings/sprint'

import styles from '../Steps.module.scss'

type Props = {
  sprint: Sprint
  resubmit: MouseEventHandler<HTMLAnchorElement>
}

const COURSES_WITH_NO_NEXT_STEPS = ['section4-brand-strategy-sprint-2023-02']

const Submitted: FC<Props> = ({ sprint, resubmit }) => {
  const {
    final_project_per_course_info: { ta_scheduling_link: taScheduleLink },
    slug,
  } = sprint

  const showNextSteps = !COURSES_WITH_NO_NEXT_STEPS.includes(slug)

  return (
    <div className={styles.step}>
      <div className={styles.submittedTitle}>
        <div>
          <Uploaded className={styles.submittedCheck} />
        </div>
        <div className={styles.submittedSubTitle}>
          <h3>Your project has been submitted!</h3>
          <p>
            If you have edits to your project, come back to{' '}
            <a href="#submit" onClick={resubmit}>
              resubmit
            </a>
            .
          </p>
        </div>
      </div>
      {showNextSteps && (
        <div className={styles.submittedPara}>
          <b>Next Steps:</b> If you're planning to put your project into action after this sprint,{' '}
          <ExternalLink link={taScheduleLink}>Click Here</ExternalLink> to sign up for a 10 min session with your TA to
          brainstorm about how to hit the ground running.
        </div>
      )}
    </div>
  )
}

export default Submitted
