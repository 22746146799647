/**
 *  As talked with Lee G, they want to be able to experiment with some specific Sprints
 *  This file will contain an array with all the sprints and the function that will identify them
 */

const comcastSprintSlugs: string[] = ['section4-storytelling-sprint-for-comcast', 'section4-data-analytics-sprint']

const microsoftSprintSlugs: string[] = [
  'section4-product-experimentation-for-microsoft',
  'section4-business-strategy-for-microsoft',
  'section4-customer-centered-innovation-sprint-for-microsoft',
  'section4-storytelling-sprint-for-microsoft',
]

export const isMicrosoftSprint = (slug: string): boolean => microsoftSprintSlugs.includes(slug)

export const isComcastSprint = (slug: string): boolean => comcastSprintSlugs.includes(slug)

export const offersPeerFeedback = (slug: string): boolean => {
  const feedbacklessSprintSlugPrefixes = [
    'section4-complete-manager-sprint',
    'section4-productivity-sprint',
    'section4-productivity-performance-sprint',
    'section4-product-positioning-sprint-03',
  ]

  for (const prefix of feedbacklessSprintSlugPrefixes) {
    if (slug.indexOf(prefix) === 0) {
      return false
    }
  }

  return true
}
