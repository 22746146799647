import Col from 'components/Col'
import Row from 'components/Row'
import { getNow } from 'modules/date'
import { createMarkup } from 'modules/render'
import React from 'react'
import { Sprint } from 'typings/sprint'

import Button from '../../../components/Button'
import { ReactComponent as DownloadIcon } from '../../../images/download.svg'
import { ReactComponent as Eye2Icon } from '../../../images/eye2.svg'
import Video from './Video'
import styles from './Welcome.module.scss'

type Props = {
  sprint: Sprint
}

const Welcome: React.FC<Props> = ({ sprint }) => {
  const { onboarding_slides, syllabus } = sprint.document_links

  if (sprint.course_modules.length > 1) {
    const now = getNow().utc().unix()
    const nextModule = sprint.course_modules[1]
    if (now > nextModule.course_module.available_datetime_UTC) {
      return null
    }
  }

  const module = sprint.course_modules[0]
  const items = module.course_module.items
  const availableDatetimeUTC = module.course_module.available_datetime_UTC

  return (
    <Row className="mt-3 mt-md-4">
      <Col xs={12}>
        <div className={styles.welcome}>
          <div className={styles.title}>Welcome</div>
          <div
            className={`${styles.description} wordpress-text`}
            dangerouslySetInnerHTML={createMarkup(sprint.post_content)}
          />
          <div className={`row ${styles.row}`}>
            <div className="col-12 col-md-6">
              {items.length > 0 && (
                <Video
                  sprintSlug={sprint.slug}
                  moduleSlug={module.slug}
                  video={items[0]}
                  availableDatetimeUTC={availableDatetimeUTC}
                />
              )}
            </div>
            <div className="col-12 col-md-6 mt-3 mt-md-0">
              {items.length > 1 && (
                <Video
                  sprintSlug={sprint.slug}
                  moduleSlug={module.slug}
                  video={items[1]}
                  availableDatetimeUTC={availableDatetimeUTC}
                />
              )}
            </div>
          </div>

          <div className={`row ${styles.row}`}>
            {onboarding_slides && (
              <div className="col-12 col-md-4">
                <Button
                  link={onboarding_slides}
                  image={<DownloadIcon />}
                  label="Sprint Kickoff Slides"
                  external={true}
                  className={styles.button}
                  labelClassName={styles.buttonLabel}
                />
              </div>
            )}

            {syllabus && (
              <div className="col-12 col-md-4 mt-2 mt-md-0">
                <Button
                  link={syllabus}
                  image={<Eye2Icon />}
                  label="Sprint Syllabus"
                  external={true}
                  className={styles.button}
                  labelClassName={styles.buttonLabel}
                />
              </div>
            )}
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default Welcome
