import { Link } from '@reach/router'
import Col from 'components/Col'
import Row from 'components/Row'
import { AnimatePresence, motion } from 'framer-motion'
import { variants } from 'pages/upload/animation'
import styles from 'pages/upload/ProjectUpload.module.scss'
import React, { FC } from 'react'

type Props = {
  step: number
  direction: number
  children?: React.ReactNode
}

const Wrapper: FC<Props> = ({ step, direction, children }) => (
  <>
    <Row className={styles.breadcrumb}>
      <Col sm={12}>
        <Link to="..">Documents</Link> <span>&gt;</span> <span>Upload Your Project</span>
      </Col>
    </Row>
    <Row>
      <AnimatePresence initial={false} exitBeforeEnter custom={direction}>
        <motion.div
          key={`step-${step}`}
          className={`col-12 ${styles.content}`}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            opacity: { duration: 0.3 },
          }}
        >
          {children}
        </motion.div>
      </AnimatePresence>
    </Row>
  </>
)

export default Wrapper
