import Card from 'components/Card'
import { createMarkup } from 'modules/render'
import React from 'react'
import { Sprint } from 'typings/sprint'
import { isMicrosoftSprint } from 'utils/isFilteredSprint'

import Module from './Module'
import styles from './Modules.module.scss'
import ModulesButtons from './ModulesButtons'
import { getCurrentModule } from './utils'
import ViewAll from './ViewAll'

type Props = {
  sprint: Sprint
}

const Modules: React.FC<Props> = ({ sprint }) => {
  const isMicrosoft = isMicrosoftSprint(sprint?.slug)
  const lastModule = isMicrosoft ? sprint.course_modules[0] : getCurrentModule(sprint)

  return (
    <Card className={styles.modules}>
      <div className={styles.header}>
        <div className={styles.title}>Modules</div>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={createMarkup(lastModule.course_module.module_banner)}
        />
        <ViewAll url={`./modules`} className={styles.viewAll} />
      </div>
      <Module sprintSlug={sprint.slug} module={lastModule} />
      <ModulesButtons sprint={sprint} />
    </Card>
  )
}

export default Modules
