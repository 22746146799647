import React, { FC } from 'react'

import Col from '../../components/Col'
import styles from './ButtonExpander.module.scss'
import Chevron from './Chevron'

type Props = {
  expanded?: boolean
  onClick?: () => void
}

const ButtonExpander: FC<Props> = ({ expanded, onClick }) => (
  <Col xs={12} md={3}>
    <div className={styles.expanderButtonContainer}>
      <div className={styles.expanderButton} onClick={onClick}>
        {expanded ? 'Collapse All' : 'Expand All'}
        <div style={{ marginTop: -24, marginLeft: 8 }}>
          <Chevron size={24} expanded={expanded} />
        </div>
      </div>
    </div>
  </Col>
)

export default ButtonExpander
