import classNames from 'classnames'
import React, { forwardRef, ReactNode } from 'react'

type Props = {
  className?: string
  children?: ReactNode
  style?: React.CSSProperties
  onClick?: () => void
  noGutters?: boolean
}

type Ref = HTMLDivElement

const Row = forwardRef<Ref, Props>(({ children, className, style, noGutters }, ref) => (
  <div ref={ref} style={style} className={classNames('row', { 'no-gutters': noGutters }, className)}>
    {children}
  </div>
))

export default Row
