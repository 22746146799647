import { navigate, RouteComponentProps, useLocation } from '@reach/router'
import { doCheckAuthentication } from 'features/user/userSlice'
import { FC } from 'react'
import { useDispatch, useStore } from 'react-redux'

const V1Redirect: FC<RouteComponentProps> = () => {
  const dispatch = useDispatch()
  const store = useStore()

  const V1_ROOT_URL = 'https://sprint.section4.com'
  const location = useLocation()
  const v1Url = [V1_ROOT_URL, location.pathname, location.search, location.hash].join('')

  // If a user is logged in, we probably reached this point because state wasn't
  // set yet when the request was routed. The user likely clicked an old email link
  // or used their browser history just to get back to the app. Skip the redirect
  // and let the router try again.
  // *This seems to work but there's probably a better way to do this.
  const authCheck = doCheckAuthentication()
  authCheck(dispatch).then(() => {
    const state = store.getState()
    const haveLoggedInUser = !!state?.user?.user?.user_id

    if (!haveLoggedInUser) {
      navigate(v1Url)
    }
  })

  return null
}

export default V1Redirect
