import cn from 'classnames'
import { motion } from 'framer-motion'
import React, { FC, useContext } from 'react'
import { DeviceConfig } from 'typings/deviceConfig'

import { hamburgerVariants } from './Animations'
import styles from './Hamburger.module.scss'
import { NavContext } from './Sprint2'

type Props = {
  config: DeviceConfig
}

const Hamburger: FC<Props> = ({ config }) => {
  const { navOpen, toggleNav } = useContext(NavContext)

  return (
    <motion.div className={styles.component} variants={hamburgerVariants(config)}>
      <div className={styles.bun} onClick={toggleNav}>
        <div className={cn(styles.hamburger, { [styles.open]: navOpen })}>
          <div />
        </div>
      </div>
    </motion.div>
  )
}

export default Hamburger
