import { useNavigate } from '@reach/router'
import cn from 'classnames'
import React, { FC } from 'react'

import styles from './VideoItem.module.scss'

type Props = {
  thumbnail: string
  title: string
  status: string
  link: string
  disabled: boolean
}

const VideoItem: FC<Props> = ({ thumbnail, title, status, link, disabled }) => {
  const navigate = useNavigate()

  const handleOnClick = () => {
    if (!disabled) {
      navigate(link)
    }
  }

  return (
    <div
      onClick={handleOnClick}
      className={cn(styles.videoItem, {
        [styles.videoItemNotAvailable]: disabled,
      })}
    >
      <div className={styles.imageWrapper}>
        <img className={styles.image} src={thumbnail} alt="Thumbnail" />
        <div className={styles.imageCover} />
      </div>

      <div className={cn(styles.title, { [styles.notAvailable]: disabled })}>{title}</div>

      <div className={cn([styles.status], { [styles.notAvailable]: disabled })}>{status}</div>
    </div>
  )
}

export default VideoItem
