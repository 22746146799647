import React, { FC, ReactNode } from 'react'

import ExternalLink from './ExternalLink'
import InternalLink from './InternalLink'

type Props = {
  link: string
  external?: boolean
  children: ReactNode
  className?: string
}

const InternalOrExternalLink: FC<Props> = ({ link, external, children, className }) => {
  if (!link) {
    return <div className={className}>{children}</div>
  }

  if (external) {
    return (
      <ExternalLink link={link} className={className}>
        {children}
      </ExternalLink>
    )
  }

  return (
    <InternalLink link={link} className={className}>
      {children}
    </InternalLink>
  )
}

export default InternalOrExternalLink
