import { ReactComponent as DotSvg } from 'images/footer/dot.svg'
import { ReactComponent as LogoSvg } from 'images/logo.svg'
import { ReactComponent as LogoFullSvg } from 'images/logo-full.svg'
import { HELP_CENTER_URL, TERMS_OF_USE_URL } from 'modules/constants'
import { getNow } from 'modules/date'
import React from 'react'

import ExternalLink from './ExternalLink'
import styles from './Footer.module.scss'

const Footer = () => {
  const today = getNow().toDate()
  const year = today.getFullYear()

  return (
    <footer className={styles.footer}>
      <LogoFullSvg className={styles.fullLogo} />
      <LogoSvg className={styles.logo} />

      <div className={styles.links}>
        <ExternalLink className={styles.link} link={TERMS_OF_USE_URL}>
          Terms & Conditions
        </ExternalLink>
        <DotSvg className={styles.dot} />
        <ExternalLink className={styles.link} link={HELP_CENTER_URL}>
          Help Center
        </ExternalLink>
      </div>

      <div className={styles.copyright}>&#169; Copyright Section {year}</div>
    </footer>
  )
}

export default Footer
