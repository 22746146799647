import { navigate } from '@reach/router'
import { postUpdatePosition, postUpdatePositionSuccess } from 'features/sprint/sprintSlice'
import {
  EVENT_VIDEO_COMPLETED,
  EVENT_VIDEO_STARTED,
  GO_BACK_SECONDS,
  PLAYER_ID,
  PLAYER_KEY,
  URL_PLAYER_LIBRARIES,
  URL_PLAYER_LIST,
  VIDEO_ACTIONS,
} from 'modules/constants'
import { emailEvent } from 'modules/eventTracking'
import { playerGetCurrentVideoPosition, playerGetState, readyPlayerOne } from 'modules/player'
import { buildUrl } from 'modules/routing'
import React, { FC, useEffect, useRef, useState } from 'react'
import ReactJWPlayer from 'react-jw-player'
import { useDispatch } from 'react-redux'
import { CourseModule } from 'typings/courseModule'
import { VideoPost } from 'typings/video'

import { sendVideoEvent } from '../utils'

type Props = {
  sprintSlug: string
  module: CourseModule
  video: VideoPost
  autoPlay: boolean
}

interface PlayerElement extends HTMLDivElement {
  setPlaybackRate(arg: number)
  getPlaybackRate(): number
  setCurrentCaptions(arg: number)
}

interface Element extends HTMLElement {
  ariaChecked: string
  ariaLabel: string
}

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    jwplayer?: any
  }
}

export const Video: FC<Props> = ({ sprintSlug, module, video, autoPlay }) => {
  const [completed, setCompleted] = useState(false)

  const moduleSlug = module.slug
  const videoSlug = video.slug
  const dispatch = useDispatch()
  const userEmail = '' //useSelector((state) => state.user.user.user_email);
  const playerRef = useRef<PlayerElement | null>(null)

  useEffect(() => {
    setCompleted(false)
  }, [videoSlug])

  const updatePosition = (current: number) => {
    if (current === undefined || Number.isNaN(current) || !Number.isFinite(current)) {
      return
    }

    const currentWithBack = Math.max((current ?? 0) - GO_BACK_SECONDS, 0)

    dispatch(postUpdatePosition(moduleSlug, videoSlug, video.post_id, currentWithBack))
  }

  const handleUpdatePosition = () => {
    const current = playerGetCurrentVideoPosition()
    updatePosition(current)
  }
  const savePosition = () => {
    const state = playerGetState()

    if (state === 'playing') {
      handleUpdatePosition()
    }
  }

  // Save the user's current position when they leave the page
  useEffect(() => {
    window.addEventListener('beforeunload', savePosition)
    return () => {
      handleUpdatePosition()

      window.removeEventListener('beforeunload', savePosition)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSeek = (e) => {
    sendVideoEvent(videoSlug, 'onSeek')
    updatePosition(e.offset)
  }

  const handleTenSeconds = () => {
    emailEvent(userEmail, EVENT_VIDEO_STARTED, video.post_title)
    handleUpdatePosition()
  }

  const handleOneHundredPercent = () => {
    sendVideoEvent(video.slug, 'onOneHundredPercent')
    handleUpdatePosition()

    const items = module.course_module.items
    const index = items.findIndex((item) => item.slug === videoSlug)
    if (items[index + 1]) {
      const nextVideo = buildUrl(sprintSlug, moduleSlug, items[index + 1].slug)
      navigate(nextVideo, {
        state: { autoPlay: true },
      })
    }
  }

  const handlePause = (event) => {
    // Send a GTM event...
    sendVideoEvent(videoSlug, VIDEO_ACTIONS.ON_PAUSE)

    if (event.oldstate === 'playing' && event.newstate === 'paused') {
      handleUpdatePosition()
    }
  }

  const handleOnTime = (event) => {
    dispatch(
      postUpdatePositionSuccess({
        moduleSlug,
        videoSlug,
        currentTime: event.currentTime,
      }),
    )

    if (event.position >= event.duration - 30) {
      if (!completed) {
        handleVideoCompleted()
        setCompleted(true)
      }
    }
  }

  const sendEvents = (videoSlug, action, title) => {
    // Send a GTM event...
    sendVideoEvent(videoSlug, action)
    emailEvent(userEmail, EVENT_VIDEO_COMPLETED, title)
  }

  const handleVideoCompleted = () => {
    sendEvents(videoSlug, VIDEO_ACTIONS.ON_COMPLETE, video.post_title)
    // ...And update the user's position
    handleUpdatePosition()
  }

  const handleSettings = (event, type) => {
    if (type === 'playbackRate') {
      localStorage.setItem('jwplayer.playbackRate', event.playbackRate)
      handleChangeClasses()
    } else if (type === 'captions') {
      localStorage.setItem('jwplayer.captions', event.track)
    }
  }

  const handleOnVideoLoad = () => {
    const playbackRate = Number(localStorage.getItem('jwplayer.playbackRate')) || 1
    const captions = Number(localStorage.getItem('jwplayer.captions')) || 0

    const current = window.jwplayer(PLAYER_ID)
    current.setPlaybackRate(playbackRate)
    current.setCurrentCaptions(captions)
    handleChangeClasses()
  }

  const mainElement = document.getElementById('jw-settings-submenu-playbackRates')
  const elements =
    mainElement && (mainElement.getElementsByClassName('jw-settings-content-item') as HTMLCollectionOf<Element>)
  mainElement &&
    elements[1].addEventListener('click', () => {
      handleSettings({ playbackRate: 1 }, 'playbackRate')
    })

  const handleChangeClasses = () => {
    const playbackRate = Number(localStorage.getItem('jwplayer.playbackRate')) || 1

    const arrayElements = Array.from(elements)
    arrayElements.forEach((e, i) => {
      if (playbackRate === parseFloat(e.ariaLabel)) {
        elements[i].click()
      }
      return null
    })
  }

  const handleOnPlayResume = () => {
    handleUpdatePosition()
    handleChangeClasses()
  }

  if (!video) {
    return null
  }

  return (
    <div>
      <ReactJWPlayer
        playerId={PLAYER_ID}
        className={PLAYER_ID}
        playerScript={`${URL_PLAYER_LIBRARIES}/${PLAYER_KEY}.js`}
        playlist={`${URL_PLAYER_LIST}/${video.video.video_key}`}
        isAutoPlay={autoPlay}
        onReady={() => (autoPlay ? null : readyPlayerOne(video.user.history.continue_at_time))}
        onPlay={handleOnPlayResume}
        onAutoStart={handleOnPlayResume}
        onResume={handleOnPlayResume}
        onPause={handlePause}
        onThreeSeconds={handleUpdatePosition}
        onThirtySeconds={handleUpdatePosition}
        onTwentyFivePercent={handleUpdatePosition}
        onFiftyPercent={handleUpdatePosition}
        onSeventyFivePercent={handleUpdatePosition}
        onNinetyFivePercent={handleUpdatePosition}
        onSeek={handleSeek}
        onTime={handleOnTime}
        onTenSeconds={handleTenSeconds}
        onOneHundredPercent={handleOneHundredPercent}
        onError={(e) => console.error(`Player error: ${e}`)}
        onCaptionsChanged={(e) => handleSettings(e, 'captions')}
        onPlaybackRateChanged={(e) => handleSettings(e, 'playbackRate')}
        ref={playerRef}
        onVideoLoad={handleOnVideoLoad}
      />
    </div>
  )
}

export default Video
