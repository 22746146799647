import cn from 'classnames'
import { Expandable } from 'components/Expandable'
import { useWindowSize } from 'hooks/useWindowSize'
import { decode } from 'html-entities'
import { ReactComponent as ChevronDownSvg } from 'images/chevron-down.svg'
import { everythingBefore } from 'modules/string'
import React, { FC, useState } from 'react'
import { VideoPost } from 'typings/video'

import styles from './Bookmarks.module.scss'

type Props = {
  video: VideoPost
  onBookmarkClick: (second: number) => void
}

const Bookmarks: FC<Props> = ({ video, onBookmarkClick }) => {
  const [expanded, setExpanded] = useState(false)
  const tracks = video?.video?.timed_track ?? []
  const { width } = useWindowSize()

  const isDesktop = width >= 992

  const handleHeaderClick = () => {
    if (isDesktop) {
      return
    }

    setExpanded(!expanded)
  }

  return (
    <div className={styles.bookmarks}>
      <div className={styles.header} onClick={handleHeaderClick}>
        Video Bookmarks
        {!isDesktop && (
          <ChevronDownSvg
            className={cn(styles.arrow, {
              [styles.arrow__expanded]: expanded,
            })}
          />
        )}
      </div>
      <Expandable expanded={expanded || isDesktop}>
        <div className={styles.body}>
          {tracks.map((t, i) => (
            <div key={i} className={styles.item} onClick={() => onBookmarkClick(t.start_seconds)}>
              <div className={styles.trackName}>{decode(t.event_text)}</div>
              <div className={styles.time}>{everythingBefore('.', t.start_time)}</div>
            </div>
          ))}
        </div>
      </Expandable>
    </div>
  )
}

export default Bookmarks
