import { navigate, RouteComponentProps } from '@reach/router'
import { doCheckAuthentication } from 'features/user/userSlice'
import { FC } from 'react'
import { useDispatch, useStore } from 'react-redux'

type Props = {
  to: string
}

const RedirectIfLoggedOut: FC<RouteComponentProps & Props> = ({ to }) => {
  const dispatch = useDispatch()
  const store = useStore()

  const authCheckFn = doCheckAuthentication()
  authCheckFn(dispatch).then(() => {
    const state = store.getState()

    const haveLoggedInUser = !!state?.user?.user?.user_id

    if (!haveLoggedInUser) {
      navigate(to)
    }
  })

  return null
}

export default RedirectIfLoggedOut
