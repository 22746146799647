import { navigate } from '@reach/router'
import cn from 'classnames'
import commonStyles from 'common.module.scss'
import { motion } from 'framer-motion'
import React, { FC, useContext } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { NavConfig } from 'typings/navConfig'
import { NavContextType } from 'typings/navContextType'

import { device, PHONE_NAV_VARIANTS } from './Animations'
import Hamburger from './Hamburger'
import Info from './Info'
import MenuItem from './MenuItem'
import styles from './PhoneNav.module.scss'
import { NavContext } from './Sprint2'

type Props = {
  items: Array<NavConfig>
}

const PhoneNav: FC<Props> = ({ items }) => {
  const sprints = useSelector((state: RootState) => state.sprint.sprints)
  const selectedSprint = useSelector((state: RootState) => state.sprint.selectedSprint)

  const sprint = sprints && sprints[selectedSprint] ? sprints[selectedSprint] : undefined

  const { navOpen, toggleNav } = useContext<NavContextType>(NavContext)

  const navigateTo = (path) => {
    toggleNav()

    navigate(path)
  }

  return (
    <div className={cn(styles.component, { [styles.open]: navOpen })}>
      <motion.div className={styles.nav} variants={PHONE_NAV_VARIANTS}>
        <Hamburger config={device.PHONE} />
        {items.map((item) => (
          <MenuItem
            key={`menu-item-desktop-${item.label}`}
            config={device.PHONE}
            src={item.imgSrc}
            srcActive={item.imgSrcActive}
            label={item.label}
            clickHandler={navigateTo}
            sprintSlug={sprint?.slug}
            path={item.path}
          />
        ))}
        <motion.hr className={commonStyles.separator} />
        {!sprint?.endedMoreThanTwoWeeksAgo && (
          <>
            <MenuItem
              config={device.PHONE}
              src="/images/nav/channels.svg"
              srcActive="/images/nav/channels-active.svg"
              label="Channels"
              sprintSlug={sprint?.slug}
              externalUrl={sprint?.sprint_section?.slack_section_url}
            />
            <Info sprint={sprint} />
          </>
        )}
      </motion.div>
    </div>
  )
}

export default PhoneNav
