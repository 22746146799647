import { ReactComponent as DownloadIcon } from 'images/download.svg'
import { ReactComponent as Eye2Icon } from 'images/eye2.svg'
import React, { FC } from 'react'
import { CourseModule } from 'typings/courseModule'

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import { createMarkup } from '../../../modules/render'
import styles from './ModuleHeader.module.scss'

type Props = {
  module: CourseModule
}

const ModuleHeader: FC<Props> = ({ module }) => {
  const { download_workbook_link, download_project_materials } = module.module_workbook
  return (
    <Card>
      <div className={styles.module}>Module</div>
      <div className={styles.title}>{module.post_title}</div>
      <div className={styles.description} dangerouslySetInnerHTML={createMarkup(module.post_content)} />
      <div className={styles.buttons}>
        {download_workbook_link && (
          <div className={styles.button}>
            <Button link={download_workbook_link} image={<DownloadIcon />} label="Workbook" />
          </div>
        )}

        {download_project_materials && (
          <div className={styles.button}>
            <Button link={download_project_materials} image={<Eye2Icon />} label="Project Materials" />
          </div>
        )}
      </div>
    </Card>
  )
}

export default ModuleHeader
